import { DatabaseItem } from './common'
import { StaffItem } from './staff/item'
import { SpecialistItem } from './specialist/item'
import { RidInternalItem } from './rid/rid_internal/item'
import { NiokrItem } from './niokr/item'
import { PublicationItem } from './publication/item'
import { FinancingItem } from './financing/item'
import { AmortizationItem } from './amortization/item'
import { InternshipsItem } from './internships/item'
import { EducationalProgramItem } from './educational_program/item'
import { ControlOopItem } from './control_oop/item'
import { DpoItem } from './dpo/item'
import { SchoolItem } from './school/item'
import { EmployedItem } from './employed/item'
import { EducationalSpacesItem } from './educational_spaces/item'

export const DatabaseItemStaff = () => {
    return <DatabaseItem title={'staff'}>
        <StaffItem/>
    </DatabaseItem>
}

export const DatabaseItemSpecialist = ({ itemId, isView, urlUpdate }) => {
    return <DatabaseItem title={'specialist'} itemId={itemId} isView={isView} urlUpdate={urlUpdate}>
        <SpecialistItem/>
    </DatabaseItem>
}

export const DatabaseItemRidInternal = ({ itemId, isView, urlUpdate }) => {
    return <DatabaseItem title={'rid_internal'} itemId={itemId} isView={isView} urlUpdate={urlUpdate}>
        <RidInternalItem/>
    </DatabaseItem>
}

export const DatabaseItemInternships = ({ itemId, isView, urlUpdate }) => {
    return <DatabaseItem title={'internships'} itemId={itemId} isView={isView} urlUpdate={urlUpdate}>
        <InternshipsItem/>
    </DatabaseItem>
}

export const DatabaseItemEducationalProgram = ({ itemId, isView, urlUpdate }) => {
    return <DatabaseItem title={'educational_program'} itemId={itemId} isView={isView} urlUpdate={urlUpdate}>
        <EducationalProgramItem/>
    </DatabaseItem>
}

export const DatabaseItemControlOop = ({ itemId, isView, urlUpdate }) => {
    return <DatabaseItem title={'control_oop'} itemId={itemId} isView={isView} urlUpdate={urlUpdate}>
        <ControlOopItem/>
    </DatabaseItem>
}

export const DatabaseItemDpo = ({ itemId, isView, urlUpdate }) => {
    return <DatabaseItem title={'dpo'} itemId={itemId} isView={isView} urlUpdate={urlUpdate}>
        <DpoItem/>
    </DatabaseItem>
}

export const DatabaseItemSchool = ({ itemId, isView, urlUpdate }) => {
    return <DatabaseItem title={'school'} itemId={itemId} isView={isView} urlUpdate={urlUpdate}>
        <SchoolItem/>
    </DatabaseItem>
}

export const DatabaseItemPublication = ({ itemId, isView, urlUpdate }) => {
    return <DatabaseItem title={'publication'} itemId={itemId} isView={isView} urlUpdate={urlUpdate}>
        <PublicationItem/>
    </DatabaseItem>
}

export const DatabaseItemFinancing = ({ itemId, isView, urlUpdate }) => {
    return <DatabaseItem title={'financing'} itemId={itemId} isView={isView} urlUpdate={urlUpdate}>
        <FinancingItem/>
    </DatabaseItem>
}

export const DatabaseItemAmortization = ({ itemId, isView, urlUpdate }) => {
    return <DatabaseItem title={'amortization'} itemId={itemId} isView={isView} urlUpdate={urlUpdate}>
        <AmortizationItem/>
    </DatabaseItem>
}

export const DatabaseItemNiokr = ({ itemId, isView, urlUpdate }) => {
    return <DatabaseItem title={'niokr'} itemId={itemId} isView={isView} urlUpdate={urlUpdate}>
        <NiokrItem/>
    </DatabaseItem>
}

export const DatabaseItemEmployed = ({ itemId, isView, urlUpdate }) => {
    return <DatabaseItem title={'employed'} itemId={itemId} isView={isView} urlUpdate={urlUpdate}>
        <EmployedItem/>
    </DatabaseItem>
}

export const DatabaseItemEducationalSpaces = ({ itemId, isView, urlUpdate }) => {
    return <DatabaseItem title={'educational_spaces'} itemId={itemId} isView={isView} urlUpdate={urlUpdate}>
        <EducationalSpacesItem/>
    </DatabaseItem>
}

