import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconText } from '../../icon_txt'

export const ShowAll = ({ totalCount, pageLimit, setPageLimit, setCurrentPage }) => {

    const [show_all, setShowAll] = useState(false)

    const ModalApprove = () => (
        <Modal size={'lg'} show={show_all} centered={true} onHide={() => setShowAll(false)}>
            <div className={'modal-content'}>
                <Modal.Header style={{ backgroundColor: '#ffc107', borderColor: '#ffc107' }}>
                    <Modal.Title>
                        Подтверждение
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {`Всего записей: ${totalCount}. Хотите отобразить их все?`}
                </Modal.Body>
                <Modal.Footer>
                    <div className={'button-actions'}>
                        <Button variant={'secondary'} onClick={() => setShowAll(false)}>
                            <IconText icon={'ban'} text={'Отмена'}/>
                        </Button>
                        <Button variant={'warning'}
                                onClick={(event) => {
                                    event.preventDefault()
                                    setPageLimit(Number(totalCount))
                                    setCurrentPage(1)
                                    setShowAll(false)
                                }}
                        >
                            <IconText icon={'check'} text={'Ok'}/>
                        </Button>
                    </div>
                </Modal.Footer>
            </div>
        </Modal>
    )

    return (
        <>
            <ModalApprove/>
            <Button variant={'light'} className={'mr-1'}
                    onClick={() => {
                        pageLimit === 10 ? setShowAll(true) : setPageLimit(10)
                    }}>
                <FontAwesomeIcon icon={'expand'}/>
                {pageLimit === 10 ? ' Все' : ' Страница'}
            </Button>
        </>
    )
}
