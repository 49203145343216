import * as React from 'react'
import { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { Area, Input } from '../form/forms'
import { PERMISSIONS } from '../../rbac/constant'
import { useAbac } from 'react-abac'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik, useFormik } from 'formik'
import { IconText } from '../icon_txt'
import { toast } from 'react-toastify'
import { MonthResults } from './month_results'

export const YearResults = ({ 
    formik, 
    yearResults, 
    disabled, 
    field='year_result', 
    emptyYearResult,
    data = null, 
    user = null, 
    id = null,
    showMonthHeader = false,
    AddToMonth=null, 
    AddToYear=null,
    TotalYearResults=null,
    setDisabledRes=null,    
}) => {
    const { userHasPermissions } = useAbac()
    const [show_modal, setShowModal] = useState(false)
    const [delete_year, setDeleteYear] = useState(null)
    const defaultDisabled = () => (disabled || !CAN_MANAGE_YEAR)

    const CAN_MANAGE_YEAR = userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || userHasPermissions(PERMISSIONS.ADMIN_PANEL)

    const formikHelper = useFormik({
        initialValues: {},
    })

    useEffect(() => {
        if (delete_year) {
            let years = formik.values[field]
            let i
            for (i = 0; i < years.length; i++) {
                if (years[i].year === delete_year) {
                    break
                }
            }
            setDeleteYear(null)
            years.splice(i, 1)
        }
    }, [formik.values, delete_year])

    const handleAddYear = (values) => {
        if (!CAN_MANAGE_YEAR) {
            setShowModal(false)
            return
        }

        if (formik.values[field].find((item) => item?.year === Number(values?.year))) {
            toast.error('Введенный год уже присутствует в фактических значениях')
            return
        }

        let emptyNewYearResult = structuredClone(emptyYearResult)
        emptyNewYearResult.year = values?.year
        formik.setFieldValue(field, [...formik.values[field], emptyNewYearResult])
        formikHelper.setValues({
            ...formikHelper.values, [values?.year]: {
                visible: false,
            },
        })
        setShowModal(false)
    }

    const FormikModalProps = ( props ) => (
        <Form>
            <Modal.Body>
                <Input name={'year'} id={'year'}
                    label={'Год'}
                    max={2100}
                    min={1900}
                    type={'year'}
                    handleChangeValue={props.handleChange}/>
            </Modal.Body>

            <Modal.Footer>
                <div className={'button-actions'}>
                    <Button variant={'success'}
                            onClick={() => props.submitForm()}>
                        <IconText icon={'plus'} text={'Создать'}/>
                    </Button>
                    <Button variant={'warning'} 
                            onClick={() => { setShowModal(false) }}
                    >
                        Отменить
                    </Button>
                </div>
            </Modal.Footer>
        </Form>    
    )

    return (<>
        <Modal size={'md'} 
               show={show_modal} 
               centered={true} 
               onHide={() => setShowModal(false)}
        >
            <div className={'modal-content'}>

                <Modal.Header>
                    <Modal.Title>
                        Добавление результата за год
                    </Modal.Title>

                    <button className={'close'} onClick={() => { setShowModal(false) }}>
                        <FontAwesomeIcon icon={'times'}/>
                    </button>
                </Modal.Header>

                <Formik
                    initialValues={{ year: '' }}
                    onSubmit={(values) => handleAddYear(values)}>
                    {FormikModalProps}
                </Formik>
            </div>
        </Modal>

        <legend>Результаты по годам</legend>
        {CAN_MANAGE_YEAR && (
            <Button className={'mb-4'}
                    variant={'success'}
                    onClick={() => setShowModal(true)}
            >
                Добавить год
            </Button>
        )}

        {formik.values[field] && formik.values[field]?.map((item, index) => (
            <fieldset key={item?.year} className={'mb-3'}>

                <legend>Результаты за {item?.year}</legend>
                <Button onClick={() => formikHelper.setFieldValue(
                    `${item?.year}.visible`, !formikHelper.values?.[item?.year]?.visible,
                )}>
                    {formikHelper.values?.[item?.year]?.visible ? 'Скрыть' : 'Показать'}
                </Button>

                {CAN_MANAGE_YEAR && (
                    <Button variant={'danger'} 
                            className={'ml-2'}
                            onClick={() => setDeleteYear(item?.year)}
                    >
                        <FontAwesomeIcon icon={'trash-alt'}/>
                    </Button>
                )}

                {formikHelper.values?.[item?.year]?.visible && ( 
                    <div key={`inside ${item?.year}`}>
                        <br/>
                        {TotalYearResults && (
                            <TotalYearResults
                                id={id}
                                data={data}
                                formik={formik} 
                                item={item} 
                                index={index} 
                                user={user} 
                                disabled={disabled}
                                setDisabledRes={setDisabledRes ? setDisabledRes : defaultDisabled}
                            />
                        )}

                        <fieldset>
                            {showMonthHeader && (
                                <legend>
                                    Фактические результаты работы за месяц:
                                </legend>
                            )}

                            <MonthResults
                                data={data}
                                formik={formik} 
                                index={index} 
                                user={user} 
                                disabled={disabled}
                                field={field}
                                AddToMonth={AddToMonth}
                                yearResults={yearResults}
                                setDisabledRes={setDisabledRes ? setDisabledRes : defaultDisabled}
                            />
                        </fieldset>

                        {AddToYear && (
                            <AddToYear 
                                id={id}
                                data={data}
                                formik={formik} 
                                item={item} 
                                index={index} 
                                user={user} 
                                disabled={disabled}
                            />
                        )}
                    </div> 
                )}
                
            </fieldset>
        ))}
    </>)
}

