import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import ReactFilterTable from '../../../components/tables/react_table_filters/react_table_filters'
import { useNavigate } from 'react-router-dom'
import { PERMISSIONS, ROLES } from '../../../rbac/constant'
import { Button } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { AuthContext } from '../../../auth'
import {
    FIELDS,
    FINANCING_DOCUMENT_TYPES,
    LABELS,
    STATUS_ACCEPT,
    STATUS_BADGE_CLASS,
    STATUS_LABELS,
    STATUS_NEW,
} from './constant'
import { putRequest } from '../../../actions/common'
import { getLabelFromOptions, Messages } from '../../../utils/utils'
import { useAbac } from 'react-abac'
import { getSelectedColor, getStatusColor, handleMoveClick, MoveButton, onSelectRow } from '../common'
import { FinancingPreview } from '../preview'


const Financing = () => {
    const navigate = useNavigate()
    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()

    const [selected, setSelected] = useState([])
    const [status, setStatus] = useState(null)
    const [financing, setFinancing] = useState(null)
    const [viewItem, setViewItem] = useState(null)
    const [filtering, setFiltering] = useState(null)
    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['id', 'project', 'status', 'person', 'customer', 'contract_number', 'work_date_end'],
        'Скрытые / Фиксированные колонки': ['contract_type', 'inn', 'account', 'contract_date', 'contract_subject', 'total_cost', 'created_at', 'updated_at', 'created_by', 'updated_by', 'direction_by', 'educational_program', 'messages'],
    })
    const templateFileTags = JSON.stringify({ 'document_type': 'template_import', 'DB': 'financing' })

    const checkField = (field, key) => {
        if (key === 'status') {
            if (key === 'status') {
                return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
            }
        }
        if (key === 'project' && field) {
            return <span>{field.title_short}</span>
        }
        if (key === 'messages') {
            return <Messages messages_={field} status_labels={STATUS_LABELS} source={'financing'}/>
        }
        if (key === 'contract_type') {
            let label = getLabelFromOptions(FINANCING_DOCUMENT_TYPES, field)
            return label ? label : <span className={'not-set'}>(не задано)</span>
        }
        if (key === 'educational_program' && field) {
            let programs = []
            field.forEach((v) => {
                programs.push(`${v.event_name}`)
            })
            return <span>{programs.join(', ')}</span>
        }
        return null
    }

    useEffect(() => {
        if (userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && !userHasPermissions(PERMISSIONS.ADMIN_PANEL)) {
            setFiltering({ status_not: STATUS_NEW })
        }
    }, [user])

    const addNewFinancing = (state) => {
        putRequest('financing', {
            status: state?.monitoring ? STATUS_ACCEPT : STATUS_NEW, project: user?.project?.id, is_draft: true,
        }).then((response) => {
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/financing/item/${response.data.id}/?update=true`)
            }
        })
    }

    return (<React.Fragment>
        <ReactBreadcrumb/>
        <div className={'box'}>
            <h1 className={'page-header'}>
                Доход / Софинансирование
            </h1>
            {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.direction) || user?.roles?.includes(ROLES.project_manager) || user?.roles?.includes(ROLES.direction_financing)
                ? <React.Fragment>
                    <div className={'button-actions'}>
                        {userHasPermissions(PERMISSIONS.CONTROL_FINANCING) ? <React.Fragment>
                            {userHasPermissions(PERMISSIONS.ADMIN_PANEL)
                                ? <Button variant={'primary'} onClick={() => {
                                    addNewFinancing()
                                }}>
                                    <IconText icon={'plus'} text={'Создать'}/>
                                </Button> : null}
                        </React.Fragment> : <React.Fragment>
                            <Button variant={'primary'} onClick={() => {
                                addNewFinancing()
                            }}>
                                <IconText icon={'plus'} text={'Создать'}/>
                            </Button>
                        </React.Fragment>}
                        {selected.length
                            ? <MoveButton
                                status={status}
                                variantBtn={getStatusColor(status, STATUS_ACCEPT, STATUS_NEW)}
                                labels={STATUS_LABELS}
                                canNext={status !== STATUS_ACCEPT}
                                canBack={status > STATUS_NEW}
                                isAccept={(status + 1) === STATUS_ACCEPT}
                                handleMoveClick={(moveIndex) => handleMoveClick(moveIndex, 'financing', selected, setSelected, status, setFiltering)}
                                permissionManage={PERMISSIONS.MANAGE_FINANCING}
                            />
                            : null}
                    </div>
                </React.Fragment> : null}
        </div>
        {viewItem
            ? <FinancingPreview
                itemId={viewItem}
                onClose={() => setViewItem(null)}
                urlUpdate={'/financing/item'}
            />
            : null}
        <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                          filtering={filtering}
                          data={financing} setFunc={setFinancing} page={1} url={'/financing'}
                          url_update={'/financing/item'}
                          get_title={'financing'}
                          checkField={checkField}
                          onRowClick={(el) => setViewItem(el?.id)}
                          permission={PERMISSIONS.MANAGE_FINANCING}
                          urlImport={'/api/financing_import_request/'}
                          urlExport={'financing_table_export'}
                          templateFile={'import_financing_template.xlsx'}
                          templateFileTags={templateFileTags}
                          fields={FIELDS}
                          importSmallText={'Разделы "Этапы договора", "Порядок оплаты" и "Акты и платежные поручения" \
                                    необходимо заполнять в каждой записи отдельно руками.'}
                          canSelect
                          onSelect={(ev, el) => onSelectRow(ev, el, financing, setSelected, selected)}
                          colorExpression={(el) => getSelectedColor(el, selected)}
                          selectedAll={selected?.length && (financing['results']?.length === selected?.length)}
                          selected={selected}
                          setStatus={setStatus}
        />
    </React.Fragment>)
}

export default Financing
