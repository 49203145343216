import * as React from 'react'
import { useContext } from 'react'
import { IconText } from '../components/icon_txt'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Card, Image, ListGroup, ListGroupItem } from 'react-bootstrap'
import { DropdownSubmenu } from 'react-bootstrap-submenu'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { logoutSubmit } from '../actions/auth'
import { AuthContext } from '../auth'
import { PERMISSIONS, ROLES_LABELS } from '../rbac/constant'
import { useAbac } from 'react-abac'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import avatar from '../assets/image/avatar.png'

const Home = () => {
    const navigate = useNavigate()

    React.useEffect(() => {
        if (user && user.roles.includes('inspector')) {
            navigate('/file-manager')
        }

        document.title = 'Главная | ИС «ПИШ»'
    })

    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()

    const logoutHandle = (e) => {
        e.preventDefault()
        logoutSubmit()
    }

    const handleFeedback = () => {
        if (process.env.REACT_APP_FEEDBACK_URL) {
            window.open(process.env.REACT_APP_FEEDBACK_URL, '_blank', 'noreferrer')
        }
    }

    const MenuButton = ({ href_url, icon, title }) => {
        return (<React.Fragment>
            <ListGroupItem>
                <Link to={href_url} style={{ width: '100%', height: '100%', display: 'block', color: 'inherit' }}>
                    <IconText text={title} icon={icon}/>
                </Link>
            </ListGroupItem>
        </React.Fragment>)
    }

    const SubMenuButton = ({ icon, title, children }) => {
        return (<React.Fragment>
            <ListGroupItem>
                <DropdownSubmenu href='#' title={<IconText text={title} icon={icon}/>}>
                    {children}
                </DropdownSubmenu>
            </ListGroupItem>
        </React.Fragment>)
    }

    const CustomListGroup = ({ lead, children, col_size }) => {
        return (<React.Fragment>
            <div className={`col-lg-${col_size} h-100`}>
                {lead ? <p className='lead' style={{ fontWeight: 500 }}>{lead}</p> : <React.Fragment/>}
                <ListGroup className='list-group h-100'>
                    {children}
                </ListGroup>
            </div>
        </React.Fragment>)
    }

    const Roles = () => {
        if (user) {
            let elements = []
            user.roles.forEach((role) => {
                elements.push(<span key={role}
                                    className={`badge ${role === 'admin' ? 'badge-danger' : 'badge-secondary'}`}>
                    {ROLES_LABELS[role]}
                </span>)
            })
            return elements
        }
        return null
    }

    const Projects = () => {
        if (user && user?.projects?.length) {
            let elements = []
            user.projects.forEach((project) => {
                elements.push(<>
                    <span key={project} className={'badge badge-secondary'}>
                        {project['title_short']}
                    </span>
                    &nbsp;
                </>)
            })

            return elements
        }

        return <>Не задано</>
    }

    const ProfileItem = ({ label, children }) => {
        return <div>
            <small style={{ color: '#6C757D' }}>{label}</small>
            <div>{children}</div>
        </div>
    }

    return (<React.Fragment>
        <Breadcrumb>
            <div className={'d-flex flex-row align-items-center justify-content-between w-100'}>
                <h4 className={'m-0'}>
                    Главная
                </h4>
                {process.env.REACT_APP_ENV !== 'production'
                    ? <Button variant={'outline-secondary'} onClick={handleFeedback}>
                        <IconText icon={'comments'} text={'Оставить обратную связь'}/>
                    </Button> : null}
            </div>
        </Breadcrumb>
        <div className={'card-deck mb-2 mx-0'}>
            <div className={'d-flex flex-column'}>
                <Card className={'mb-2 mx-1'}>
                    <Card.Body>
                        <CustomListGroup lead={'Профиль'}>
                            <div className={'d-flex h-75 flex-column justify-content-between'}>
                                <div>
                                    <div className={'row px-3'}>
                                        <div
                                            className={'d-flex mr-3 rounded bg-light border align-items-center justify-content-center'}
                                            style={{ height: 140, width: 140 }}>
                                            <Image src={avatar} width={110}
                                                   height={110}/>
                                        </div>
                                        <div>
                                            <ProfileItem label={'ФИО'}>
                                                {user ? user.last_name : null} {user ? user.first_name : null} {user ? user.middle_name : null}
                                            </ProfileItem>
                                            <ProfileItem label={'E-mail'}>
                                                {user ? user.email : null}
                                            </ProfileItem>
                                            <ProfileItem label={'Роли'}>
                                                <Roles/>
                                            </ProfileItem>
                                        </div>
                                    </div>

                                    <ProfileItem label={'Проекты'}>
                                        <Projects/>
                                    </ProfileItem>
                                </div>
                                <div className='button-actions mb-0 mt-2'>
                                    <Button variant='outline-danger' onClick={logoutHandle}>
                                        <IconText text={'Выход'} icon={'sign-out-alt'}/>
                                    </Button>
                                    <Link to={'/forget'}>
                                        <Button variant='outline-secondary'>
                                            <IconText text={'Смена пароля'} icon={'key'}/>
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </CustomListGroup>
                    </Card.Body>
                </Card>
                <Card className={'mx-1 flex-grow-0 flex-shrink-0'}>
                    <Card.Body>
                        <CustomListGroup lead={'Дашборд'}>
                            <MenuButton href_url={'/dashboard'} icon={'chart-line'}
                                        title={'Открыть'}/>
                            <MenuButton href_url={'/dashboard-report'} icon={'file-alt'}
                                        title={'Данные'}/>
                        </CustomListGroup>
                    </Card.Body>
                </Card>
            </div>
            <Card className={'mx-1'}>
                <Card.Body>
                    <CustomListGroup lead={'Мониторинг'}>
                        <MenuButton href_url={'/indicator'} icon={'thermometer-half'}
                                    title={'Показатели'}/>
                        <MenuButton href_url={'/indicator-project'} icon={'project-diagram'}
                                    title={'Показатели по проектам'}/>
                        <MenuButton href_url={'/indicator/report'} icon={'file-alt'}
                                    title={'Отчеты'}/>
                        <MenuButton
                            href_url={userHasPermissions(PERMISSIONS.IS_MANAGER) ? `/grant/${user?.project?.id}?year=${new Date().getFullYear()}` : '/grant'}
                            icon={'file-alt'}
                            title={'Грант'}/>
                        <MenuButton href_url={'control_oop'} icon={'graduation-cap'}
                                    title={'Контроль разработки ООП'}/>
                    </CustomListGroup>
                </Card.Body>
            </Card>
            <Card className={'mx-1'}>
                <Card.Body>
                    <div className={'row'}>
                        <CustomListGroup lead={'Базы данных'} col_size={12}>
                            <MenuButton href_url={'/students'} icon={'user-graduate'}
                                        title={'Студенты'}/>
                            <MenuButton href_url={'/specialist'} icon={'user-graduate'}
                                        title={'Обученные'}/>
                            <DropdownButton
                                key={'end'}
                                id={'dropdown-button-drop-end'}
                                drop={'end'}
                                variant={'transparent'}
                                className={'dropright list-group-item btn-group button-dropdown'}
                                title={<IconText text={'РИДы'} icon={'lightbulb'}/>}
                            >
                                {userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && <Link to={'/rid/rid-external'}
                                                                                          role={'button'}
                                                                                          className={'dropdown-item'}
                                >
                                    <IconText text={'РИДы (внешний мониторинг)'}
                                              icon={'lightbulb'}
                                    />
                                </Link>}
                                <Link to={'/rid/rid-internal'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'РИДы (внутренний мониторинг)'} icon={'lightbulb'}/>
                                </Link>
                            </DropdownButton>
                            <MenuButton href_url={'/financing'} icon={'coins'}
                                        title={'Доход / Софинансирование'}/>
                            <MenuButton href_url={'/internships'} icon={'address-card'}
                                        title={'Стажировка'}/>
                            <MenuButton href_url={'/educational_program'} icon={'graduation-cap'}
                                        title={'Образовательные программы'}/>
                            <MenuButton href_url={'/niokr'} icon={'coins'}
                                        title={'НИОКР'}/>
                            <MenuButton href_url={'/dpo'} icon={'lightbulb'}
                                        title={'ДПО'}/>
                            <MenuButton href_url={'/school'} icon={'school'}
                                        title={'Школьники'}/>
                            <MenuButton href_url={'/employed'} icon={'briefcase'}
                                        title={'Трудоустроенные'}/>
                            <MenuButton href_url={'/educational_spaces'} icon={'graduation-cap'}
                                        title={'Образовательные пространства'}/>
                        </CustomListGroup>
                    </div>
                </Card.Body>
            </Card>
        </div>
        <div className={'card-deck mb-4 mx-0'}>
            <Card className={'mx-1'}>
                <Card.Body>
                    <p className='lead' style={{ fontWeight: 500 }}>Справочники</p>
                    <div className={'row'}>
                        <CustomListGroup lead={''} col_size={6}>
                            <MenuButton href_url={'/dicts/self-academic-degree'} icon={'award'}
                                        title={'Ученые степени'}/>
                            <MenuButton href_url={'/dicts/self-academic-rank'} icon={'graduation-cap'}
                                        title={'Ученые звания'}/>
                            <MenuButton href_url={'/dicts/project'} icon={'project-diagram'}
                                        title={'Проекты'}/>
                            <MenuButton href_url={'/dicts/unit'} icon={'ruler-horizontal'}
                                        title={'Единицы измерения'}/>
                            <MenuButton href_url={'/dicts/staff-category'} icon={'user-tag'}
                                        title={'Категории работников'}/>
                            <MenuButton href_url={'/dicts/job-contract'} icon={'file-contract'}
                                        title={'Формы привлечения работников'}/>
                            {/* <MenuButton href_url={"/dicts/event-category"} icon={"calendar-alt"}
                                            title={"Категории мероприятий"}/>
                                <MenuButton href_url={"/dicts/event"} icon={"calendar-alt"}
                                            title={"Мероприятия"}/> */}
                            <MenuButton href_url={'/dicts/organization'} icon={'building'}
                                        title={'Контрагенты'}/>
                            <MenuButton href_url={'/dicts/patent-office'} icon={'shield-alt'}
                                        title={'Патентные ведомства'}/>
                            <MenuButton href_url={'/dicts/speciality'} icon={'user-tag'}
                                        title={'Специальность и направления подготовки'}/>
                            {user?.roles.every((value) => value !== 'project_manager')
                                ? <MenuButton href_url={'/dicts/person'} icon={'user-friends'}
                                              title={'Персоналии'}/> : null}
                            <MenuButton href_url={'/dicts/school-event'} icon={'award'}
                                        title={'Типы мероприятий для БД "Школьники"'}/>
                        </CustomListGroup>
                        <CustomListGroup lead={''} col_size={6}>

                            <MenuButton href_url={'/dicts/department'} icon={'sitemap'}
                                        title={'Подразделения'}/>
                            <MenuButton href_url={'/dicts/academic-degree'} icon={'award'}
                                        title={'Ученые степени (внешний источник)'}/>
                            <MenuButton href_url={'/dicts/academic-rank'} icon={'graduation-cap'}
                                        title={'Ученые звания (внешний источник)'}/>
                            <MenuButton href_url={'/dicts/country'} icon={'globe'}
                                        title={'Страны'}/>
                            <MenuButton href_url={'/dicts/staff-position'} icon={'street-view'}
                                        title={'Должности'}/>
                            <MenuButton href_url={'/dicts/staff-unit'} icon={'user-shield'}
                                        title={'Штатные единицы'}/>
                            <MenuButton href_url={'/dicts/order'} icon={'book'}
                                        title={'Приказы'}/>
                            <MenuButton href_url={'/dicts/template'} icon={'book'}
                                        title={'Шаблоны'}/>
                            <MenuButton href_url={'/dicts/template-oop'} icon={'book'}
                                        title={'Шаблоны документов для открытия ООП'}/>
                            <MenuButton href_url={'/dicts/deadlines-oop'} icon={'calendar-alt'}
                                        title={'Сроки добавления документов по ООП'}/>

                        </CustomListGroup>
                    </div>
                </Card.Body>
            </Card>
        </div>
    </React.Fragment>)
}

export default Home
