import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Accordion, Button, Card, Col, Form, Row } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { getRequest, putRequest, updateRequest } from '../../../actions/common'
import {
    MONTH,
    NO_YES,
    REQUIRED_FIELDS,
    REQUIRED_FIELDS_TEMP,
    SPECIALIST_TYPES,
    STATUS_ACCEPT,
    STATUS_APPROVE,
    STATUS_NEW,
    STUDENT_TYPES,
} from './constant'
import { useAbac } from 'react-abac'
import { PERMISSIONS } from '../../../rbac/constant'
import { Area, Input } from '../../../components/form/forms'
import { MyFileBrowser } from '../../../components/file_manager/file_manager'
import { btnMessage } from '../../../utils/utils'
import { Field, FormikProvider, useFormik } from 'formik'
import { FormikDate, FormikRadio, FormikSelect } from '../../../components/form/formik'
import { toast } from 'react-toastify'

export const SpecialistItem = ({
                                   data,
                                   setData,
                                   user,
                                   disabled,
                                   messages,
                                   generateOptions,
                                   MessageHistory,
                                   ActionButtons,
                                   DisableButton,
                                   HeaderStatus,
                                   handleSubmit,
                                   ErrorsBlock,
                                   EditButton,
                                   ToggleButtons,
                                   MainBlock,
                                   FileBlock,
                               }) => {
    const params = useParams()
    const navigate = useNavigate()
    const project_ref = useRef()
    const location = useLocation()
    const isStudents = location.pathname.includes('students')

    const { userHasPermissions } = useAbac()
    const [select_data, setSelectData] = useState({})

    const select_fields = ['project', 'specialist_event', 'order', 'indicator']
    const given_select_fields = ['is_network', 'is_commercial', 'diploma_month']

    const [input_fields, setInputFields] = useState({
        'status': 1,
        'current_status': 1,
        'save_form': false,
        'fio': '', // 'birth_date': null,
        'edu_doc_number': '',
        'expel_order_number': '',
        'enrollment_order_number': '',
        'contract_details': '',
        'edu_doc_date': null,
        'employment_date': null,
        'expel_order_date': null,
        'enrollment_order': '',
        'related_docs_url': '',
        'comment': '',
        'type': null,
        'position': '',
        'event_id': null,
        'event_oop_id': null,
        'event_dpo_id': null,
        'program': 2,
        'event_name': '',
        'message': '',
        'organization_inn': '',
        'employed_inn': undefined,
        'organization_program': '',
        'organization_employed': '',
        'is_network': null,
        'is_commercial': null,
        'diploma_year': null,
    })

    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: (values) => {
            return saveSpecialist()
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })

    useEffect(() => {
        handleSubmit(formik, saveSpecialist)
    }, [formik.values])

    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${data?.id} в БД Обученные и студенты | ИС «ПИШ»`
            let set_selected = {}
            select_fields.forEach((field) => {
                set_selected[`${field}_id`] = data[field]?.id
            })
            given_select_fields.forEach((field) => {
                set_selected[field] = data[field]
            })
            set_selected['event_oop_id'] = data?.event_oop?.id
            set_selected['event_dpo_id'] = data?.event_dpo?.id
            set_selected['indicator_id'] = data?.indicator
            set_selected['is_educational'] = !isStudents
            let set_inputs = {}
            for (let field in input_fields) {
                if (Object.keys(data).includes(field)) {
                    set_inputs[field] = data[field]
                } else {
                    set_inputs[field] = formik.values?.[field]
                }
            }
            if (!set_inputs?.program) {
                set_inputs.program = 2
            }
            formik.setValues({ ...set_inputs, ...set_selected, current_status: data.status })
        }
    }, [data])

    useEffect(() => {
        if (data) {
            let is_manager = user?.roles.every((value) => value === 'project_manager')  // can't view all projects
            if (Object.keys(select_data).length < select_fields.length || !select_data?.event?.length) {
                for (let field of select_fields) {
                    if (!Object.keys(select_data).includes(field) && field !== 'event' && field !== 'specialist_event' && field !== 'order') {
                        if (field === 'project' && is_manager) {
                            getRequest('project', setSelectData, {
                                type_data: 'user_projects', 'user_id': user.id,
                            }, '', select_data, field)
                        } else {
                            getRequest(field, setSelectData, {
                                type_data: 'all', specialist: true,
                            }, '', select_data, field)
                        }
                        break
                    }
                    if (!select_data?.order?.length && field === 'order') {
                        getRequest('order', setSelectData, {
                            type_data: 'all', only_report_year: true,
                        }, '', select_data, field)
                        break
                    }

                    if (!select_data?.event?.length && field === 'specialist_event') {
                        getRequest('specialist_event', setSelectData, {}, '', select_data, 'event').then((r) => {
                            let oop = r.data.find((v) => v?.type === 1 && v?.id === formik.values.event_oop_id)
                            let dpo = r.data.find((v) => v?.type === 2 && v?.id === formik.values.event_dpo_id)

                            if (oop || dpo) {
                                setInputFields({ ...formik.values, event_id: oop || dpo })
                            }
                        })
                        break
                    }
                }
            }
        }
    }, [data, select_data, formik.values])

    useEffect(() => {
        if (formik.values.event_id) {
            let find = formik.values.event_id
            if (find?.type && find?.type === 1 && find.id !== formik.values?.event_oop_id) {
                formik.setFieldValue('event_oop_id', find?.id)
                formik.setFieldValue('event_dpo_id', null)
            } else if (find?.type && find?.type === 2 && find.id !== formik.values?.event_dpo_id) {
                formik.setFieldValue('event_oop_id', null)
                formik.setFieldValue('event_dpo_id', find?.id)
            }
        }
    }, [formik.values])

    useEffect(() => {
        if (Number(formik.values.program) === 1) {
            formik.setFieldValue('event_name', null)
        } else {
            formik.setValues({ ...formik.values, event_oop_id: null, event_dpo_id: null, event_id: null })
        }
    }, [formik.values.program])


    const saveSpecialist = () => {
        formik.setFieldValue('save_form', false)

        let payload = null
        if (STATUS_ACCEPT === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values, direction: user.id }
        } else if (STATUS_APPROVE === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values, direction_edu: user.id }
        } else {
            payload = formik.values
        }

        if (payload.event_id && payload.event_id.type) {
            if (payload.event_id.type === 1) {
                payload.event_oop_id = payload.event_id.id
            } else {
                payload.event_dpo_id = payload.event_id.id
            }
            payload.event_name = null
        } else if (payload.event_name) {
            payload.event_oop_id = null
            payload.event_dpo_id = null
        }

        if (payload.status === STATUS_ACCEPT && data.status < STATUS_ACCEPT) {
            toast.success('Запись создана в БД Трудоустроенные')
        }

        updateRequest('specialist', { 'common': payload }, params.id).then((response) => {
            if (response.status === 200) {
                if (payload?.status !== data?.status) navigate(isStudents ? '/students' : '/specialist')
                setData(response.data)
            }
        })
        if (formik?.values?.message) {
            putRequest('specialist_messages', {
                content: formik?.values?.message,
                specialist_status: data.status,
                status: data.status,
                created_by: user.id,
                specialist: data.id,
            }, false).then((r) => {
            })
            document.getElementById('message').value = null
            formik.setFieldValue('message', null)
        }

        return Promise.resolve('is_saved')
    }

    function generateEventOptions() {
        let options = generateOptions('event', (element) => {
            let sub_name = element?.type === 1 ? 'ООП' : 'ДПО'
            if (element?.event_name) {
                return `(${sub_name}) ${element.event_name}`
            } else {
                return ''
            }
        }, select_data, null, 'index')
        console.log(options)
        return options?.filter((item) => !!item?.label)
    }

    const getHoverContext = (element) => {
        return <>
            {element.title}
        </>
    }

    const getTags = (document_type) => {
        return {
            document_type: document_type,
            year: formik.values.diploma_year,
            month: formik.values.diploma_month,
            project: formik.values.project_id,
            specialist_item: data?.id,
        }
    }

    return (<React.Fragment>
        <FormikProvider value={formik}>
            <ReactBreadcrumb/>
            <Form>
                <div className='box'>
                    <div className={`d-flex align-items-center ${disabled ? 'justify-content-between' : ''}`}>
                        <h1 className='page-header d-flex'>
                            {`№${data?.id} в БД ${isStudents ? 'Студенты' : 'Обученные'}`}
                        </h1>
                        <DisableButton/>
                    </div>
                    <div className={'d-flex justify-content-between align-items-center'}>
                        <Button variant={'outline-primary'} className={'flex-shrink-0 mt-1 mr-2'} size={'md'}
                                onClick={btnMessage}>
                            <IconText icon={'comment'}
                                      text={disabled ? 'Сообщения' : ''}/>
                        </Button>
                        <EditButton/>
                        <ActionButtons
                            form={formik}
                            permission_control={PERMISSIONS.CONTROL_SPECIALIST}
                            permission_manage={PERMISSIONS.MANAGE_SPECIALIST}
                            can_back={(userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || userHasPermissions(PERMISSIONS.EDU_DIRECTION_PANEL)) && data?.status !== STATUS_ACCEPT}
                        />
                    </div>
                </div>
                <Accordion>
                    <Accordion.Item eventKey='0'>
                        <Accordion.Button className={'accordion-head'}>Дополнительная информация</Accordion.Button>
                        <Accordion.Body className={'accordion-body'}>
                            <p>
                                <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте
                                следующие
                                условные обозначения,
                                используемые в интерфейсе:
                            </p>
                            <ul>
                                <li>поля помеченные <strong className='text-danger'>красной
                                    «*»</strong> являются <strong>обязательными
                                    на текущем
                                    этапе для перехода на следующий</strong>;
                                </li>
                                <li>поля помеченные <strong className='text-success'>зелёной
                                    «*»</strong> являются <strong>обязательными
                                    для
                                    определенных условий</strong>;
                                </li>
                                <li>кнопка <span className='badge badge-success'>Сохранить</span> выполняет
                                    сохранение
                                    текущего состояния карточки (требуется
                                    заполнения ключевых полей);
                                </li>
                                <li>кнопка <span
                                    className='badge badge-success'>Сохранить + отправить далее</span> сохраняет
                                    карточку и
                                    проверяет выполнение
                                    условий текущего этапа (при выполнении условий карточка переходит на следующий
                                    этап).
                                </li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Card className={'bg-white mt-4 mb-4'}
                      style={messages && messages?.length !== 0 || formik.errors?.message ? { display: 'block' } : { display: 'none' }}
                      id={'card-message'}>
                    <Card.Body>
                        <div className='alert alert-danger'
                             style={{ display: formik.errors?.message ? 'block' : 'none' }}>
                            <p>Исправьте следующие ошибки:</p>
                            <ul>
                                <li>Необходимо заполнить: "Комментарий"</li>
                            </ul>
                        </div>
                        <label>История сообщений</label>
                        <MessageHistory/>
                        <div id='field-mention' className='content-group navbar-nav px-0'>
                            <div id='field-comment'>
                                <label htmlFor='specialist_message'>Комментарий</label>
                                <Area id='message'
                                      name={'message'}
                                      disabled={disabled}
                                      value={formik.values?.message}
                                      error={formik.errors['message']}
                                      invalid={formik.errors['message']}
                                      rows='5'
                                      handleChangeValue={formik.handleChange}
                                />
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card className={'bg-white mb-3 mt-3'}>
                    <Card.Body>
                        <ToggleButtons/>
                        <hr/>
                        <ErrorsBlock form={formik}/>
                        <MainBlock>
                            <fieldset>
                                <legend>Персональные данные</legend>
                                <Row>
                                    <Col md={12}>
                                        <Input label={'ФИО специалиста'}
                                               id={'fio'}
                                               name={'fio'}
                                               handleChangeValue={formik.handleChange}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('fio') : false}
                                               disabled={disabled}
                                               error={formik.errors['fio']}
                                               invalid={formik.errors['fio']}
                                               value={formik.values ? formik.values.fio : null}/>
                                    </Col>
                                </Row>

                                {!isStudents ? <>
                                    <Row>
                                        <Col md={12}>
                                            <Input label={'Должность'} id={'position'}
                                                   name={'position'}
                                                   handleChangeValue={formik.handleChange}
                                                   required={data ? REQUIRED_FIELDS[data.status + 1].includes('position') : false}
                                                   disabled={disabled}
                                                   error={formik.errors['position']}
                                                   invalid={formik.errors['position']}
                                                   value={formik.values ? formik.values.position : null}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Input
                                                label={'Наименование организации, в которой трудоустроен специалист'}
                                                id={'organization_employed'}
                                                name={'organization_employed'}
                                                handleChangeValue={formik.handleChange}
                                                required_on_transition={data ? REQUIRED_FIELDS[data.status + 1].includes('organization_employed') : false}
                                                disabled={disabled}
                                                error={formik.errors['organization_employed']}
                                                invalid={formik.errors['organization_employed']}
                                                value={formik.values ? formik.values.organization_employed : null}/>
                                        </Col>
                                        <Col md={12}>
                                            <Input handleChangeValue={formik.handleChange}
                                                   label={'ИНН организации, в которой трудоустроен специалист'}
                                                   id={'employed_inn'}
                                                   name={'employed_inn'}
                                                   disabled={disabled}
                                                   mask={'9999-999999-99'}
                                                   error={formik.errors['employed_inn']}
                                                   invalid={formik.errors['employed_inn']}
                                                   required={false}
                                                   value={formik.values ? formik.values?.employed_inn : null}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Field component={FormikSelect} name={'indicator_id'}
                                                   label={'Показатель'} id={'indicator_id'}
                                                   isSearchable={true}
                                                   required={data ? REQUIRED_FIELDS[data.status + 1].includes('indicator_id') : false}
                                                   handleChangeValue={formik.handleChange}
                                                   options={generateOptions('indicator', 'abbreviation', select_data, undefined, undefined, getHoverContext)}
                                                   disabled={disabled}
                                                   error={formik.errors['indicator_id']}
                                                   invalid={formik.errors['indicator_id']}
                                            />
                                        </Col>
                                    </Row>
                                </> : null}
                                <hr/>
                            </fieldset>
                            <fieldset>
                                <legend>Специальные данные</legend>
                                <Field component={FormikSelect}
                                       name={'type'}
                                       label={'Тип (форма подготовки)'}
                                       id={'type'}
                                       isSearchable={true}
                                       required={data ? REQUIRED_FIELDS[data.status + 1].includes('type') : false}
                                       error={formik.errors?.['type']}
                                       invalid={formik.errors?.['type']}
                                       options={isStudents ? STUDENT_TYPES : SPECIALIST_TYPES}
                                       disabled={disabled}
                                />

                                <Form.Label>Наименование организации</Form.Label>
                                <Row>
                                    <Col md={1}>
                                        <Field component={FormikRadio}
                                               type={'radio'}
                                               id={'program'}
                                               set_value={1}
                                               value={1}
                                               name={'program'} label={'СПБПУ'}/>
                                    </Col>
                                    <Col md={1}>
                                        <Field component={FormikRadio}
                                               type={'radio'}
                                               id={'program'}
                                               set_value={2}
                                               value={2}
                                               name={'program'} label={'Внешняя'}/>
                                    </Col>
                                    {formik.values?.program == 2 ? <Col md={10}>
                                        <Input
                                            label={isStudents ? 'Наименование организации' : 'Наименование организации, по программе которой подготовлен специалист'}
                                            id={'organization_program'}
                                            name={'organization_program'}
                                            handleChangeValue={formik.handleChange}
                                            required_on_transition={data ? REQUIRED_FIELDS[data.status + 1].includes('organization_program') : false}
                                            disabled={disabled}
                                            error={formik.errors['organization_program']}
                                            invalid={formik.errors['organization_program']}
                                            value={formik.values ? formik.values.organization_program : null}/>
                                    </Col> : null}
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Field component={FormikSelect} name={'project_id'}
                                               label={'Проект'} id={'project_id'}
                                               isSearchable={true}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('project_id') : false}
                                               handleChangeValue={formik.handleChange}
                                               options={generateOptions('project', 'title_short', select_data)}
                                               disabled={disabled}
                                               error={formik.errors['project_id']}
                                               invalid={formik.errors['project_id']}
                                        />
                                    </Col>
                                </Row>
                                {formik.values?.program == 1 ? <Field component={FormikSelect} name={'event_id'}
                                                                      label={'Наименование программы'}
                                                                      id={'event_id'}
                                                                      isSearchable={true}
                                                                      options={generateEventOptions()}
                                                                      compare={(option, value) => {
                                                                          if (option?.id === value?.id && option?.event_name === value?.event_name && option?.type === value?.type) {
                                                                              let sub_name = value?.type === 1 ? 'ООП' : 'ДПО'
                                                                              return {
                                                                                  value: option,
                                                                                  label: `(${sub_name}) ${option.event_name}`,
                                                                              }
                                                                          }
                                                                      }}
                                                                      disabled={disabled}
                                                                      error={formik.errors['event_id']}
                                                                      invalid={formik.errors['event_id']}
                                                                      required={data ? REQUIRED_FIELDS[data.status + 1].includes('event_id') : false}
                                /> : <div>
                                    <Input label={'Наименование программы'} id={'event_name'}
                                           name={'event_name'}
                                           handleChangeValue={formik.handleChange}
                                           required={data ? REQUIRED_FIELDS[data.status + 1].includes('event_name') : false}
                                           disabled={disabled}
                                           error={formik.errors['event_name']}
                                           invalid={formik.errors['event_name']}
                                           value={formik.values ? formik.values.event_name : null}/>
                                </div>}
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'is_network'}
                                               label={'Сетевая'}
                                               isClearable={true}
                                               options={NO_YES}
                                               id={'is_network'}
                                               error={formik.errors['is_network_id']}
                                               invalid={formik.errors['is_network_id']}
                                               disabled={disabled || (userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status >= STATUS_APPROVE)}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('is_network')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'is_commercial'}
                                               label={'На коммерческой основе'}
                                               isClearable={true}
                                               options={NO_YES}
                                               id={'is_commercial'}
                                               error={formik.errors['is_commercial_id']}
                                               invalid={formik.errors['is_commercial_id']}
                                               disabled={disabled || (userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status >= STATUS_APPROVE)}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('is_commercial')}
                                        />
                                    </Col>
                                </Row>
                            </fieldset>
                            <fieldset>
                                <legend>Подтверждающие документы</legend>
                                <Row>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Дата приказа о зачислении/ о направлении на обучение'}
                                               id={'enrollment_order'}
                                               name={'enrollment_order'}
                                               value={formik.values ? formik.values.enrollment_order : null}
                                               disabled={disabled}
                                               small={'Приказ о зачислении - для формы подготовки «Обучающийся», Приказ о включении в рабочую группу - для формы подготовки «Молодой исследователь»'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Номер приказа о зачислении/ о направлении на обучение'}
                                               id={'enrollment_order_number'}
                                               name={'enrollment_order_number'}
                                               value={formik.values ? formik.values.enrollment_order_number : null}
                                               disabled={disabled}
                                               error={formik.errors['enrollment_order_number']}
                                               invalid={formik.errors['enrollment_order_number']}
                                               required={false}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate}
                                               name={'expel_order_date'}
                                               label={'Дата приказа об отчислении'} id={'expel_order_date'}
                                               handleChangeValue={formik.handleChange}
                                               value={formik.values ? formik.values.expel_order_date : null}
                                               disabled={disabled}
                                               error={formik.errors['expel_order_date']}
                                               invalid={formik.errors['expel_order_date']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Номер приказа об отчислении'}
                                               id={'expel_order_number'}
                                               name={'expel_order_number'}
                                               value={formik.values ? formik.values.expel_order_number : null}
                                               disabled={disabled}
                                               error={formik.errors['expel_order_number']}
                                               invalid={formik.errors['expel_order_number']}
                                               required={false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikDate}
                                               name={'edu_doc_date'}
                                               label={'Дата документа об образовании'} id={'edu_doc_date'}
                                               handleChangeValue={formik.handleChange}
                                               value={formik.values ? formik.values.edu_doc_date : null}
                                               disabled={disabled}
                                               error={formik.errors['edu_doc_date']}
                                               invalid={formik.errors['edu_doc_date']}
                                               small={'Документ об образовании, полученный в рамках ПИШ'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('edu_doc_date') : false}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Номер документа об образовании'}
                                               id={'edu_doc_number'}
                                               name={'edu_doc_number'}
                                               value={formik.values ? formik.values.edu_doc_number : null}
                                               disabled={disabled}
                                               error={formik.errors['edu_doc_number']}
                                               invalid={formik.errors['edu_doc_number']}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('edu_doc_number') : false}
                                               small={'Обязательное поле для формы подготовки «Обучающийся». Документ об образовании, полученный в рамках ПИШ'}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'diploma_month'}
                                               label={!isStudents ? 'Месяц включения в отчет' : 'Месяц поступления'}
                                               id={'diploma_month'}
                                               isClearable={true}
                                               isSearchable={true}
                                               options={MONTH}
                                               required={data ? REQUIRED_FIELDS[data.status].includes('diploma_month') : false}
                                               disabled={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={!isStudents ? 'Год включения в отчет' : 'Год поступления'}
                                               id={'diploma_year'}
                                               type={'int'}
                                               min={2022}
                                               max={2030}
                                               name={'diploma_year'}
                                               error={formik.errors['diploma_year']}
                                               invalid={formik.errors['diploma_year']}
                                               disabled={disabled}
                                               value={formik.values.diploma_year}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('diploma_year')}
                                        />
                                    </Col>
                                </Row>
                                <Input label={'Ссылка на сопутствующие документы'} id={'related_docs_url'}
                                       name={'related_docs_url'}
                                       handleChangeValue={formik.handleChange}
                                       small={!isStudents ? '' : 'Скан документа, подтверждающий поле «Вид документа»'}
                                       disabled={disabled}
                                       value={formik.values ? formik.values.related_docs_url : null}/>
                                {!isStudents ? <Input label={'Реквизиты договора'} id={'contract_details'}
                                                      name={'contract_details'}
                                                      handleChangeValue={formik.handleChange}
                                                      disabled={disabled}
                                                      value={formik.values ? formik.values.contract_details : null}/> : null}
                                <Field component={FormikDate}
                                       name={'employment_date'}
                                       label={'Дата трудоустройства'} id={'employment_date'}
                                       handleChangeValue={formik.handleChange}
                                       value={formik.values ? formik.values.edu_doc_date : null}
                                       disabled={disabled}
                                       error={formik.errors['employment_date']}
                                       invalid={formik.errors['employment_date']}
                                />
                                <hr/>
                                <Area label={'Комментарий (дополнительная информация)'} id={'comment'}
                                      name={'comment'}
                                      handleChangeValue={formik.handleChange}
                                      disabled={disabled}
                                      value={formik.values ? formik.values.comment : null}/>
                            </fieldset>
                        </MainBlock>
                        <FileBlock>
                            <fieldset>
                                <legend>Прикрепленные файлы</legend>
                                <hr/>
                                {isStudents ? <MyFileBrowser path={`root/storage/student/student_${params.id}`}
                                                             instanceId={`student_${params.id}`}
                                                             read_only={disabled}
                                /> : <Row>
                                    <Col md={6}>
                                        <label>Приказ о зачислении/направлении на обучение</label>
                                        <MyFileBrowser
                                            getTags={getTags('enrollment_order')}
                                            path={`root/storage/specialist/specialist_${params.id}/enrollment_order`}
                                            instanceId={'enrollment_order'}
                                            height={300}
                                            read_only={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label>Приказ об отчислении</label>
                                        <MyFileBrowser
                                            getTags={getTags('expel_order')}
                                            path={`root/storage/specialist/specialist_${params.id}/expel_order`}
                                            instanceId={'expel_order'}
                                            height={300}
                                            read_only={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label>Документ об образовании</label>
                                        <MyFileBrowser
                                            getTags={getTags('edu_doc')}
                                            path={`root/storage/specialist/specialist_${params.id}/edu_doc`}
                                            instanceId={'edu_doc'}
                                            height={300}
                                            read_only={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label>Дополнительные документы</label>
                                        <MyFileBrowser
                                            getTags={getTags('others')}
                                            path={`root/storage/specialist/specialist_${params.id}/others`}
                                            instanceId={'others'}
                                            height={300}
                                            read_only={disabled}
                                        />
                                    </Col>
                                </Row>}
                            </fieldset>
                        </FileBlock>
                    </Card.Body>
                </Card>
            </Form>
        </FormikProvider>
    </React.Fragment>)
}
