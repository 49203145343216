import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import ReactFilterTable from '../../../components/tables/react_table_filters/react_table_filters'
import {
    FIELDS,
    LABELS,
    MONTH,
    NO_YES,
    SPECIALIST_TYPES,
    STATUS_ACCEPT,
    STATUS_BADGE_CLASS,
    STATUS_LABELS,
    STATUS_NEW,
} from './constant'
import { PERMISSIONS, ROLES } from '../../../rbac/constant'
import { Button } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../../auth'
import { putRequest } from '../../../actions/common'
import { Messages } from '../../../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAbac } from 'react-abac'
import { getSelectedColor, getStatusColor, handleMoveClick, MoveButton, onSelectRow } from '../common'
import { SpecialistPreview } from '../preview'


const Specialist = () => {
    const navigate = useNavigate()
    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()
    const location = useLocation()
    const isStudents = location.pathname.includes('students')

    const [selected, setSelected] = useState([])
    const [viewItem, setViewItem] = useState(null)
    const [status, setStatus] = useState(null)
    const [info, setInfo] = useState(false)
    const [filtering, setFiltering] = useState({ is_educational: !isStudents })
    const [specialist, setSpecialist] = useState(null)
    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['id', 'fio', 'status', 'type', 'project'],
        'Скрытые / Фиксированные колонки': ['edu_doc_date', 'edu_doc_number', 'direction_edu', 'direction', 'staff', 'event', 'organization_employed', 'is_network', 'is_commercial', 'messages', 'enrollment_order', 'diploma_month', 'diploma_year', 'updated_by', 'created_at', 'updated_at', 'created_by'],
    })

    useEffect(() => {
        if (userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && !userHasPermissions(PERMISSIONS.ADMIN_PANEL)) {
            setFiltering({ status_not: STATUS_NEW })
        }
    }, [user])

    const checkField = (field, key) => {
        if (key === 'status') {
            return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
        } else if (['project', 'job_contract'].includes(key) && field) {
            return <span>{field.title_short}</span>
        } else if (key === 'type') {
            return <span>{SPECIALIST_TYPES[field - 1]?.label}</span>
        } else if (key === 'messages') {
            return <Messages messages_={field} status_labels={STATUS_LABELS} source={'specialist'}/>
        } else if (['created_by', 'updated_by'].includes(key) && field) {
            return <span>{field}</span>
        } else if (key === 'staff') {
            if (field) {
                return <Link to={`/staff/item/${field}/?update=false`}>
                    <FontAwesomeIcon icon={'external-link-alt'}/>
                </Link>
            } else {
                return <span></span>
            }
        } else if (key === 'event') {
            if (!field?.title_short || !field?.title) {
                return <span className={'not-set'}>(не задано)</span>
            }
            return `(${field?.title_short}) ${field?.title}`
        } else if (['is_network', 'is_commercial'].includes(key) && field) {
            return NO_YES.find((v) => v?.value === field)?.['label']
        } else if (key === 'diploma_month') {
            return MONTH?.[field - 1]
        }
        return null
    }

    const showInfo = (event) => {
        event.preventDefault()
        setInfo(!info)
    }

    const addNewSpecialist = (state) => {
        putRequest('specialist', {
            status: state?.monitoring ? STATUS_ACCEPT : STATUS_NEW,
            project: user?.project?.id,
            is_educational: state?.is_educational,
            is_draft: true,
        }).then((response) => {
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/${isStudents ? 'students' : 'specialist'}/item/${response.data.id}/?update=true`)
            }
        })
    }

    const templateFileTags = JSON.stringify({
        'document_type': 'template_import',
        'DB': isStudents ? 'students' : 'specialist',
    })

    return (<React.Fragment>
        <ReactBreadcrumb/>
        <div className={'box'}>
            <h1 className={'page-header'}>
                {isStudents ? 'Студенты' : 'Обученные'}
            </h1>
            {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.project_manager) || user?.roles?.includes(ROLES.direction)
                ? <React.Fragment>
                    <div className={'button-actions'}>
                        {!user?.roles?.includes(ROLES.direction) ? <React.Fragment>
                            <Button variant={'outline-primary'} onClick={() => {
                                addNewSpecialist({ is_educational: !isStudents })
                            }}>
                                <IconText icon={'plus'} text={'Создать'}/>
                            </Button>
                        </React.Fragment> : null}
                        {selected.length
                            ? <MoveButton
                                status={status}
                                variantBtn={getStatusColor(status, STATUS_ACCEPT, STATUS_NEW)}
                                labels={STATUS_LABELS}
                                canNext={status !== STATUS_ACCEPT}
                                canBack={status > STATUS_NEW}
                                isAccept={(status + 1) === STATUS_ACCEPT}
                                handleMoveClick={(moveIndex) => handleMoveClick(moveIndex, 'specialist', selected, setSelected, status, setFiltering)}
                                permissionManage={PERMISSIONS.MANAGE_FINANCING}
                            />
                            : null}
                    </div>
                </React.Fragment> : null}
        </div>
        {viewItem
            ? <SpecialistPreview
                itemId={viewItem}
                onClose={() => setViewItem(null)}
                urlUpdate={`/${isStudents ? 'students' : 'specialist'}/item`}
            />
            : null}
        <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                          filtering={filtering}
                          data={specialist} setFunc={setSpecialist} page={1} url={'/specialist'}
                          url_update={`/${isStudents ? 'students' : 'specialist'}/item`}
                          get_title={isStudents ? 'students' : 'specialist'}
                          checkField={checkField}
                          permission={PERMISSIONS.MANAGE_SPECIALIST}
                          fields={FIELDS}
                          urlImport={`/api/${isStudents ? 'students' : 'specialist'}_import/`}
                          urlExport={`${isStudents ? 'students' : 'specialist'}_table_export`}
                          templateFile={`/root/templates/Шаблон импорта бд ${isStudents ? 'Студенты' : 'Обученные'}.xlsx`}
                          templateFileTags={templateFileTags}
                          canSelect
                          onSelect={(ev, el) => onSelectRow(ev, el, specialist, setSelected, selected)}
                          onRowClick={(el) => setViewItem(el?.id)}
                          colorExpression={(el) => getSelectedColor(el, selected)}
                          selectedAll={selected?.length && (specialist['results']?.length === selected?.length)}
                          selected={selected}
                          setStatus={setStatus}
        />
    </React.Fragment>)
}

export default Specialist
