import { Accordion } from 'react-bootstrap'
export const AdditionalInfoText = () => (
    <Accordion>
        <Accordion.Item eventKey='0'>
            <Accordion.Button className={'accordion-head'}>
                Дополнительная информация
            </Accordion.Button>

            <Accordion.Body className={'accordion-body'}>
                <p>
                    <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте
                    следующие
                    условные обозначения,
                    используемые в интерфейсе:
                </p>
                
                <ul>
                    <li>поля помеченные <strong className='text-danger'>красной
                        «*»</strong> являются <strong>обязательными
                        на текущем
                        этапе для перехода на следующий</strong>;
                    </li>
                    <li>поля помеченные <strong className='text-success'>зелёной
                        «*»</strong> являются <strong>обязательными
                        для
                        определенных условий</strong>;
                    </li>
                    <li>кнопка <span className='badge badge-success'>Сохранить</span> выполняет
                        сохранение
                        текущего состояния карточки (требуется
                        заполнения ключевых полей);
                    </li>
                    <li>кнопка <span
                        className='badge badge-success'>Сохранить + отправить далее</span> сохраняет
                        карточку и
                        проверяет выполнение
                        условий текущего этапа (при выполнении условий карточка переходит на следующий
                        этап).
                    </li>
                </ul>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
)