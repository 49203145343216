import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import {
    DatabaseItemControlOop,
    DatabaseItemDpo,
    DatabaseItemEducationalProgram,
    DatabaseItemEducationalSpaces,
    DatabaseItemEmployed,
    DatabaseItemFinancing,
    DatabaseItemInternships,
    DatabaseItemNiokr,
    DatabaseItemPublication,
    DatabaseItemRidInternal,
    DatabaseItemSchool,
    DatabaseItemSpecialist,
} from './items'

const ItemPreview = ({ children, onClose }) => {
    return <Modal size={'xl'} style={{ zIndex: '9999999' }} show={true} centered={true} onHide={() => onClose()}>
        <div className={'modal-content'}>
            <Modal.Header>
                <Modal.Title>
                    Предпросмотр
                </Modal.Title>
                <button className={'close'} onClick={() => onClose()}>
                    <FontAwesomeIcon icon={'times'}/>
                </button>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
        </div>
    </Modal>
}

export const SpecialistPreview = ({ onClose, itemId, urlUpdate }) => {
    return <ItemPreview onClose={onClose} >
        <DatabaseItemSpecialist itemId={itemId} isView urlUpdate={urlUpdate}/>
    </ItemPreview>
}

export const RidInternalPreview = ({ onClose, itemId, urlUpdate }) => {
    return <ItemPreview onClose={onClose} >
        <DatabaseItemRidInternal itemId={itemId} isView urlUpdate={urlUpdate}/>
    </ItemPreview>
}

export const InternshipsPreview = ({ onClose, itemId, urlUpdate }) => {
    return <ItemPreview onClose={onClose} >
        <DatabaseItemInternships itemId={itemId} isView urlUpdate={urlUpdate}/>
    </ItemPreview>
}

export const EducationalProgramPreview = ({ onClose, itemId, urlUpdate }) => {
    return <ItemPreview onClose={onClose} >
        <DatabaseItemEducationalProgram itemId={itemId} isView urlUpdate={urlUpdate}/>
    </ItemPreview>
}

export const ControlOopPreview = ({ onClose, itemId, urlUpdate }) => {
    return <ItemPreview onClose={onClose} >
        <DatabaseItemControlOop itemId={itemId} isView urlUpdate={urlUpdate}/>
    </ItemPreview>
}

export const DpoPreview = ({ onClose, itemId, urlUpdate }) => {
    return <ItemPreview onClose={onClose} >
        <DatabaseItemDpo itemId={itemId} isView urlUpdate={urlUpdate}/>
    </ItemPreview>
}

export const SchoolPreview = ({ onClose, itemId, urlUpdate }) => {
    return <ItemPreview onClose={onClose} >
        <DatabaseItemSchool itemId={itemId} isView urlUpdate={urlUpdate}/>
    </ItemPreview>
}

export const PublicationPreview = ({ onClose, itemId, urlUpdate }) => {
    return <ItemPreview onClose={onClose} >
        <DatabaseItemPublication itemId={itemId} isView urlUpdate={urlUpdate}/>
    </ItemPreview>
}

export const FinancingPreview = ({ onClose, itemId, urlUpdate }) => {
    return <ItemPreview onClose={onClose} >
        <DatabaseItemFinancing itemId={itemId} isView urlUpdate={urlUpdate}/>
    </ItemPreview>
}

export const EmployedPreview = ({ onClose, itemId, urlUpdate }) => {
    return <ItemPreview onClose={onClose} >
        <DatabaseItemEmployed itemId={itemId} isView urlUpdate={urlUpdate}/>
    </ItemPreview>
}

export const EducationalSpacesPreview = ({ onClose, itemId, urlUpdate }) => {
    return <ItemPreview onClose={onClose} >
        <DatabaseItemEducationalSpaces itemId={itemId} isView urlUpdate={urlUpdate}/>
    </ItemPreview>
}

export const NiokrPreview = ({ onClose, itemId, urlUpdate }) => {
    return <ItemPreview onClose={onClose} >
        <DatabaseItemNiokr itemId={itemId} isView urlUpdate={urlUpdate}/>
    </ItemPreview>
}

