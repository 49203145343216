import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { getRequest, putRequest, updateRequest } from '../../../actions/common'
import {
    PUBLICATION_TYPE,
    REPORT_YEAR,
    REQUIRED_FIELDS,
    REQUIRED_FIELDS_TEMP,
    SEMI_REQUIRED_FIELDS,
    STATUS_ACCEPT,
    STATUS_ADDITIONAL_INFO,
    STATUS_DRAFT,
    STATUS_NEW,
} from './constant'
import { Area, Check, Input } from '../../../components/form/forms'
import { PERMISSIONS } from '../../../rbac/constant'
import { useAbac } from 'react-abac'
import { btnMessage } from '../../../utils/utils'
import { MyFileBrowser } from '../../../components/file_manager/file_manager'
import { Field, FormikProvider, useFormik } from 'formik'
import { FormikDate, FormikSelect } from '../../../components/form/formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const PublicationItem = ({
                                    data,
                                    setData,
                                    user,
                                    disabled,
                                    messages,
                                    handleSaveAndMove,
                                    handleChangeValue,
                                    generateOptions,
                                    ErrorsBlock,
                                    MessageHistory,
                                    ActionButtons,
                                    DisableButton,
                                    handleSubmit,
                                    HeaderStatus,
                                }) => {
    const params = useParams()
    const navigate = useNavigate()

    const { userHasPermissions } = useAbac()
    const [new_message, setNewMessage] = useState(null)

    const [select_data, setSelectData] = useState({})

    const select_fields = ['country', 'project', 'staff']


    const [input_fields, setInputFields] = useState({
        'status': 0,
        'current_status': 1,
        'save_form': false,
        'journal': '',
        'publication_title': '',
        'journal_quartile': '',
        'journal_issn': '',
        'journal_isbn': '',
        'impact_factor': '',
        'title': '',
        'type': '',
        'doi': '',
        'wos_indexed': false,
        'scopus_indexed': false,
        'other_authors': '',
        'receipt_date': null,
        'pub_date': null,
        'report_year': null,
        'related_docs_url': null,
    })

    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: (values) => {
            return savePublication()
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })

    useEffect(() => {
        handleSubmit(formik, savePublication)
    }, [formik.values])

    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${data?.id} в БД Публикации | ИС «ПИШ»`
            let set_selected = {}
            select_fields.forEach((field) => {
                if (field === 'staff') {
                    let value = data['authors']?.map((element) => element.id)
                    set_selected['authors'] = value.length > 0 ? value : null
                } else {
                    set_selected[`${field}_id`] = data[field]?.id
                }
            })
            set_selected['report_year'] = data?.report_year

            let set_inputs = {}
            for (let field in input_fields) {
                set_inputs[field] = data[field]
            }

            formik.setValues({ ...set_inputs, ...set_selected, current_status: data.status })

        }
    }, [data])

    useEffect(() => {
        if (Object.keys(select_data).length < select_fields.length) {
            for (let field of select_fields) {
                if (!Object.keys(select_data).includes(field) && field !== 'event') {
                    field === 'staff'
                        ? getRequest('staff_select', setSelectData, {}, '', select_data, field)
                        :                        getRequest(field, setSelectData, { type_data: 'all' }, '', select_data, field)
                    break
                }
            }
        }
    }, [select_data])

    const savePublication = () => {
        formik.setFieldValue('save_form', false)

        let payload = null
        if (STATUS_ACCEPT === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values, direction_by: user.id }
        } else {
            payload = formik.values
        }

        updateRequest('publication', { 'common': payload }, params.id).then((response) => {
            if (response.status === 200) {
                if (payload?.status !== data?.status)
                    navigate('/publication')
                setData(response.data)
            }
        })
        if (formik?.values?.message) {
            putRequest('publication_messages', {
                content: formik?.values?.message,
                publication_status: data.status,
                status: data.status,
                created_by: user.id,
                publication: data.id,
            }, false).then()
            document.getElementById('message').value = null
            formik.setFieldValue('message', '')
        }

        return Promise.resolve('is_saved')
    }

    const additionalButtons = () => {
        let to_return = []
        if (data?.status > STATUS_NEW && data?.status !== STATUS_DRAFT && data?.status !== STATUS_ADDITIONAL_INFO
            && !userHasPermissions(PERMISSIONS.IS_MANAGER)) {
            let status = -data?.status
            to_return.push(<Button key={'save_plus_reject'} variant={'danger'} onClick={() => {
                handleSaveAndMove(status, formik)
            }}>
                <FontAwesomeIcon icon={'save'}/>
                <span> +</span>
                <IconText icon={'times'}
                          text={'Отклонить'}/>
            </Button>)
        }
        return to_return
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <FormikProvider value={formik}>
                <Form>
                    <div className='section-header sticky-top'>
                        <h1 className='page-header'>
                            {`Редактирование записи №${data?.id} в БД Публикации`}
                            <DisableButton/>
                        </h1>
                        <ActionButtons form={formik}
                                       permission_control={PERMISSIONS.CONTROL_PUB}
                                       permission_manage={PERMISSIONS.MANAGE_PUB}
                                       additional_buttons={additionalButtons()}
                        />
                    </div>
                    <div
                        className='mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center'>
                        <HeaderStatus style={{ minWidth: '180px' }}/>
                    </div>
                    <Button variant={'info'} onClick={btnMessage}><IconText icon={'comment'}
                                                                            text={'Сообщения'}/></Button>
                    <Card className={'bg-light mt-4'}
                          style={messages && messages?.length !== 0 || formik.errors?.message ? { display: 'block' } : { display: 'none' }}
                          id={'card-message'}>
                        <Card.Body>
                            <div className='alert alert-danger'
                                 style={{ display: formik.errors?.message ? 'block' : 'none' }}>
                                <p>Исправьте следующие ошибки:</p>
                                <ul>
                                    <li>Необходимо заполнить: "Комментарий"</li>
                                </ul>
                            </div>
                            <label>История сообщений</label>
                            <MessageHistory/>
                            <div id='field-mention' className='content-group navbar-nav px-0'>
                                <div id='field-comment' className='field-staffmessage-content'>
                                    <label htmlFor='publication_message'>Комментарий</label>
                                    <Area id='message'
                                          name={'message'}
                                          value={formik?.values?.message}
                                          disabled={disabled}
                                          error={formik.errors['message']}
                                          invalid={formik.errors['message']}
                                          rows='5'
                                          handleChangeValue={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className={'bg-light mb-3 mt-3'}>
                        <Card.Body>
                            <p>
                                <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте следующие
                                условные обозначения,
                                используемые в интерфейсе:
                            </p>
                            <ul>
                                <li>поля помеченные <strong className='text-danger'>красной
                                    «*»</strong> являются <strong>обязательными
                                    на текущем
                                    этапе для перехода на следующий</strong>;
                                </li>
                                <li>поля помеченные <strong className='text-success'>зелёной
                                    «*»</strong> являются <strong>обязательными
                                    для
                                    определенных условий</strong>;
                                </li>
                                <li>кнопка <span className='badge badge-success'>Сохранить</span> выполняет сохранение
                                    текущего состояния карточки (требуется
                                    заполнения ключевых полей);
                                </li>
                                <li>кнопка <span
                                    className='badge badge-success'>Сохранить + отправить далее</span> сохраняет
                                    карточку и
                                    проверяет выполнение
                                    условий текущего этапа (при выполнении условий карточка переходит на следующий
                                    этап).
                                </li>
                            </ul>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <fieldset>
                                <legend>Показатели журнала</legend>
                                <Input handleChangeValue={formik.handleChange} label={'Наименование журнала'}
                                       id={'journal'}
                                       name={'journal'}
                                       required={data ? REQUIRED_FIELDS[data.status + 1].includes('journal') : false}
                                       error={formik.errors['journal']}
                                       invalid={formik.errors['journal']}
                                       disabled={disabled}
                                       value={formik.values ? formik.values.journal : null}/>
                                <Row>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange} label={'Наименование издания'}
                                               id={'publication_title'}
                                               name={'publication_title'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('publication_title') : false}
                                               error={formik.errors['publication_title']}
                                               invalid={formik.errors['publication_title']}
                                               disabled={disabled}
                                               value={formik.values ? formik.values.publication_title : null}/>
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'country_id'} label={'Страна издания'}
                                               id={'country_id'}
                                               isSearchable={true}
                                               isClearable={true}
                                               options={generateOptions('country', 'title_short', select_data)}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('country') : false}
                                               error={formik.errors['country']}
                                               invalid={formik.errors['country']}
                                               disabled={disabled}
                                        />
                                    </Col>
                                </Row>
                                <Row sm={2} md={4} className={'row-cols-1'}>
                                    <Col>
                                        <Input handleChangeValue={formik.handleChange} label={'Квартиль журнала'}
                                               id={'journal_quartile'}
                                               name={'journal_quartile'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('journal_quartile') : false}
                                               error={formik.errors['journal_quartile']}
                                               mask={'Q*'}
                                               formatChars={{
                                                   '*': '[1-4]',
                                               }}
                                               invalid={formik.errors['journal_quartile']}
                                               disabled={disabled}
                                               value={formik.values ? formik.values.journal_quartile : null}/>
                                    </Col>
                                    <Col>
                                        <Input handleChangeValue={formik.handleChange} label={'ISSN'}
                                               id={'journal_issn'}
                                               name={'journal_issn'}
                                               required_on_transition={data ? SEMI_REQUIRED_FIELDS[data.status + 1].includes('journal_issn') : false}
                                               error={formik.errors['journal_issn']}
                                               mask={'99999999'}
                                               invalid={formik.errors['journal_issn']}
                                               disabled={disabled}
                                               small={'Необходимо указать ISSN и/или ISBN'}
                                               value={formik.values ? formik.values.journal_issn : null}/>
                                    </Col>
                                    <Col>
                                        <Input handleChangeValue={formik.handleChange} label={'ISBN'}
                                               id={'journal_isbn'}
                                               name={'journal_isbn'}
                                               required_on_transition={data ? SEMI_REQUIRED_FIELDS[data.status + 1].includes('journal_isbn') : false}
                                               error={formik.errors['journal_isbn']}
                                               invalid={formik.errors['journal_isbn']}
                                               mask={'9999999999999'}
                                               disabled={disabled}
                                               small={'Необходимо указать ISSN и/или ISBN'}
                                               value={formik.values ? formik.values.journal_isbn : null}/>
                                    </Col>
                                    <Col>
                                        <Input handleChangeValue={formik.handleChange} label={'Импакт-Фактор'}
                                               id={'impact_factor'} required={false}
                                               name={'impact_factor'}
                                               type={'number'}
                                               disabled={disabled}
                                               value={formik.values ? formik.values.impact_factor : null}/>

                                    </Col>
                                </Row>
                                <hr/>
                            </fieldset>
                            <fieldset>
                                <legend>Показатели статьи</legend>
                                <Row>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange} label={'Заголовок'} id={'title'}
                                               name={'title'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('title') : false}
                                               error={formik.errors['title']}
                                               invalid={formik.errors['title']}
                                               disabled={disabled}
                                               value={formik.values ? formik.values.title : null}/>
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange} label={'Тип публикации'}
                                               id={'type'}
                                               name={'type'}
                                               required={false}
                                               value={data?.type ? PUBLICATION_TYPE[data.type] : null} disabled={true}/>
                                    </Col>
                                </Row>
                                <Input handleChangeValue={formik.handleChange} label={'DOI'}
                                       id={'doi'}
                                       name={'doi'}
                                       mask={'10.9999/????????????????????????'}
                                       formatChars={{ '9': '[0-9]', '?': '.', '*': '[A-Za-z0-9]' }}
                                       required={data ? REQUIRED_FIELDS[data.status + 1].includes('doi') : false}
                                       error={formik.errors['doi']}
                                       invalid={formik.errors['doi']}
                                       disabled={disabled}
                                       value={formik.values ? formik.values.doi : null}
                                       small={'В формате 10.xxxx/xxxxx...'}
                                />
                                <Row>
                                    <Col md={6}>
                                        <Check label={'Индексируется в WoS'} id={'wos_indexed'}
                                               name={'wos_indexed'}
                                               handleChangeValue={formik.handleChange}
                                               disabled={disabled}
                                               value={formik.values ? formik.values.wos_indexed : null}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Check label={'Индексируется в Scopus'} id={'scopus_indexed'}
                                               name={'scopus_indexed'}
                                               handleChangeValue={formik.handleChange}
                                               disabled={disabled}
                                               value={formik.values ? formik.values.scopus_indexed : null}
                                        />
                                    </Col>
                                </Row>
                                <Field component={FormikSelect}
                                       name={'authors'}
                                       label={'Авторы-сотрудники ПИШ'}
                                       isSearchable={true}
                                       id={'authors'}
                                       closeMenuOnSelect={false}
                                       isMulti={true}
                                       isClearable={true}
                                       options={generateOptions('staff', 'fio', select_data)}
                                       disabled={disabled}
                                       required={data ? REQUIRED_FIELDS[data.status + 1].includes('authors') : false}
                                       error={formik.errors['authors']}
                                       invalid={formik.errors['authors']}
                                />
                                <Area label={'Прочие авторы'}
                                      id={'other_authors'}
                                      name={'other_authors'}
                                      required={false}
                                      disabled={disabled}
                                      handleChangeValue={formik.handleChange}
                                      value={formik.values ? formik.values.other_authors : null}
                                />
                                <Row sm={2} md={3} className={'row-cols-1'}>
                                    <Col>
                                        <Field component={FormikDate} name={'receipt_date'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата первичного поступления в редакцию'}
                                               id={'receipt_date'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('receipt_date') : false}
                                               error={formik.errors['receipt_date']}
                                               invalid={formik.errors['receipt_date']}
                                               disabled={disabled}
                                               value={formik.values ? formik.values.receipt_date : null}
                                               small={'Дата загрузки на сайт или дата получения письма о принятии к рассмотрению'}
                                        />
                                    </Col>
                                    <Col>
                                        <Field component={FormikDate} name={'pub_date'}
                                               handleChangeValue={formik.handleChange} label={'Дата публикации'}
                                               id={'pub_date'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('pub_date') : false}
                                               error={formik.errors['pub_date']}
                                               invalid={formik.errors['pub_date']}
                                               disabled={disabled}
                                               value={formik.values ? formik.values.pub_date : null}
                                               small={'Дата опубликования статьи'}
                                        />
                                    </Col>
                                    <Col>
                                        <Field component={FormikSelect}
                                               name={'report_year'}
                                               label={'Год зачета публикации'}
                                               options={REPORT_YEAR}
                                               isSearchable={true}
                                               isClearable={true}
                                               id={'report_year'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('report_year') : false}
                                               error={formik.errors['report_year']}
                                               invalid={formik.errors['report_year']}
                                               disabled={disabled}
                                        />
                                    </Col>
                                </Row>
                                <hr/>
                            </fieldset>

                            <fieldset>
                                <legend>Взаимосвязь с проектом ПИШ</legend>
                                <Row>
                                    <Col md={12}>
                                        <Field component={FormikSelect}
                                               name={'project_id'}
                                               label={'Проект'} id={'project_id'}
                                               isSearchable={true}
                                               isClearable={true}
                                               options={generateOptions('project', 'title_short', select_data)}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('project') : false}
                                               error={formik.errors['project']}
                                               invalid={formik.errors['project']}
                                               disabled={user?.roles.every((value) => value === 'project_manager') ? true : disabled}
                                        />
                                    </Col>
                                </Row>
                                <Input handleChangeValue={formik.handleChange}
                                       label={'Ссылка на сопутствующие документы'}
                                       id={'related_docs_url'}
                                       name={'related_docs_url'}
                                       disabled={disabled}
                                       value={formik.values ? formik.values.related_docs_url : null}
                                />
                                <hr/>
                            </fieldset>
                            <fieldset>
                                <legend>Прикрепленные файлы</legend>
                                <hr/>
                                <MyFileBrowser path={`root/storage/publication/publication_${params.id}`}
                                               instanceId={`publication_${params.id}`}
                                               read_only={disabled}/>
                            </fieldset>
                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    )
}
