import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Accordion, Button, Card, Col, Form, Row } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { getRequest, putRequest, updateRequest } from '../../../actions/common'
import {
    MONTHS,
    REPORT_YEAR,
    REQUIRED_FIELDS,
    REQUIRED_FIELDS_TEMP,
    STATUS_ACCEPT,
    STATUS_APPROVE,
    STATUS_NEW,
} from './constant'
import { Area, Input } from '../../../components/form/forms'
import { PERMISSIONS } from '../../../rbac/constant'
import { btnMessage } from '../../../utils/utils'
import { MyFileBrowser } from '../../../components/file_manager/file_manager'
import { Field, FormikProvider, useFormik } from 'formik'
import { FormikSelect } from '../../../components/form/formik'

export const SchoolItem = ({
                               data,
                               user,
                               disabled,
                               messages,
                               generateOptions,
                               ErrorsBlock,
                               MessageHistory,
                               ActionButtons,
                               DisableButton,
                               HeaderStatus,
                               handleSubmit,
                               setData,
                               MainBlock,
                               FileBlock,
                               ToggleButtons,
                               EditButton,
                           }) => {
    const params = useParams()
    const navigate = useNavigate()
    const project_ref = useRef()

    const [select_data, setSelectData] = useState({})
    const FILE_PATH = `root/storage/school/school_${params.id}`
    const [s3Tags, setS3Tags] = useState({
        'document_type': 'attach_file',
        'year': '',
        'month': '',
        'project': '',
        'school_item': params.id,
    })

    const select_fields = ['project', 'event_type']

    const [input_fields, setInputFields] = useState({
        'status': 1,
        'current_status': 1,
        'save_form': false,
        'event_info': '',
        'deadline': '',
        'organization': '',
        'region': '',
        'count_pupils': null,
        'month': null,
        'year': null,
        'project_id': null,
        'event_name': '',
        'event_type': null,
        'docs_link': '',
        'docs_title': '',
    })

    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: () => {
            return saveFunction()
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })


    useEffect(() => {
        handleSubmit(formik, saveFunction)
    }, [formik.values])

    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${data?.id} в БД Школьники | ИС «ПИШ»`

            let set_selected = {}
            select_fields.forEach((field) => {
                set_selected[`${field}_id`] = data[field]?.id
            })
            let set_inputs = {}
            for (let field in input_fields) {
                set_inputs[field] = data[field]
            }

            formik.setValues({
                ...set_inputs,
                ...set_selected,
                current_status: data.status,
            })
        }
    }, [data])

    useEffect(() => {
        if (data) {
            let is_manager = user?.roles.every((value) => value === 'project_manager')
            if (Object.keys(select_data).length < select_fields.length) {
                for (let field of ['project', 'school_event']) {
                    if (!Object.keys(select_data).includes(field)) {
                        if (field === 'project' && is_manager) {
                            getRequest('project', setSelectData, {
                                type_data: 'user_projects',
                                'user_id': user.id,
                            }, '', select_data, field).then()
                        } else {
                            getRequest(field, setSelectData, { type_data: 'all' },
                                '',
                                select_data,
                                field).then()
                        }
                        break
                    }
                }
            }
        }
    }, [data, select_data, formik.values])

    useEffect(() => {
        setS3Tags({
            'document_type': 'attach_file',
            'year': formik.values.year,
            'month': formik.values.month,
            'project': formik.values.project_id,
            'school_item': params.id,
        })
    }, [formik.values.project_id, formik.values.month, formik.values.year])

    useEffect(() => {
        if (formik.values?.project_id)
            project_ref.current = formik.values?.project_id
    }, [formik.values])


    const saveFunction = () => {
        formik.setFieldValue('save_form', false)

        let payload = null
        if (STATUS_ACCEPT === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values, direction_by: user.id }
        } else {
            payload = formik.values
        }

        updateRequest('school', { 'common': payload }, params.id).then((response) => {
            if (response.status === 200) {
                if (payload?.status !== data?.status)
                    navigate('/school')
                setData(response.data)
            }
        })

        const fileData = { path: FILE_PATH, tags: s3Tags }
        putRequest('directory', fileData, false)

        if (formik?.values?.message) {
            putRequest('school_messages', {
                content: formik?.values?.message,
                school_status: data.status,
                status: data.status,
                created_by: user.id,
                school: data.id,
            }, false).then()
            document.getElementById('message').value = null
            formik.setFieldValue('message', '')
        }

        return Promise.resolve('is_saved')
    }

    const getHoverContext = (element) => {
        return <>
            <b>Варианты мероприятий:</b>
            <ul>
                {element?.variants?.map((variant) => <li key={variant}>{variant}</li>)}
            </ul>
        </>
    }

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <ReactBreadcrumb/>
                <Form>
                    <div className='box'>
                        <div className={`d-flex align-items-center ${disabled ? 'justify-content-between' : ''}`}>
                            <h1 className='page-header d-flex'>
                                {`№${data?.id} в БД Школьники`}
                            </h1>
                            <DisableButton/>
                        </div>
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <Button variant={'outline-primary'} className={'flex-shrink-0 mt-1 mr-2'} size={'md'}
                                    onClick={btnMessage}>
                                <IconText icon={'comment'}
                                          text={disabled ? 'Сообщения' : ''}/>
                            </Button>
                            <EditButton/>
                            <ActionButtons form={formik}
                                           permission_control={PERMISSIONS.CONTROL_SCHOOL}
                                           permission_manage={PERMISSIONS.MANAGE_SCHOOL}
                                           can_back={data?.status > STATUS_NEW
                                               && data?.status !== STATUS_ACCEPT}
                            />
                        </div>
                    </div>
                    <Card className={'bg-white mt-4 mb-4'}
                          style={messages && messages?.length !== 0
                          || formik.errors?.message
                              ? { display: 'block' }
                              : { display: 'none' }}
                          id={'card-message'}>
                        <Card.Body>
                            <div className='alert alert-danger'
                                 style={{ display: formik.errors?.message ? 'block' : 'none' }}>
                                <p>Исправьте следующие ошибки:</p>
                                <ul>
                                    <li>Необходимо заполнить: "Комментарий"</li>
                                </ul>
                            </div>
                            <label>История сообщений</label>
                            <MessageHistory/>
                            <div id='field-mention' className='content-group navbar-nav px-0'>
                                <div id='field-comment'>
                                    <label htmlFor='message'>Комментарий</label>
                                    <Area id='message'
                                          name={'message'}
                                          value={formik.values?.message}
                                          disabled={disabled}
                                          rows='5'
                                          error={formik.errors['message']}
                                          invalid={formik.errors['message']}
                                          handleChangeValue={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Accordion>
                        <Accordion.Item eventKey='0'>
                            <Accordion.Button className={'accordion-head'}>Дополнительная информация</Accordion.Button>
                            <Accordion.Body className={'accordion-body'}>
                                <p>
                                    <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте
                                    следующие
                                    условные обозначения,
                                    используемые в интерфейсе:
                                </p>
                                <ul>
                                    <li>поля помеченные <strong className='text-danger'>красной
                                        «*»</strong> являются <strong>обязательными
                                        на текущем
                                        этапе для перехода на следующий</strong>;
                                    </li>
                                    <li>поля помеченные <strong className='text-success'>зелёной
                                        «*»</strong> являются <strong>обязательными
                                        для
                                        определенных условий</strong>;
                                    </li>
                                    <li>кнопка <span className='badge badge-success'>Сохранить</span> выполняет
                                        сохранение
                                        текущего состояния карточки (требуется
                                        заполнения ключевых полей);
                                    </li>
                                    <li>кнопка <span
                                        className='badge badge-success'>Сохранить + отправить далее</span> сохраняет
                                        карточку и
                                        проверяет выполнение
                                        условий текущего этапа (при выполнении условий карточка переходит на следующий
                                        этап).
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Card className={'bg-white mb-3 mt-3'}>
                        <Card.Body>
                            <ToggleButtons/>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <MainBlock>
                                <fieldset>
                                    <legend>Основные данные</legend>
                                    <Field component={FormikSelect} label={'Проект'} id={'project_id'}
                                           isSearchable={true}
                                           options={generateOptions('project', 'title_short', select_data)}
                                           name={'project_id'}
                                           disabled={disabled}
                                           error={formik.errors['project']}
                                           invalid={formik.errors['project']}
                                           required={data
                                               ? REQUIRED_FIELDS[data.status + 1].includes('project_id')
                                               : false}/>
                                    <Row>
                                        <Col md={6}>
                                            <Input handleChangeValue={formik.handleChange}
                                                   label={'Наименование мероприятия'}
                                                   id={'event_name'}
                                                   name={'event_name'}
                                                   error={formik.errors['event_name']}
                                                   invalid={formik.errors['event_name']}
                                                   disabled={disabled}
                                                   value={formik.values.event_name}
                                                   required={REQUIRED_FIELDS[STATUS_NEW].includes('event_name')}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Field component={FormikSelect}
                                                   name={'event_type'}
                                                   label={'Тип мероприятия'}
                                                   isClearable={true}
                                                   options={
                                                       generateOptions('school_event', 'title', select_data,
                                                           null, null, getHoverContext)
                                                   }
                                                   id={'event_type'}
                                                   error={formik.errors['event_type_id']}
                                                   invalid={formik.errors['event_type_id']}
                                                   disabled={disabled}
                                                   required={REQUIRED_FIELDS[STATUS_NEW].includes('event_type')}
                                            />
                                        </Col>
                                    </Row>
                                    <Area label={'Содержание мероприятия'}
                                          id={'event_info'}
                                          name={'event_info'}
                                          handleChangeValue={formik.handleChange}
                                          value={formik.values.event_info}
                                          error={formik.errors['event_info']}
                                          invalid={formik.errors['event_info']}
                                          required={REQUIRED_FIELDS[STATUS_NEW].includes('event_info')}
                                          disabled={disabled}
                                    />
                                    <Row>
                                        <Col md={6}>
                                            <Input name={'deadline'}
                                                   handleChangeValue={formik.handleChange}
                                                   label={'Срок проведения'}
                                                   small={`Введите дату проведения в формате "дд.мм.гггг" или 
                                               промежуток проведения в формате "дд.мм.гггг - дд.мм.гггг"`}
                                                   id={'deadline'}
                                                   disabled={disabled}
                                                   required={REQUIRED_FIELDS[STATUS_NEW].includes('deadline')}
                                                   value={formik.values.deadline}
                                                   error={formik.errors['deadline']}
                                                   invalid={formik.errors['deadline']}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Area handleChangeValue={formik.handleChange}
                                                  label={'Организация/школа'}
                                                  id={'organization'}
                                                  name={'organization'}
                                                  error={formik.errors['organization']}
                                                  invalid={formik.errors['organization']}
                                                  disabled={disabled}
                                                  value={formik.values.organization}
                                                  required={REQUIRED_FIELDS[STATUS_NEW].includes('organization')}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input handleChangeValue={formik.handleChange}
                                                   label={'Регион'}
                                                   id={'region'}
                                                   name={'region'}
                                                   error={formik.errors['region']}
                                                   invalid={formik.errors['region']}
                                                   disabled={disabled}
                                                   value={formik.values.region}
                                                   required={REQUIRED_FIELDS[STATUS_NEW].includes('region')}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input handleChangeValue={formik.handleChange}
                                                   label={'Количество школьников'}
                                                   type={'int'}
                                                   id={'count_pupils'}
                                                   name={'count_pupils'}
                                                   error={formik.errors['count_pupils']}
                                                   invalid={formik.errors['count_pupils']}
                                                   disabled={disabled}
                                                   value={formik.values.count_pupils}
                                                   required={REQUIRED_FIELDS[STATUS_NEW].includes('count_pupils')}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Field component={FormikSelect}
                                                   name={'month'} label={'Месяц включения в отчет'}
                                                   id={'month'}
                                                   isSearchable={true}
                                                   isClearable={true}
                                                   options={MONTHS.map((v, i) => {
                                                       return { value: i + 1, label: v }
                                                   })}
                                                   required={data?.status === STATUS_APPROVE}
                                                   error={formik.errors['month_id']}
                                                   invalid={formik.errors['month_id']}
                                                   disabled={disabled}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Field component={FormikSelect}
                                                   name={'year'} label={'Год включения в отчет'}
                                                   id={'year'}
                                                   isSearchable={true}
                                                   isClearable={true}
                                                   options={REPORT_YEAR}
                                                   required={data?.status === STATUS_APPROVE}
                                                   error={formik.errors['year_id']}
                                                   invalid={formik.errors['year_id']}
                                                   disabled={disabled}
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <Input handleChangeValue={formik.handleChange}
                                                   label={'Ссылка на документы'}
                                                   id={'docs_link'}
                                                   name={'docs_link'}
                                                   error={formik.errors['docs_link']}
                                                   invalid={formik.errors['docs_link']}
                                                   disabled={disabled}
                                                   value={formik.values.docs_link}
                                                   required={REQUIRED_FIELDS[STATUS_NEW].includes('docs_link')}
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <Input handleChangeValue={formik.handleChange}
                                                   label={'Названия документов'}
                                                   id={'docs_title'}
                                                   name={'docs_title'}
                                                   error={formik.errors['docs_title']}
                                                   invalid={formik.errors['docs_title']}
                                                   small={'Вам необходимо отметить, что есть документ, описывающий содержание мероприятия, и список школьников.'}
                                                   disabled={disabled}
                                                   value={formik.values.docs_title}
                                                   required={data ? REQUIRED_FIELDS[data.status].includes('docs_title') : false}
                                            />
                                        </Col>
                                    </Row>
                                </fieldset>
                            </MainBlock>
                            <FileBlock>
                                <fieldset>
                                    <legend>Прикрепленные файлы</legend>
                                    <small>Пожалуйста, загружайте файл с названием "[Фамилия руководителя] _ [вид
                                        документа]
                                        _ [название мероприятия]"</small>
                                    <hr/>
                                    <MyFileBrowser path={FILE_PATH}
                                                   instanceId={`school_${params.id}`}
                                                   tags={s3Tags}
                                                   read_only={disabled}
                                    />
                                </fieldset>
                            </FileBlock>
                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    )
}
