import { useAbac } from 'react-abac'
import { Area, Check } from '../../../components/form/forms'
import { 
    CAN_APPROVE_ROLES,
    CAN_MANAGE_ROLES_RESULTS,
    REQUIRED_FIELDS,
    STATUS_ACCEPT,
    STATUS_APPROVE,
    STATUS_PROCESS,
} from '../../../screens/database/niokr/constant'
import { PERMISSIONS } from '../../../rbac/constant'

export const ExpectedResultYear = ({ 
    formik, 
    item, 
    index, 
    data = null, 
    user = null,
    setDisabledRes,
}) => {
    const { userHasPermissions } = useAbac()

    const canAccept = user.roles.some((role) => CAN_APPROVE_ROLES[data?.status].includes(role)) && data?.status === STATUS_PROCESS
    const canSendToApprove = userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status === STATUS_APPROVE && !item.accepted


    return ( 
        <fieldset>
            <legend>
                Ожидаемый результат
                за {item?.year}
            </legend>

            <Area
                label={''}
                id={`expected_result_year.${index}.result`}
                name={`expected_result_year.${index}.result`}
                handleChangeValue={formik.handleChange}
                value={item.result}
                step={'any'}
                disabled={setDisabledRes(item.accepted)}
                required={REQUIRED_FIELDS?.[data?.status]?.includes('expected_result_year')}
                error={formik.errors[`expected_result_year.${index}.result`]}
                invalid={formik.errors[`expected_result_year.${index}.result`] || (data?.status === STATUS_PROCESS && item.needAcceptResult && !item.accepted)}
            />

            {data && canAccept && (
                <Check
                    label={'Принять результат за год'}
                    id={`expected_result_year.${index}.accepted`}
                    value={item.accepted}
                    handleChangeValue={formik.handleChange}
                />
            )}

            {data && canSendToApprove && (
                <Check
                    label={'Отправить на согласование информацию в поле выше'}
                    id={`expected_result_year.${index}.needAcceptResult`}
                    value={item.needAcceptResult}
                    handleChangeValue={formik.handleChange}
                />
            )}
        </fieldset>
   )    
}
