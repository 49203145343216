import { MONTHS } from '../../screens/database/niokr/constant'
import { Area } from '../form/forms'

export const MonthResults = ({ 
    formik, 
    yearResults, 
    index, 
    field='year_result',
    data = null, 
    user = null,
    AddToMonth=null,
    setDisabledRes,
}) => {

    const output = yearResults[index].month_results?.map((month_res, month_index) => (
        <>
            <div key={`year-${index}-${month_index}`} className={'mt-3'}>
                <Area
                    label={MONTHS[month_index + 1]}
                    id={`${field}.${index}.month_results.${month_index}.results`}
                    name={`${field}.${index}.month_results.${month_index}.results`}
                    handleChangeValue={formik.handleChange}
                    value={month_res.results}
                    step={'any'}
                    disabled={setDisabledRes(month_res.accepted)}
                    error={formik.errors[`month_results.${month_index}.results`]}
                    invalid={formik.errors[`month_results.${month_index}.results`]}
                />
            </div>
            {AddToMonth && (
                <AddToMonth data={data} 
                            user={user}
                            formik={formik} 
                            month_res={month_res} 
                            index={index} 
                            month_index={month_index}
                />
            )}
        </>
    ))

    return <>{output}</>
}
