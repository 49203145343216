import { useAbac } from 'react-abac'
import { 
    CAN_APPROVE_ROLES, 
    STATUS_APPROVE, 
    STATUS_PROCESS,
} from '../../../screens/database/niokr/constant'
import { PERMISSIONS } from '../../../rbac/constant'
import { Check } from '../../../components/form/forms'

export const AcceptMonths = ({ data, user, formik, month_res, index, month_index }) => {
    const { userHasPermissions } = useAbac()

    const canAccept = user.roles.some((role) => CAN_APPROVE_ROLES[data?.status].includes(role)) && data?.status === STATUS_PROCESS
    const canSendToApprove = userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status === STATUS_APPROVE && !month_res.accepted
    
    return (
        <>
            {data && canAccept && (
                <Check
                    label={'Принять результат за месяц'}
                    id={`expected_result_year.${index}.month_results.${month_index}.accepted`}
                    value={month_res.accepted}
                    handleChangeValue={formik.handleChange}
                />
            )}
            {data && canSendToApprove && (
                <Check
                    label={'Отправить на согласование информацию в поле выше'}
                    id={`expected_result_year.${index}.month_results.${month_index}.needAcceptResult`}
                    value={month_res.needAcceptResult}
                    handleChangeValue={formik.handleChange}
                />
            )}
        </>
    )
}
