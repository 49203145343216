import * as React from 'react'
import { useContext, useState } from 'react'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { useNavigate } from 'react-router-dom'
import { PERMISSIONS } from '../../../rbac/constant'
import { Button } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { AuthContext } from '../../../auth'
import { FIELDS, LABELS, SPACE_TYPES, STATUS_ACCEPT, STATUS_BADGE_CLASS, STATUS_LABELS } from './constant'
import { putRequest } from '../../../actions/common'
import { Messages } from '../../../utils/utils'
import { useAbac } from 'react-abac'
import { FileList } from '../../../components/file_manager/file_handlers'
import ReactFilterTable from '../../../components/tables/react_table_filters/react_table_filters'
import { EducationalSpacesPreview } from '../preview'


const EducationalSpaces = () => {
    const navigate = useNavigate()
    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()
    const [viewItem, setViewItem] = useState(null)

    const [edu, setEdu] = useState(null)
    const [filtering, setFiltering] = useState(null)
    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': [
            'id',
            'space_name',
            'description',
            'space_type',
            'location',
            'area',
            'project',
            'opening_plan',
            'order_url',
            'files',
            'comment',
        ],
        'Скрытые / Фиксированные колонки': [
            'updated_by',
            'created_by',
            'created_at',
            'updated_at',
            'messages',
        ],
    })

    const checkField = (field, key, element) => {
        if (key === 'status') {
            if (key === 'status') {
                return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
            }
        }
        if (key === 'space_type') {
            let label = SPACE_TYPES.find((v) => v?.value === field)?.['label']
            return label ? label : <span className={'not-set'}>(не задано)</span>
        }
        if (['project'].includes(key) && field) {
            return <span>{field.title_short}</span>
        }
        if (key === 'messages') {
            return <Messages messages_={field} status_labels={STATUS_LABELS} source={'educational_spaces'}/>
        }
        if (key === 'files') {
            return <FileList files={field} title={'educational_spaces'}
                             folder={field?.[0]?.path}/>
        }

        return null
    }


    const addNewEducationalProgram = (state) => {
        putRequest('educational_spaces', {
            status: STATUS_ACCEPT, project: user?.project?.id,
        }).then((response) => {
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/educational_spaces/item/${response.data.id}/?update=true`)
            }
        })
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <div className={'box'}>
                <h1 className={'page-header'}>
                    Образовательные пространства
                </h1>
                {userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                    ? <React.Fragment>
                        <div className={'button-actions'}>
                            <Button variant={'primary'} onClick={() => {
                                addNewEducationalProgram()
                            }}>
                                <IconText icon={'plus'} text={'Создать'}/>
                            </Button>
                        </div>
                    </React.Fragment> : null}
            </div>
            {viewItem
                ? <EducationalSpacesPreview
                    itemId={viewItem}
                    onClose={() => setViewItem(null)}
                    urlUpdate={'/educational_spaces/item'}
                />
                : null}
            <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                              filtering={filtering}
                              data={edu} setFunc={setEdu} page={1} url={'/educational_spaces'}
                              url_update={'/educational_spaces/item'}
                              onRowClick={(el) => setViewItem(el?.id)}
                              get_title={'educational_spaces'}
                              checkField={checkField}
                              permission={PERMISSIONS.DIRECTION_PANEL}
                              fields={FIELDS}
            />
        </React.Fragment>
    )
}

export default EducationalSpaces
