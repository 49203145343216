import * as React from 'react'
import { useContext, useState } from 'react'
import ReactBreadcrumb from '../../../../components/breadcrumbs'
import ReactFilterTable from '../../../../components/tables/react_table_filters/react_table_filters'
import { useNavigate } from 'react-router-dom'
import { PERMISSIONS, ROLES } from '../../../../rbac/constant'
import { Badge, Button, ButtonGroup } from 'react-bootstrap'
import { IconText } from '../../../../components/icon_txt'
import { AuthContext } from '../../../../auth'
import {
    FIELDS,
    LABELS,
    RID_DOCUMENT_TYPES,
    RID_FINANCING_METHOD,
    RID_STATUS,
    RID_TYPES,
    STATUS_ACCEPT,
    STATUS_BADGE_CLASS,
    STATUS_LABELS,
    STATUS_NEW,
} from './constant'
import { getLabelFromOptions, Messages } from '../../../../utils/utils'
import { putRequest } from '../../../../actions/common'
import { useAbac } from 'react-abac'
import { getSelectedColor, getStatusColor, handleMoveClick, MoveButton, onSelectRow } from '../../common'
import { RidInternalPreview } from '../../preview'

const RidInternal = () => {
    const navigate = useNavigate()
    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()


    const [selected, setSelected] = useState([])
    const [status, setStatus] = useState(null)
    const [rid, setRid] = useState(null)
    const [filtering, setFiltering] = useState(null)
    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['id', 'status', 'title', 'type', 'document_type',
            'project', 'application_status'],
        'Скрытые / Фиксированные колонки': ['updated_by', 'created_at', 'updated_at',
            'created_by', 'direction_rid_by', 'organization_name', 'patent_office',
            'direction_by', 'authors_input', 'number',
            'messages', 'fin_method', 'application_date', 'application_number',
        ],
    })
    const templateFileTags = JSON.stringify({ 'document_type': 'template_import', 'DB': 'rid' })
    const [viewItem, setViewItem] = useState(null)


    const checkField = (field, key) => {
        if (key === 'status') {
            return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
        }
        if (['country', 'patent_office'].includes(key) && field) {
            return <span>{field.title}</span>
        }
        if (key === 'event') {
            return field?.title_short ? `(${field?.title_short}) ${field?.title}` : field?.title
        }
        if (['project', 'job_contract'].includes(key) && field) {
            return <span>{field.title_short}</span>
        }
        if (key === 'type') {
            return <span>{getLabelFromOptions(RID_TYPES, field)}</span>
        }
        if (key === 'fin_method') {
            return <span>{getLabelFromOptions(RID_FINANCING_METHOD, field)}</span>
        }
        if (key === 'document_type') {
            return <span>{getLabelFromOptions(RID_DOCUMENT_TYPES, field)}</span>
        }
        if (key === 'application_status') {
            let ridStatusBgColor = (field === -1) ? 'danger' : (field === 2) ? 'warning' : 'success'
            let ridStatusColor = (field === 2) ? 'dark' : 'white'
            return (
                <span>
                    <Badge bg={ridStatusBgColor} style={{ color: ridStatusColor }}>
                        {getLabelFromOptions(RID_STATUS, field)}
                    </Badge>
                </span>
            )
        }
        if (key === 'messages') {
            return <Messages messages_={field} status_labels={STATUS_LABELS} source={'rid'}/>
        }
        if (key === 'title') {
            return <span>
                {field?.ru
                    ? (<span className={'d-flex align-items-center'}>
                        <Badge bg={'secondary'} style={{ color: 'white' }} className={'mr-1'}>
                            Ru:
                        </Badge>
                        <span className={'ellipsis-cell'}>{field?.ru}</span>
                    </span>)
                    : null
                }
                {field?.en
                    ? (<>
                        <br/>
                        <span className={'d-flex align-items-center'}>
                        <Badge bg={'secondary'} style={{ color: 'white' }} className={'mr-1'}>
                            En:
                        </Badge>
                        <span className={'ellipsis-cell'}>{field?.en}</span>
                    </span>
                    </>)
                    : null
                }
            </span>
        }
        return null
    }

    const addNewRid = (state) => {
        putRequest('rid_internal', {
            status: state?.monitoring ? STATUS_ACCEPT : STATUS_NEW,
            project: user?.project?.id,
            is_draft: true,
        }).then((response) => {
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/rid/rid-internal/item/${response.data.id}/?update=true`)
            }
        })
    }

    return (<React.Fragment>
        <ReactBreadcrumb/>
        <div className='box sticky-top'>
            <h1 className={'page-header'}>
                РИДы
            </h1>
            <div className={'d-flex justify-content-between align-items-center'}>
                <div>
                    {userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                        && <div className='button-actions'>
                            <ButtonGroup>
                                <Button variant={'outline-secondary'} onClick={() => {
                                    navigate('/rid/rid-external')
                                }}>
                                    <IconText icon={'circle'} text={'Внешний мониторинг'}/>
                                </Button>
                                <Button variant={'secondary'} disabled={true} onClick={() => {
                                    navigate('/rid/rid-internal')
                                }}>
                                    <IconText icon={'dot-circle'} text={'Внутренний мониторинг'}/>
                                </Button>
                            </ButtonGroup>
                        </div>
                    }
                </div>
                <div>
                    {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.direction_rid)
                    || user?.roles?.includes(ROLES.project_manager) || user?.roles?.includes(ROLES.direction)
                        ? <React.Fragment>
                            <div className={'button-actions'}>
                                {!user?.roles?.includes(ROLES.direction)
                                    ? <Button variant={'primary'} onClick={() => {
                                        addNewRid()
                                    }}>
                                        <IconText icon={'plus'} text={'Создать'}/>
                                    </Button> : null}
                                {selected.length
                                    ? <MoveButton
                                        status={status}
                                        variantBtn={getStatusColor(status, STATUS_ACCEPT, STATUS_NEW)}
                                        labels={STATUS_LABELS}
                                        canNext={status !== STATUS_ACCEPT}
                                        canBack={status > STATUS_NEW}
                                        isAccept={(status + 1) === STATUS_ACCEPT}
                                        handleMoveClick={(moveIndex) => handleMoveClick(moveIndex, 'rid_internal', selected, setSelected, status, setFiltering)}
                                        permissionManage={PERMISSIONS.MANAGE_FINANCING}
                                    />
                                    : null}
                            </div>

                        </React.Fragment> : null}
                </div>
            </div>
        </div>
        {viewItem
            ? <RidInternalPreview
                itemId={viewItem}
                onClose={() => setViewItem(null)}
                urlUpdate={'/rid/rid-internal/item'}
            />
            : null}
        <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                          filtering={filtering}
                          data={rid} setFunc={setRid} page={1} url={'/rid/rid-internal/item'}
                          url_update={'/rid/rid-internal/item'}
                          get_title={'rid_internal'}
                          checkField={checkField}
                          permission={PERMISSIONS.MANAGE_RID}
                          urlImport={'/api/rid_internal_import_request/'}
                          templateFile={'import_rid_template.xlsx'}
                          templateFileTags={templateFileTags}
                          fields={FIELDS}
                          canSelect
                          onRowClick={(el) => setViewItem(el?.id)}
                          onSelect={(ev, el) => onSelectRow(ev, el, rid, setSelected, selected)}
                          colorExpression={(el) => getSelectedColor(el, selected)}
                          selectedAll={selected?.length && (rid['results']?.length === selected?.length)}
                          selected={selected}
                          setStatus={setStatus}
        />
    </React.Fragment>)
}

export default RidInternal
