import { ROLES } from '../../../rbac/constant'
import { Input, InputDate, Select } from '../../../components/form/forms'
import * as Yup from 'yup'

export const STATUS_DRAFT = -1             // Черновик
export const STATUS_NEW = 1                // Новая сущность в БД
export const STATUS_APPROVE = 2            // Рассматривается Дирекцией
export const STATUS_ACCEPT = 3             // Принято
export const STATUS_TEMP = 4            // Принято


export const INITIAL_FORMIK_HELPER = {
    type: '',
    change_date: false,
    show_messages: false,
    message: '',
}

export const STATUS_LABELS = {
    [STATUS_NEW]: 'Заполнение менеджером',
    [STATUS_APPROVE]: 'Проверка дирекцией',
    [STATUS_ACCEPT]: 'Закрыта',
}

export const STATUS_OPTIONS = [
    { value: STATUS_NEW, label: 'Заполнение менеджером' },
    { value: STATUS_APPROVE, label: 'Проверка дирекцией' },
    { value: STATUS_ACCEPT, label: 'Закрыта' },
    // {value: STATUS_ACCOUNTING, label: 'Ведётся учёт по расходам'},
]

export const STATUS_BADGE_CLASS = {
    [STATUS_NEW]: 'badge-warning',
    [STATUS_APPROVE]: 'badge-info',
    [STATUS_ACCEPT]: 'badge-success',
    // [STATUS_ACCOUNTING]: 'badge-success',
}

export const EVENT_TYPES = [
    { value: 1, label: 'Бакалавриат' },
    { value: 2, label: 'Магистратура' },
    { value: 3, label: 'Аспирантура' },
    { value: 4, label: 'Специалитет' },
    { value: 5, label: 'Повышение квалификации' },
    { value: 6, label: 'Профессиональная переподготовка' },
]

export const IS_NETWORK = [
    { value: 1, label: 'Нет' },
    { value: 2, label: 'Да' },
]

export const EMPTY_RESULT_YEAR = {
    year: new Date().getFullYear(),
    result: '',
    accepted: false,
    needAcceptResult: false,
    month_results: new Array(12).fill({
        month: 1,
        results: '',
        accepted: false,
        needAcceptResult: false,
    }, 0, 12),
}

export const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
]

export const YEAR = [
    { value: 2021, label: 2021 },
    { value: 2022, label: 2022 },
    { value: 2023, label: 2023 },
    { value: 2024, label: 2024 },
    { value: 2025, label: 2025 },
    { value: 2026, label: 2026 },
    { value: 2027, label: 2027 },
    { value: 2028, label: 2028 },
    { value: 2029, label: 2029 },
    { value: 2030, label: 2030 },
]

export const LABELS = {
    'id': 'ID',
    'status': 'Статус',
    'event_name': 'Наименование программы',
    'is_network': 'Сетевая',
    'speciality': 'Специальность и направления подготовки',
    'event_type': 'Тип программы',
    'start': 'Начало',
    'project': 'Проект',
    'end': 'Окончание',
    'partners': 'Задействованные в реализации, высокотехнологичные компании партнеры',
    'supervisor': 'Руководитель паспорта',
    'responsible_edu': 'Ответственный за образование',
    'created_by': 'Создано',
    'updated_by': 'Обновлено',
    'created_at': 'Дата создания',
    'updated_at': 'Дата обновления',
    'direction_by': 'Дирекция',
    'message': 'Комментарий',
    'messages': 'Сообщения',
    'dev_date': 'Программа разработана',
    'impl_date_plan': 'Программа внедрена',
    'impl_date_real': 'Дата внедрения (месяц и год учета в показателе)',
    'indicator_month': 'Месяц внедрения (месяц учета в показателе)',
    'indicator_year': 'Год внедрения (год учета в показателе)',
    'retrain_date': 'Повторное обучение по программе',
    'start_order_date': 'Дата приказа об открытии',
    'first_launch_date': 'Дата первого запуска',
    'next_training_date': 'Дата последующего обучения',
    'document_link': 'Ссылка на документы',
    'count_educated': 'Количество обученных',
    'count_educated_commercial': 'Из количества обученных: на коммерческой основе',
    'speciality_custom': 'Специальность и направления подготовки',

    'matrix_dev': 'Разработка матрицы комментарий',
    'reference': 'Справка-обоснование',
    'characteristic': 'Общая характеристика ООП',
    'curriculum': 'Учебный план',
    'information_pedagogical': 'Сведения о педагогических работниках',
    'information_specialist': 'Сведения о специалистах-практиках',
    'information_mto': 'Сведения о МТО',
    'information_leader': 'Сведения о руководителе научным содержанием магистерской программы',
    'extract_protocol': 'Выписка из протокола заседания Ученого совета института',
}

export const BLOCKS_IDS = {
    'matrix_dev': 1,
    'reference': 2,
    'characteristic': 3,
    'curriculum': 4,
    'information_pedagogical': 5,
    'information_specialist': 6,
    'information_mto': 7,
    'information_leader': 8,
    'extract_protocol': 9,
}

export const FIELDS = {
    id: {
        type: Input,
        content: 'number',
        props: {
            show_zero: false,
        },
    },
    status: {
        type: Select,
        options: STATUS_OPTIONS,
    },
    event_type: {
        type: Select,
        options: EVENT_TYPES,
    },
    event_name: {
        type: Input,
        content: 'text',
    },
    speciality: {
        type: Select,
        source: 'speciality',
        key: (v) => `${v?.title}`,
    },
    is_network: {
        type: Select,
        options: IS_NETWORK,
    },
    start: {
        type: InputDate,
        content: 'date',
    },
    end: {
        type: InputDate,
        content: 'date',
    },
    dev_date: {
        type: InputDate,
        content: 'date',
    },
    impl_date_plan: {
        type: InputDate,
        content: 'date',
    },
    impl_date_real: {
        type: InputDate,
        content: 'date',
    },
    indicator_month: {
        type: Input,
        content: 'number',
    },
    indicator_year: {
        type: Input,
        content: 'number',
    },
    retrain_date: {
        type: InputDate,
        content: 'date',
    },
    start_order_date: {
        type: InputDate,
        content: 'date',
    },
    first_launch_date: {
        type: InputDate,
        content: 'date',
    },
    next_training_date: {
        type: InputDate,
        content: 'date',
    },
    document_link: {
        type: Input,
        content: 'text',
    },
    count_educated: {
        type: Input,
        content: 'number',
    },
    count_educated_commercial: {
        type: Input,
        content: 'number',
    },
    speciality_custom: {
        type: Input,
        content: 'text',
    },
    partners: {
        type: Input,
        content: 'text',
    },
    supervisor: {
        type: Select,
        source: 'project',
        key: (v) => `${v?.title_short}`,
    },
    responsible_edu: {
        type: Input,
        content: 'text',
    },
    direction_by: {
        type: Select,
        source: 'users',
        key: (v) => `${v?.fio}`,
    },
    created_by: {
        type: Select,
        source: 'users',
        key: (v) => `${v?.fio}`,
    },
    updated_by: {
        type: Select,
        source: 'users',
        key: (v) => `${v?.fio}`,
    },
    created_at: {
        type: InputDate,
        content: 'date',
    },
    updated_at: {
        type: InputDate,
        content: 'date',
    },
}

export const REQUIRED_FIELDS = {
    [STATUS_NEW]: [
        'event_name',
        'speciality_custom',
        'project',
        'event_type',
        'start',
        'end',
        'partners',
        'supervisor',
        'responsible',
    ],
    [STATUS_APPROVE]: [
        'event_name',
        'speciality_custom',
        'project',
        'event_type',
        'start',
        'end',
        'partners',
        'supervisor',
        'responsible',
    ],
    [STATUS_ACCEPT]: [
        'event_name',
        'speciality_custom',
        'project',
        'event_type',
        'start',
        'end',
        'partners',
        'supervisor',
        'responsible',
    ],
}

export const CAN_MANAGE_ROLES = {
    [STATUS_NEW]: [ROLES.admin, ROLES.project_manager],
    [STATUS_APPROVE]: [ROLES.admin, ROLES.direction, ROLES.direction_oop],
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction],
}

const BLOCK_DATA = Yup.object({
    type: Yup.string().nullable(),
    change_date: Yup.boolean().nullable(),
    show_messages: Yup.boolean().nullable(),
    message: Yup.string().nullable(),
})

const Schema = Yup.object().shape({
    status: Yup.number(),
    message: Yup.string().when(['status', 'current_status'], (status, schema) => {
        return (status[0] < status[1]) ? schema.required('Обязательное поле!') : schema
    }),
    is_closed: Yup.boolean().typeError('Обязательное поле!').when(['status'], (status, schema) => {
        return (status[0] >= STATUS_ACCEPT)
            ? schema?.required('Обязательное поле!').oneOf([true], 'Обязательное поле!')
            : schema
    }),
    rpd_progress: Yup.number().nullable(),
    blocks: Yup.object({
        matrix_dev: BLOCK_DATA,
        reference: BLOCK_DATA,
        characteristic: BLOCK_DATA,
        curriculum: BLOCK_DATA,
        information_pedagogical: BLOCK_DATA,
        information_specialist: BLOCK_DATA,
        information_mto: BLOCK_DATA,
        information_leader: BLOCK_DATA,
        extract_protocol: BLOCK_DATA,
    }).nullable(),
    start: Yup.date()
        .typeError('Обязательное поле!')
        .required('Обязательное поле!')
        .when(['end'],
            (end, schema) => {
                return end[0]
                    ? schema.max(end[0], 'Дата начала не может быть больше даты окончания') : schema
            })
        .nullable()
        .default(undefined)
        .test('test-on-null', 'Обязательное поле!', (value, context) => {
            return value
        }),
    end: Yup.date()
        .typeError('Обязательное поле!')
        .required('Обязательное поле!')
        .nullable()
        .default(undefined)
        .test('test-on-null', 'Обязательное поле!', (value, context) => {
            return value
        }),
    event_name: Yup.string().nullable().required('Обязательное поле!'),
    speciality_custom: Yup.string().nullable().required('Обязательное поле!').typeError('Обязательное поле!'),
    responsible_edu: Yup.string().nullable().required('Обязательное поле!').typeError('Обязательное поле!'),
    supervisor_id: Yup.number().nullable().required('Обязательное поле!').typeError('Обязательное поле!'),
    event_type: Yup.number().nullable().required('Обязательное поле!').typeError('Обязательное поле!'),
    partners: Yup.string().nullable().required('Обязательное поле!'),
})

export const REQUIRED_FIELDS_TEMP = {
    [STATUS_NEW]: Schema,
    [STATUS_APPROVE]: Schema,
    [STATUS_ACCEPT]: Schema,
    [STATUS_TEMP]: Schema,
}

export const BLOCKS = [
    'matrix_dev',
    'reference',
    'characteristic',
    'curriculum',
    'information_pedagogical',
    'information_specialist',
    'information_mto',
    'information_leader',
    'extract_protocol',
]
