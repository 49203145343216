import { Form } from 'react-bootstrap'
import * as React from 'react'
import { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { useSearchParams } from 'react-router-dom'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import { SelectReact } from './select'


export const Input = ({
                          label,
                          id,
                          name,
                          value,
                          type = '',
                          step = '',
                          show_zero = true,
                          required = false,
                          required_on_transition = false,
                          invalid = false,
                          disabled = null,
                          mask = null,
                          small = '',
                          error = '',
                          errors,
                          setValid = false,
                          className = '',
                          handleChangeValue,
                          data_change_key,
                          data_foreign_id,
                          data_list_index,
                          onKeyPress,
                          onBlur,
                          formatChars,
                          max,
                          min,
                      }) => {
    const [searchParams, _] = useSearchParams()

    const unmaskValue = (value) => {
        let unmaskedValue = ''
        for (let i = 0; i < value.length; i++) {

            const isValueChar = formatChars
                ? Object.keys(formatChars).some((v) => mask[i])
                : ['9', 'a', '*'].some((v) => {
                    return v === mask[i]
                })
            const isMaskChar = value[i] === ''

            if ((isValueChar) && !isMaskChar)
                unmaskedValue += value[i]
        }
        return unmaskedValue
    }

    const handleDecimal = (e) => {
        if (type === 'number') {
            e.target.value = e.target.value.replace(',', '.')
                .replaceAll(',', '')
            let pointSplit = e.target.value.split('.')
            if (pointSplit.length >= 2 && pointSplit[1]) {
                e.target.value = pointSplit[0] + '.' + pointSplit[1].substring(0, 2)
            }
        }
    }

    let as = mask
        ? {
            'as': InputMask,
            maskChar: '',
            value: value,
        }
        : type === 'year' ? { type: 'number', max: 2100, min: 1900 }
            : (type === 'number' || type === 'int' || type === 'percent') ? {
                    onInput: (event) => {
                        const input = event.target
                        const currentValue = input.value

                        // Заменяем все нецифровые и не точечные символы на пустую строку
                        const sanitizedValue = currentValue.replace(/[^0-9.]/g, '')

                        // Проверяем, что после точки не более двух знаков
                        const dotIndex = sanitizedValue.indexOf('.')
                        if (dotIndex !== -1 && sanitizedValue.substring(dotIndex + 1).length > 2) {
                            // Если более двух знаков после точки, обрезаем до двух
                            input.value = sanitizedValue.substring(0, dotIndex + 3)
                        } else {
                            input.value = sanitizedValue
                        }
                        if (type === 'int' && dotIndex !== -1) {
                            input.value = sanitizedValue.substring(0, dotIndex)
                        } else if (type === 'percent' && Number(sanitizedValue) > 100) {
                            input.value = '100'
                        }
                    },
                }
                : null

    let format_chars = formatChars ? { formatChars: formatChars } : null

    as = value !== undefined && name ? { ...as, value: value } : { ...as, defaultValue: value }

    return <div
        className={`form-group ${required_on_transition ? 'required-on-transition' : ''} 
        ${required ? 'required' : ''} 
        ${className}`}>
        <Form.Label>{label}</Form.Label>
        <Form.Control id={id}
                      name={name ? name : id}
                      {...as}
                      onKeyDown={onKeyPress}
                      onBlur={onBlur}
                      mask={mask}
                      isInvalid={setValid ? false : Boolean(error)}
                      onChange={(e) => {
                          handleDecimal(e)
                          handleChangeValue(e)
                      }}
                      onBlurCapture={(e) => {
                          if (type === 'number') {
                              e.target.value = e.target.value.replace(',', '.')
                              e.target.value = e.target.value.replaceAll(',', '')
                              if (show_zero) {
                                  e.target.value = Number(e.target.value).toFixed(2)
                              }
                              handleChangeValue(e)
                          }
                          if (typeof e.target.value === 'string') {
                              e.target.value = e.target.value.trim()
                              if (mask)
                                  e.target.value = unmaskValue(e.target.value)
                              handleChangeValue(e)
                          }
                          if (type === 'year') {
                              if (Number(e.target.value) > max) {
                                  e.target.value = max
                              } else if (Number(e.target.value) < min) {
                                  e.target.value = min
                              }
                          }
                      }}
                      type={type === 'year' ? 'number' : type === 'number' ? null : type}
                      step={step}
                      disabled={disabled === null
                          ? searchParams.has('update') && searchParams.get('update') === 'false'
                          : disabled}
                      data_change_key={data_change_key ? data_change_key : 'common'}
                      data_foreign_id={data_foreign_id}
                      data_list_index={data_list_index}
                      max={max}
                      min={min}
                      {...format_chars}
        />
        <Form.Control.Feedback type='invalid'>
            {error}
        </Form.Control.Feedback>
        {small ? <small className='form-text text-muted'>{small}</small> : null}
    </div>
}

export const Check = ({
                          label,
                          id,
                          required = false,
                          value = null,
                          disabled = null,
                          small = '',
                          error,
                          className,
                          invalid,
                          handleChangeValue,
                          data_foreign_id,
                          data_list_index,
                          props,
                      }) => {
    const [searchParams, _] = useSearchParams()
    const [check, setCheck] = useState(!!value)

    useEffect(() => {
        setCheck(!!value)
    }, [value])

    const toggleCheckbox = (e) => {
        setCheck(e.target.checked)
        e.target.value = e.target.checked
        handleChangeValue(e)
    }

    return <div className={`form-group ${required ? 'required' : ''} ${className ? className :  ''}`}>
        <Form.Control id={id}
                      type={'checkbox'}
                      data_change_key={'common'}
                      isInvalid={invalid}
                      checked={!!check}
                      defaultValue={value ? 'on' : 'off'}
                      data_foreign_id={data_foreign_id}
                      data_list_index={data_list_index}
                      onChange={toggleCheckbox}
                      disabled={disabled === null
                          ? searchParams.has('update') && searchParams.get('update') === 'false'
                          : disabled}
                      {...props}
        />
        <Form.Label htmlFor={id}>{label}</Form.Label>
        {error ? <small className='form-text text-danger'>{error}</small> : null}
        <small className='form-text text-muted'>{small}</small>
    </div>
}

export const Area = ({
                         label,
                         id,
                         name,
                         value,
                         handleChangeValue,
                         required = false,
                         disabled = null,
                         error = '',
                         defaultValue = null,
                         invalid = false,
                         data_change_key = 'common',
                         small = '',
                     }) => {
    const [searchParams, _] = useSearchParams()

    return <div className={`form-group ${required ? 'required' : ''}`}>
        {label && <Form.Label>{label}</Form.Label>}
        <Form.Control id={id}
                      name={name ? name : id}
                      style={{ width: '100%', minHeight: '86px' }}
                      isInvalid={invalid}
                      as='textarea'
                      defaultValue={defaultValue}
                      onChange={handleChangeValue}
                      data_change_key={data_change_key ? data_change_key : 'common'}
                      disabled={disabled === null
                          ? searchParams.has('update') && searchParams.get('update') === 'false'
                          : disabled}
                      value={value}
        />
        {error ? <small className='form-text text-danger'>{error}</small> : null}
        <small className='form-text text-muted'>{small}</small>
    </div>
}

export const Select = ({
                           label,
                           id,
                           options,
                           menuPortalTarget,
                           style,
                           select_values,
                           setSelectValues,
                           placeholder = '',
                           required = false,
                           small = '',
                           error = '',
                           invalid = false,
                           disabled = null,
                           isSearchable = true,
                           isClearable = true,
                           isMulti = false,
                           closeMenuOnSelect = true,
                           selectAllOption = false,
                           onMenuScrollToBottom = null,
                           onMenuScrollToTop = null,
                       }) => {
    const [searchParams, _] = useSearchParams()

    return <div className={`form-group ${required ? 'required' : ''}`}>
        <Form.Label>{label}</Form.Label>
        <SelectReact options={options} setSelectState={true}
                     style={style}
                     selectAllOption={selectAllOption}
                     setState={setSelectValues}
                     menuPortalTarget={menuPortalTarget}
                     value={select_values.hasOwnProperty(id) ? select_values[id] : null}
                     selectID={id}
                     select_key={id}
                     select_values={select_values}
                     placeholder={placeholder}
                     isSearchable={isSearchable}
                     invalid={invalid ? true : false}
                     closeMenuOnSelect={closeMenuOnSelect}
                     multi={isMulti}
                     isLoading={!options}
                     isClearable={isClearable}
                     onMenuScrollToBottom={onMenuScrollToBottom}
                     onMenuScrollToTop={onMenuScrollToTop}
                     disabled={!options ? true : disabled === null
                         ? searchParams.has('update') && searchParams.get('update') === 'false'
                         : disabled}
        />
        {error ? <small className='form-text text-danger'>{error}</small> : null}
        {small ? <small className='form-text text-muted'>{small}</small> : null}
    </div>
}


export const InputDate = (
    {
        label,
        id,
        value,
        type = '',
        step = '',
        onKeyPress,
        required = false,
        required_on_transition = false,
        invalid = false,
        disabled = null,
        small = '',
        error = '',
        className = '',
        handleChangeValue,
        data_change_key = 'common',
        data_foreign_id,
        data_list_index,
        formatChars,
    },
) => {
    const [input_value, setValue] = useState(null)
    const [searchParams, _] = useSearchParams()

    useEffect(() => {
        if (value && !input_value) {
            let date = new Date(value)
            if (value.includes('.')) {
                date = new Date(value.split('.')[2], value.split('.')[1] - 1, value.split('.')[0])
            }
            if (isValidDate(date)) {
                setValue(new Date(date))
            }
        }
    }, [input_value, value])

    const isValidDate = (d) => {
        return d instanceof Date && !isNaN(d)
    }

    const toIsoString = (date) => {
        let tzo = -date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function (num) {
                return (num < 10 ? '0' : '') + num
            }

        return date.getFullYear()
            + '-' + pad(date.getMonth() + 1)
            + '-' + pad(date.getDate())
    }

    return <div
        className={`form-group ${required_on_transition ? 'required-on-transition' : ''} 
        ${required ? 'required' : ''} 
        ${className}`}>
        <Form.Label>{label}</Form.Label>
        <Form.Control id={id}
                      as={DatePicker}
                      isInvalid={invalid}
                      dateFormat='dd.MM.yyyy'
                      onChange={(date) => {
                          handleChangeValue(null, false, {
                              id: id,
                              target: data_change_key,
                              value: date ? toIsoString(date) : null,
                              sub_key: data_foreign_id,
                              list_index: data_list_index,
                          })
                          setValue(date)
                      }}
                      type={type}
                      step={step}
                      onKeyDown={onKeyPress}
                      data_change_key={data_change_key ? data_change_key : 'common'}
                      disabled={disabled === null
                          ? searchParams.has('update') && searchParams.get('update') === 'false'
                          : disabled}
                      selected={input_value}
                      formatChars={formatChars}
        />
        {error ? <small className='form-text text-danger'>{error}</small> : null}
        {small ? <small className='form-text text-muted'>{small}</small> : null}
    </div>
}
