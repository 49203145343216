import axiosInstance, { protectedAxios } from '../utils/axiosAPI'
import { toast } from 'react-toastify'
import { getRandomString } from '../utils/utils'

export const getRequest = (name, setFunc, params = {}, id = '', value = null, key = null, only_result = false, showToast = true, showPreloader = false) => {
    let body = {
        params: params,
    }

    let toastId = null
    if (showPreloader) {
        toastId = toast.loading('Данные загружаются')
    }

    return protectedAxios(axiosInstance.get, `/api/${name}/${id}`, body)
        .then((response) => {
            if (value && response) {
                if (!only_result)
                    setFunc({ ...value, [key]: response.data })
                else
                    setFunc({ ...value, [key]: response.data.results })
            } else if (!only_result && response) {
                setFunc(response.data)
            } else if (response) {
                setFunc(response.data.results[0])
            }

            if (toastId) toast.dismiss(toastId)
            
            return response
        })
        .catch((err) => {
            if (showToast) {
                let message = err?.response?.data?.detail
                if (showPreloader && toastId) {
                    toast.error('Произошла ошибка', { id: toastId })
                    toast.dismiss(toastId)
                }
                message ? toast.error(message) : null
            }

            return err
        })
}

export const postRequest = (name, setFunc, data, id = '', withToast = true, withPreloader = false) => {
    let toastId = null
    if (withPreloader) {
        toastId = toast.loading('Данные загружаются')
    }

    return protectedAxios(axiosInstance.post, `/api/${name}_request/${id}`, data)
        .then((response) => {
            if (response.status === 200 || response.status === 205) {
                if (withToast) toast.success('Данные успешно сохранены')  
                if (response?.data) {
                    setFunc(response.data)
                }
            }
            if (toastId) toast.dismiss(toastId)

            return response
        })
        .catch((err) => {
            if (withPreloader && toastId) {
                toast.error('Произошла ошибка', { id: toastId })
                toast.dismiss(toastId)
            }
            return err.response
        })
}

export const putRequest = async (name, data, show_message = false) => {
    return protectedAxios(axiosInstance.put, `/api/${name}_request/`, data)
        .then((response) => {
            if (response.status === 201 && show_message) {
                toast.success('Элемент успешно создан')
            }
            return response
        })
        .catch((err) => {
            return err.response
        })
}

export const updateRequest = (name, changes, id) => {
    return protectedAxios(axiosInstance.post, `/api/${name}_request/${id ? id : ''}`, changes)
        .then((response) => {
            if (response.status === 200) {
                toast.success('Данные успешно сохранены')
            }
            return response
        })
        .catch((err) => {
            return err.response
        })
}

export const deleteRequest = (name, id, showMessage = true) => {
    return protectedAxios(axiosInstance.delete, `/api/${name}_request/${id}`)
        .then((response) => {
            if (showMessage && response.status === 200) {
                toast.success('Элемент успешно удален')
            }
            return response
        })
        .catch((err) => {
            return err.response
        })
}

export const downloadRequest = (name, data) => {
    let file_name = data?.file_name
    let body = {
        params: data, headers: {
            'Content-Disposition': 'attachment;',
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }, responseType: 'blob',
    }

    protectedAxios(axiosInstance.get, `/api/${name}_request/`, body)
        .then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = downloadUrl
            link.setAttribute('download', !!file_name.split('/').at(-1) ? file_name : `${getRandomString(8)}.zip`)
            document.body.appendChild(link)
            link.click()
            link.remove()
        })
        .catch((err) => {
            console.log(err)
        })
}
