import * as React from 'react'
import { useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { AuthContext } from '../../auth'
import { useAbac } from 'react-abac'
import { deleteRequest, getRequest, postRequest } from '../../actions/common'
import { Badge, Button, ButtonGroup, Dropdown, Modal } from 'react-bootstrap'
import { IconText } from '../../components/icon_txt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Messages } from '../../utils/utils'
import { toast } from 'react-toastify'


export const DatabaseItem = (props) => {
    const [constant, setConstant] = useState(null)
    useEffect(() => {
        if (!constant) {
            if (props.title.includes('rid')) {
                import(`./rid/${props.title}/constant`).then((result) => {
                    setConstant(result)
                })
            } else {
                import(`./${props.title}/constant`).then((result) => {
                    setConstant(result)
                })
            }
        }
    }, [constant])

    const params = useParams()
    const itemId = params?.id || props?.itemId
    const [searchParams, setSearchParams] = useSearchParams()
    const disabledByUrl = searchParams.has('update') ? searchParams.get('update') !== 'true' : false

    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()
    const navigate = useNavigate()
    const [show_delete, setShowDelete] = useState(false)

    const [data, setData] = useState(null)
    const [messages, setMessages] = useState(null)
    const [without_message, setWithoutMessage] = useState(null)
    const [mainInfo, setMainInfo] = useState(true)


    const [show_buttons, setDisabledButtons] = useState(props.isView || disabledByUrl)
    const [disabled, setDisabled] = useState(props.isView || disabledByUrl)
    const [view, setView] = useState(null)
    const isDraft = useRef(null)


    useEffect(() => {
        if (!messages) {
            if (props.title == 'internships') {
                getRequest('internships_messages', setMessages, { type_data: 'all', ['internship']: itemId })
            }
            if (props.title == 'rid_internal') {
                getRequest('rid_internal_messages', setMessages, { type_data: 'all', ['rid']: itemId })
            } else {
                getRequest(`${props.title}_messages`, setMessages, { type_data: 'all', [props.title]: itemId })
            }
        }
    }, [messages])

    useEffect(() => {
        if (data && !view && user && constant) {
            setView(!user.roles.some((role) => constant.CAN_MANAGE_ROLES[data.status].includes(role)))
        } else if (view) {
            setDisabled(true)
        }
    }, [data, view])

    useEffect(() => {
        if (data) {
            isDraft.current = data['is_draft']
        }
    }, [data])

    useEffect(() => {
        if (!data) {
            getRequest(props.title, setData, {}, itemId, null, null, true)
        }
    }, [data])

    const generateOptions = (field, key, select_data, permission_control, value, getHoverContext) => {
        if (data && select_data && Object.keys(select_data).includes(field)) {
            let options = []
            for (let element of select_data[field]) {
                let label = null
                if (typeof key === 'string') {
                    label = element[key]
                } else {
                    label = key(element)
                }

                if (field === 'staff_category' && constant?.CHECK_CATEGORY.hasOwnProperty(element?.title_short) && !userHasPermissions(permission_control)) {
                    options.push({
                        value: element.id,
                        label: label,
                        hover: getHoverContext ? getHoverContext(element) : undefined,
                        isDisabled: constant?.CHECK_CATEGORY ? !constant?.CHECK_CATEGORY[element.title_short](data) : false,
                    })
                } else {
                    options.push({
                        value: value === 'index' ? element : element.id,
                        label: label,
                        hover: getHoverContext ? getHoverContext(element) : undefined,
                    })
                }
            }
            return options
        }
        return null
    }

    const getStatusClassName = (status, highlightCurrent = false) => {
        if (data) {
            if (status === data.status) {
                return 'bg-primary'
            } else if (status < data.status) {
                return highlightCurrent ? 'bg-secondary' : 'bg-success'
            } else if (status > data.status) {
                return 'bg-secondary'
            }
        }
        return null
    }

    const handleSaveAndMove = (move_index, form, errorMessage = '') => {
        if (errorMessage) toast.error(errorMessage)

        form.setFieldValue('status', data.status + move_index)
        form.setFieldValue('save_form', true)

        if (props.title == 'internships') {
            getRequest('internships_messages', setMessages, { type_data: 'all', ['internship']: itemId })
        } else {
            getRequest(`${props.title}_messages`, setMessages, { type_data: 'all', [props.title]: itemId })
        }
    }

    const handleDelete = (showMessage = true) => {
        deleteRequest(props.title, itemId, showMessage).then((response) => {
            if (response.status) {
                if (props.title.includes('rid')) {
                    navigate(`/rid/${props.title.replaceAll('_', '-')}`)
                } else {
                    navigate(`/${props.title}`)
                }
            }
        })
    }

    const handleReset = (e) => {
        getRequest(props.title, setData, {}, itemId, null, null, true)
    }

    const handleSubmit = (formik, saveFunction) => {
        if (data && formik.values?.save_form === true) {
            if (formik.values?.status < data.status) {
                if (formik.values?.message || formik.values?.without_message || without_message) {
                    const handleFunc = formik.values?.strict_validation ? formik.submitForm : saveFunction
                    handleFunc().then((result) => {
                        if (result !== 'is_saved') {
                            toast.error('Ошибка в заполнении данных!')
                        }
                    })
                } else {
                    formik.setFieldError('message', 'Обязательное поле!')
                    toast.error('Ошибка в заполнении данных!')
                }
            } else {
                let errors = { ...formik.errors }
                delete errors?.message
                formik.setErrors(errors)

                formik.submitForm().then((result) => {
                    if (result !== 'is_saved') {
                        toast.error('Ошибка в заполнении данных!')
                    }
                })
            }
        }
        if (formik.values?.save_form === true) {
            formik.setFieldValue('save_form', false)
            isDraft.current = false
        }
    }

    useEffect(() => () => {
        if (isDraft.current) {
            handleDelete(false)
        }
    }, [])

    const DisableButton = () => {
        if (!view && !disabled) {
            return <Link id='w0' className='btn btn-link' onClick={() => {
                setDisabledButtons(!show_buttons)
                setDisabled(!disabled)
            }} to={'#'}>
                <FontAwesomeIcon icon={'eye'}/>
            </Link>
        } else {
            return <StatusBadge/>
        }
    }

    const StatusBadge = () => {
        let variantBtn = getStatusColor(data?.status, constant?.STATUS_ACCEPT, constant?.STATUS_NEW)

        return <div>
            Статус: <Badge bg={variantBtn} className={'text-white'}>
            {constant?.STATUS_LABELS[data?.status]}
        </Badge>
        </div>
    }

    const EditButton = () => {
        if (!view && disabled) {
            return <Button variant={'outline-secondary'} id='w0' onClick={() => {
                if (props.isView && props.urlUpdate) {
                    navigate(`${props.urlUpdate}/${itemId}/?update=true`)
                }
                setDisabledButtons(!show_buttons)
                setDisabled(!disabled)
            }}>
                <IconText icon={'pencil-alt'} text={'Редактировать'}/>
            </Button>
        } else {
            return null
        }
    }

    const ToggleButtons = () => {
        return <ButtonGroup>
            <Button variant={mainInfo ? 'secondary' : 'outline-secondary'} onClick={() => setMainInfo(true)}>
                Основная информация
            </Button>
            <Button variant={!mainInfo ? 'secondary' : 'outline-secondary'} onClick={() => setMainInfo(false)}>
                Прикрепленные файлы
            </Button>
        </ButtonGroup>
    }

    const ActionButtons = ({
                               form,
                               permission_control,
                               permission_manage,
                               additional_buttons = [],
                               can_delete = false,
                               can_back = false,
                               can_next = true,
                               back_button = true,
                               move_index_plus = 1,
                               errorMessages = null,
                           }) => {
        let delete_btn = null
        const IS_TO_ACCEPT = (data?.status + move_index_plus) === constant?.STATUS_ACCEPT
        const IS_INTERMEDIATE_STATUS = (data?.status < constant?.STATUS_ACCEPT) && (data?.status > constant?.STATUS_NEW)
        let errorMessage = ''
        if (errorMessages && 'undo' in errorMessages) {
            errorMessage = errorMessages['undo']
        }
        let variantBtn = 'warning'
        if (data?.status === constant?.STATUS_ACCEPT) {
            variantBtn = 'success'
        } else if (data?.status > constant?.STATUS_NEW) {
            variantBtn = 'info'
        }

        const handleNextClick = () => {
            if (move_index_plus === -1) setWithoutMessage(true)
            handleSaveAndMove(move_index_plus, form)
        }

        const handleRedoClick = (errorMessage) => {
            form.setFieldValue('is_back', true)
            handleSaveAndMove(-1, form, errorMessage)
        }

        let to_return = []

        if ((data && constant) && (!show_buttons && !view)) {
            let to_return = [<Button key={'reset'} variant={'outline-secondary'} onClick={() => {
                form.setFieldValue('reset_form', true)
                handleReset()
            }}>
                <IconText icon={'ban'} text={'Сбросить'}/>
            </Button>, <Button key={'save'} variant='primary' onClick={() => {
                form.setFieldValue('status', data.status)
                form.setFieldValue('save_form', true)
            }}>
                <IconText icon={'save'} text={'Сохранить'}/>
            </Button>]
            if (data.status <= constant?.STATUS_NEW || userHasPermissions(permission_control) || constant?.STATUS_ADDITIONAL_INFO) {
                delete_btn = (<Button key={'delete'} variant={'outline-danger'} onClick={() => {
                    setShowDelete(true)
                }}>
                    <IconText text={'Удалить'} icon={'trash-alt'}/>
                </Button>)
            }
            return <React.Fragment>
                <Modal size={'lg'} show={show_delete} centered={true} onHide={() => setShowDelete(false)}>
                    <div className={'modal-content'}>
                        <Modal.Header style={{ backgroundColor: '#bd2130', color: 'white' }}>
                            <Modal.Title>
                                Вы действительно хотите удалить данную запись?
                            </Modal.Title>
                            <button className={'close'} onClick={() => {
                                setShowDelete(false)
                            }}><FontAwesomeIcon icon={'times'}/></button>
                        </Modal.Header>
                        <Modal.Body>
                            <div className={'button-actions'}>
                                <Button key={'delete'} variant={'danger'} onClick={handleDelete}>
                                    <FontAwesomeIcon icon={'trash-alt'}/> Удалить
                                </Button>
                                <Button variant={'secondary'} onClick={() => setShowDelete(false)}>
                                    Отменить
                                </Button>
                            </div>
                        </Modal.Body>
                    </div>
                </Modal>
                <div className={'d-flex justify-content-between align-items-center w-100'}>
                    {(data && constant) && (!show_buttons && !view)
                        ? <RecordMoveButton
                            data={data}
                            variantBtn={variantBtn}
                            labels={constant?.STATUS_LABELS}
                            statusAccept={constant?.STATUS_ACCEPT}
                            canNext={can_next}
                            canBack={can_back}
                            backButton={back_button}
                            isAccept={IS_TO_ACCEPT}
                            handleNextClick={handleNextClick}
                            handleRedoClick={handleRedoClick}
                            permissionManage={permission_manage}
                            IsIntermediateStatus={IS_INTERMEDIATE_STATUS}
                            errorMessage={errorMessage}
                        />
                        : null}
                    <div className={'button-actions'}>
                        {[...to_return, ...additional_buttons, delete_btn]}
                    </div>
                </div>
            </React.Fragment>
        }
    }

    const MessageHistory = (props) => {
        return <Messages messages_={messages} status_labels={constant?.STATUS_LABELS} source={props.title}
                         type={props.type}/>
    }

    const ErrorsBlock = ({ form }) => {

        const generateErrorDict = (err) => {
            let temp = []
            if (err) for (const [key, value] of Object.entries(err)) {
                if (value !== '' && value !== ' ' && value) {
                    if (value instanceof Array) {
                        temp = temp.concat(generateErrorList(value))
                    } else if (['status', 'is_upload'].includes(key)) {
                        if (err[key]) temp.push(<li key={key.replace('_id', '')}>
                            {`${err[key]}`}
                        </li>)
                    } else if (key !== 'message') {
                        let label = null
                        if (constant?.ERROR_LABELS && constant?.ERROR_LABELS[key.replace('_id', '')]) {
                            label = constant?.ERROR_LABELS[key.replace('_id', '')]
                        } else if (constant?.LABELS[key.replace('_id', '')]) {
                            label = constant?.LABELS[key.replace('_id', '')]
                        }
                        if (label) temp.push(<li key={key.replace('_id', '')}>
                            {`Необходимо заполнить: "${label}"`}
                        </li>)
                    }
                }
            }
            return temp
        }

        const generateErrorList = (err) => {
            let temp = []
            for (const value of err) {
                temp = temp.concat(generateErrorDict(value))
            }
            return temp
        }

        if (form.errors) {
            let temp = generateErrorDict(form.errors)
            return <div className='alert alert-danger' style={{ display: temp.length > 0 ? 'block' : 'none' }}>
                <p>Исправьте следующие ошибки:</p>
                <ul>
                    {generateErrorDict(form.errors)}
                </ul>
            </div>
        } else return null
    }


    const HeaderStatus = ({ can_next, move_index_plus = 1, form }) => {
        return null
    }

    const MainBlock = ({ children }) => {
        if (mainInfo) {
            return children
        }
    }

    const FileBlock = ({ children }) => {
        if (!mainInfo) {
            return children
        }
    }

    return React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                itemId,
                data,
                setData,
                user,
                disabled,
                messages,
                MainBlock,
                FileBlock,
                getStatusClassName,
                handleSubmit,
                handleSaveAndMove,
                generateOptions,
                ErrorsBlock,
                MessageHistory,
                ActionButtons,
                DisableButton,
                HeaderStatus,
                ToggleButtons,
                EditButton,
                setMessages,
                setDisabled,
                setView,
            })
        }
        return child
    })
}

export const RecordMoveButton = ({
                                     data,
                                     variantBtn,
                                     labels,
                                     statusAccept,
                                     canNext,
                                     canBack,
                                     backButton,
                                     isAccept,
                                     handleNextClick,
                                     handleRedoClick,
                                     permissionManage,
                                     IsIntermediateStatus,
                                     errorMessage,
                                 }) => {
    const { userHasPermissions } = useAbac()

    return <Dropdown className={'mt-1'}>
        <Dropdown.Toggle variant={variantBtn}>
            <IconText icon={'save'}
                      text={labels[data?.status]}/>
            <span className={'mr-2'}/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Header>
                Действия
            </Dropdown.Header>
            {data?.status < statusAccept && canNext
                ? <Dropdown.Item
                    onClick={() => handleNextClick()}
                >
                    {isAccept ? 'Принять' : 'Отправить далее'}
                </Dropdown.Item>
                : null}
            {backButton
                ? userHasPermissions(permissionManage) && IsIntermediateStatus || canBack
                    ? <Dropdown.Item
                        onClick={() => handleRedoClick(errorMessage)}
                    >
                        На доработку
                    </Dropdown.Item>
                    : null
                : null
            }
        </Dropdown.Menu>
    </Dropdown>
}


export const MoveButton = ({
                               status,
                               variantBtn,
                               labels,
                               canNext,
                               canBack,
                               isAccept,
                               handleMoveClick,
                               permissionManage,
                           }) => {
    const { userHasPermissions } = useAbac()

    return <Dropdown className={'mt-1'}>
        <Dropdown.Toggle variant={variantBtn}>
            <IconText icon={'save'}
                      text={labels[status]}/>
            <span className={'mr-2'}/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Header>
                Действия
            </Dropdown.Header>
            {canNext
                ? <Dropdown.Item
                    onClick={() => handleMoveClick(1)}
                >
                    {isAccept ? 'Принять' : 'Отправить далее'}
                </Dropdown.Item>
                : null}
            {userHasPermissions(permissionManage) && canBack
                ? <Dropdown.Item
                    onClick={() => handleMoveClick(-1)}
                >
                    На доработку
                </Dropdown.Item>
                : null
            }
        </Dropdown.Menu>
    </Dropdown>
}


export const getStatusColor = (status, statusAccept, statusNew) => {
    if (status === statusAccept) {
        return 'success'
    } else if (status > statusNew) {
        return 'info'
    }
    return 'warning'
}




export const getSelectedColor = (el, selected) => {
    if (selected.indexOf(el?.id) !== -1) {
        return '#B8DAFF'
    }
}

export const onSelectRow = (event, element, data, setSelected, selected) => {
    if (!event || !element) {
        setSelected([])
        return
    }

    if (element !== 'all') {
        if (!event.target?.checked) {
            setSelected(selected.filter((el) => el !== element))
        } else {
            setSelected([...selected, element])
        }
    } else {
        if (!event.target?.checked) {
            setSelected([])
        } else {
            setSelected(data['results']?.map((el) => el.id))
        }
    }
}

export const handleMoveClick = (moveIndex, name, selected, setSelected, status, setFiltering) => {
    postRequest(`${name}_move_records`, null, {
        ids: selected,
        status: status + moveIndex,
    }).then((r) => {
        setFiltering({})
        setSelected([])
    })
}
