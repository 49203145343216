import * as React from 'react'
import { useContext, useState } from 'react'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import ReactFilterTable from '../../../components/tables/react_table_filters/react_table_filters'
import { useNavigate } from 'react-router-dom'
import { PERMISSIONS } from '../../../rbac/constant'
import { Button } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { AuthContext } from '../../../auth'
import {
    FIELDS,
    LABELS,
    SCIENCE_DIRECTIONS,
    STATUS_ACCEPT,
    STATUS_BADGE_CLASS,
    STATUS_LABELS,
    STATUS_NEW,
} from './constant'
import { getLabelFromOptions, Messages } from '../../../utils/utils'
import { putRequest } from '../../../actions/common'
import { useAbac } from 'react-abac'
import { getSelectedColor, getStatusColor, handleMoveClick, MoveButton, onSelectRow } from '../common'
import { NiokrPreview } from '../preview'


const Niokr = () => {
    const navigate = useNavigate()
    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()
    const [viewItem, setViewItem] = useState(null)

    const [selected, setSelected] = useState([])
    const [status, setStatus] = useState(null)
    const [niokr, setNiokr] = useState(null)
    const [filtering, setFiltering] = useState(null)
    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['science_dir', 'title', 'supervisor', 'partner', 'project'],
        'Скрытые / Фиксированные колонки': [
            'updated_by',
            'created_at',
            'updated_at',
            'created_by',
            'status',
            'presentation_link',
            'grnti_program',
            'id',
        ],
    })
    const templateFileTags = JSON.stringify({ 'document_type': 'template_import', 'DB': 'niokr' })

    const checkField = (field, key) => {
        if (key === 'status') {
            return (
                <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>
                    {STATUS_LABELS[field]}
                </span>
            )
        }
        if (key === 'messages') {
            return <Messages messages_={field} status_labels={STATUS_LABELS} source={'niokr'}/>
        }
        if (key === 'science_dir') {
            return getLabelFromOptions(SCIENCE_DIRECTIONS, field)
        }
        if (key === 'title') {
            if (field) {
                return field
            } else {
                return <span className={'not-set'}>(не задано)</span>
            }
        }
        if (key === 'project' && field) {
            return <span>{field.title_short}</span>
        }

        return null
    }

    const addNewNiokr = (state) => {
        putRequest('niokr', {
            status: state?.monitoring ? STATUS_ACCEPT : STATUS_NEW,
            project: user?.project?.id,
            is_draft: true,
        }).then((response) => {
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/niokr/item/${response.data.id}/?update=true`)
            }
        })
    }

    return (<React.Fragment>
        <ReactBreadcrumb/>
        <div className={'box'}>
            <h1 className={'page-header'}>
                НИОКР
            </h1>
            <div className={'button-actions'}>
                {userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                    ? (
                        <Button variant={'primary'} onClick={() => {
                            addNewNiokr()
                        }}>
                            <IconText icon={'plus'} text={'Создать'}/>
                        </Button>
                    )
                    : null}
                {selected.length
                    ? <MoveButton
                        status={status}
                        variantBtn={getStatusColor(status, STATUS_ACCEPT, STATUS_NEW)}
                        labels={STATUS_LABELS}
                        canNext={status !== STATUS_ACCEPT}
                        canBack={status > STATUS_NEW}
                        isAccept={(status + 1) === STATUS_ACCEPT}
                        handleMoveClick={(moveIndex) => handleMoveClick(moveIndex, 'niokr', selected, setSelected, status, setFiltering)}
                        permissionManage={PERMISSIONS.MANAGE_FINANCING}
                    />
                    : null}
            </div>
        </div>
        {viewItem
            ? <NiokrPreview
                itemId={viewItem}
                onClose={() => setViewItem(null)}
                urlUpdate={'/niokr/item'}
            />
            : null}
        <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                          filtering={filtering}
                          data={niokr} setFunc={setNiokr} page={1} url={'/niokr'}
                          url_update={'/niokr/item'}
                          get_title={'niokr'}
                          checkField={checkField}
                          permission={PERMISSIONS.MANAGE_NIOKR}
                          fields={FIELDS}
                          urlImport={'/api/niokr_import_request/'}
                          urlExport={'niokr_table_export'}
                          templateFile={'import_niokr_template.xlsx'}
                          templateFileTags={templateFileTags}
                          canSelect
                          onRowClick={(el) => setViewItem(el?.id)}
                          onSelect={(ev, el) => onSelectRow(ev, el, niokr, setSelected, selected)}
                          colorExpression={(el) => getSelectedColor(el, selected)}
                          selectedAll={selected?.length && (niokr['results']?.length === selected?.length)}
                          selected={selected}
                          setStatus={setStatus}
        />
    </React.Fragment>)
}

export default Niokr
