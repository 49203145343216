import { Check, Input } from '../../form/forms'
import { PERMISSIONS } from '../../../rbac/constant'
import { STATUS_NEW } from '../../../screens/database/financing/constant'
import * as React from 'react'
import { useAbac } from 'react-abac'
import { Button, Col, Row } from 'react-bootstrap'
import { getUuid } from '../../../utils/utils'
import { Field } from 'formik'
import { FormikDate } from '../../form/formik'

export const PaymentProcedure = ({ formik, paymentProcedure, status, disabled }) => {
    const { userHasPermissions } = useAbac()


    const handleClickSinglePayment = (event) => {
        const hasResults = !!paymentProcedure?.results?.length
        const newValue = { results: [], single_payment: event.target.checked }
        if (event.target.checked) {
            newValue.results = hasResults ? [paymentProcedure.results[0]] : [{
                id: getUuid(),
                date: null,
                sum: null,
                year: null,
            }]
        } else {
            newValue.results = hasResults ? [...paymentProcedure.results] : []
        }
        formik.setFieldValue('payment_procedure', newValue)
    }

    const handleAddPayment = () => {
        const newValue = paymentProcedure?.results?.length ? [...paymentProcedure?.results] : []
        formik.setFieldValue('payment_procedure.results', [...newValue, {
            id: getUuid(), date: null, sum: null, year: null,
        }])
    }

    const handleRemovePayment = () => {
        const newValue = paymentProcedure?.results?.length ? [...paymentProcedure?.results] : []
        newValue.pop()
        formik.setFieldValue('payment_procedure.results', newValue)
    }

    return (<>
        <legend>Порядок оплаты</legend>
        <Check label={'Единый платеж'}
               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && status > STATUS_NEW || disabled}
               value={!!paymentProcedure?.single_payment}
               handleChangeValue={handleClickSinglePayment}
        />

        {!(!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && status > STATUS_NEW) && !paymentProcedure?.single_payment && !disabled ? <>
            <div className={'mb-4'}>
                <Button variant={'success'} onClick={handleAddPayment}>
                    Добавить платеж
                </Button>
                <Button variant={'danger'} onClick={handleRemovePayment} className={'ml-2'}>
                    Удалить платеж
                </Button>
            </div>
        </> : null}
        {paymentProcedure?.results?.map((item, index) => <div key={item?.id}>
            <p>Платеж {index + 1}</p>
            <Row>
                <Col>
                    <Field component={FormikDate}
                           name={`payment_procedure.results.${index}.date`}
                           handleChangeValue={formik.handleChange}
                           label={'Дата платежа'}
                           id={`payment_procedure.results.${index}.date`}
                           disabled={disabled}
                           value={item?.date}
                    />
                </Col>
                <Col>
                    <Input handleChangeValue={formik.handleChange}
                           label={'Сумма платежа'}
                           type={'number'}
                           step={'any'}
                           min={0}
                           id={`payment_procedure.results.${index}.sum`}
                           name={`payment_procedure.results.${index}.sum`}
                           disabled={disabled}
                           value={item?.sum}
                    />
                </Col>
                <Col>
                    <Input handleChangeValue={formik.handleChange}
                           label={'Год учета платежа'}
                           type={'int'}
                           min={2022}
                           max={2033}
                           id={`payment_procedure.results.${index}.year`}
                           name={`payment_procedure.results.${index}.year`}
                           disabled={disabled}
                           value={item?.year}
                    />
                </Col>
            </Row>
        </div>)}
        <hr/>
    </>)
}
