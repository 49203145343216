import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { getRequest, putRequest, updateRequest } from '../../../actions/common'
import { Area, Input } from '../../../components/form/forms'
import { PERMISSIONS } from '../../../rbac/constant'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { YearResults } from '../../../components/databases/year_results'
import { AcceptMonths } from '../../../components/databases/niokr/accept_month'
import { ExpectedResultYear } from '../../../components/databases/niokr/expected_result_year'
import { AdditionalInfoText } from '../../../components/databases/additional_info'
import { YearResultsReportFiles } from '../../../components/databases/niokr/report_files'

import {
    CAN_MANAGE_ROLES_COMMON,
    CAN_MANAGE_ROLES_RESULTS,
    EMPTY_EXPECTED_RESULT_YEAR,
    REQUIRED_FIELDS,
    REQUIRED_FIELDS_TEMP,
    SCIENCE_DIRECTIONS,
    STATUS_ACCEPT,
    STATUS_PROCESS,
} from './constant'
import { btnMessage } from '../../../utils/utils'
import { Field, FormikProvider, useFormik } from 'formik'
import { FormikSelect } from '../../../components/form/formik'
import { toast } from 'react-toastify'
import { useAbac } from 'react-abac'

export const NiokrItem = ({
                              data,
                              setData,
                              user,
                              disabled,
                              messages,
                              handleChangeValue,
                              generateOptions,
                              ErrorsBlock,
                              MessageHistory,
                              ActionButtons,
                              DisableButton,
                              handleSubmit,
                              HeaderStatus,
                              MainBlock,
                              FileBlock,
                              ToggleButtons,
                              EditButton,
                          }) => {
    const params = useParams()
    const navigate = useNavigate()
    const project_ref = useRef()
    const { userHasPermissions } = useAbac()

    const [select_data, setSelectData] = useState({})

    const select_fields = ['person', 'project']
    const given_select_fields = ['science_dir']

    const [input_fields, setInputFields] = useState({
        'status': 1,
        'type': '',
        'title': '',
        'partner': '',
        'grnti_program': '',
        'project_id': null,
        'save_form': false,
        'expected_result': '',
        'expected_result_year': [EMPTY_EXPECTED_RESULT_YEAR],
        'supervisor': '',
        'presentation_link': '',
    })

    const formikHelper = useFormik({
        initialValues: {},
    })
    const [start, setStart] = useState(true)
    const [status, setStatus] = useState(1)
    const [move_index_plus, setMoveIndexPlus] = useState(1)
    const [show_modal, setShowModal] = useState(false)
    const [show_alert, setShowAlert] = useState(false)

    const [delete_year, setDeleteYear] = useState(null)
    const FILE_PATH = `root/storage/niokr/niokr_${params.id}`
    const [updateTags, setUpdateTags] = useState(null)

    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: (values) => {
            return saveNiokr()
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })
    const CAN_MANAGE_YEAR = userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
        || userHasPermissions(PERMISSIONS.ADMIN_PANEL)

    useEffect(() => {
        handleSubmit(formik, saveNiokr)
    }, [formik.values])

    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${data?.id} в БД НИОКР | ИС «ПИШ»`
            let set_selected = {}
            select_fields.forEach((field) => {
                set_selected[`${field}_id`] = data[field]?.id
            })
            given_select_fields.forEach((field) => {
                set_selected[field] = data[field]
            })

            let set_inputs = {}
            for (let field in input_fields) {
                set_inputs[field] = data[field]
            }
            if (!set_inputs?.expected_result_year) {
                set_inputs['expected_result_year'] = [EMPTY_EXPECTED_RESULT_YEAR]
            }
            if (!set_inputs?.expected_result_year?.find((item) => item?.year === new Date().getFullYear())) {
                set_inputs['expected_result_year'] = set_inputs['expected_result_year'].concat([EMPTY_EXPECTED_RESULT_YEAR])
            }
            if (set_inputs?.expected_result_year) {
                let temp = {}
                set_inputs.expected_result_year.forEach((item) => {
                    temp[item?.year] = {
                        visible: false,
                    }
                })
                formikHelper.setValues({ ...temp })
            }

            setStatus(data?.status)
            formik.setValues({
                ...set_inputs, ...set_selected,
                current_status: data.status,
                without_message: true,
                strict_validation: true,
            })
            setStart(false)
        }
    }, [data])

    useEffect(() => {
        if (data) {
            let is_manager = user?.roles.every((value) => value === 'project_manager')  // can't view all projects
            if (Object.keys(select_data).length < select_fields.length || !select_data?.event?.length) {
                for (let field of ['project', 'person_select']) {
                    if (!Object.keys(select_data).includes(field)) {
                        if (field === 'project' && is_manager) {
                            getRequest('project', setSelectData, {
                                type_data: 'user_projects',
                                'user_id': user.id,
                            }, '', select_data, field)
                        } else {
                            getRequest(field, setSelectData, { type_data: 'all' }, '', select_data, field)
                        }
                        break
                    }
                }
            }
        }
    }, [data, select_data, formik.values])

    useEffect(() => {
        if (status === STATUS_PROCESS) {
            setMoveIndexPlus(-1)
        } else {
            setMoveIndexPlus(1)
        }
    }, [formik.values])

    useEffect(() => {
        if (formik.errors.expected_result_year) {
            setShowAlert(true)
        }
    }, [formik.errors])

    useEffect(() => {
        setUpdateTags({ 'project': formik.values['project_id'] })
    }, [formik.values['project_id']])

    useEffect(() => {
        if (delete_year) {
            let years = formik.values.expected_result_year
            let i
            for (i = 0; i < years.length; i++) {
                if (years[i].year === delete_year) {
                    break
                }
            }
            setDeleteYear(null)
            years.splice(i, 1)
        }
    }, [formik.values, delete_year])

    const saveNiokr = () => {
        formik.setFieldValue('save_form', false)

        let payload = null
        if (STATUS_ACCEPT === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values }
        } else if (STATUS_PROCESS === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values, ...data?.acceptable }
        } else {
            payload = formik.values
        }

        if (updateTags) {
            const fileData = { path: FILE_PATH, tags: updateTags }
            putRequest('directory', fileData, false)
        }

        updateRequest('niokr', { 'common': payload }, params.id).then((response) => {
            if (response.status === 200) {
                if (payload?.status !== data?.status)
                    navigate('/niokr')
                setData(response.data)
            } else if (response.status === 400) {
                if (response.data?.message)
                    toast.error(response.data?.message)
                if (response.data?.fields)
                    formik.setErrors({ ...formik.errors, ...response.data?.fields })
            }
        })
        if (formik?.values?.message) {
            putRequest('niokr_messages', {
                content: formik?.values?.message,
                niokr_status: data.status,
                status: data.status,
                created_by: user.id,
                niokr: data.id,
            }, false).then()
            document.getElementById('message').value = null
            formik.setFieldValue('message', '')
        }
        return Promise.resolve('is_saved')
    }

    const setDisabledRes = (isAccepted) => {
        if (disabled) return true
        
        const canManage = user.roles.some((role) => CAN_MANAGE_ROLES_RESULTS[status].includes(role))
        const canManageAccepted = (data?.status === STATUS_ACCEPT && (user.roles.some((role) => CAN_MANAGE_ROLES_RESULTS[status].includes(role))))
        
        if (canManageAccepted) return false
        if (!canManage) return true

        return isAccepted
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <FormikProvider value={formik}>

                <Form>
                    <div className='box'>
                        <div className={`d-flex align-items-center ${disabled ? 'justify-content-between' : ''}`}>
                            <h1 className='page-header d-flex'>
                                {`№${data?.id} в БД НИОКР`}
                            </h1>
                            <DisableButton/>
                        </div>
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <Button variant={'outline-primary'} className={'flex-shrink-0 mt-1 mr-2'} size={'md'}
                                    onClick={btnMessage}>
                                <IconText icon={'comment'}
                                          text={disabled ? 'Сообщения' : ''}/>
                            </Button>
                            <EditButton/>
                            <ActionButtons form={formik}
                                           permission_control={PERMISSIONS.CONTROL_NIOKR}
                                           permission_manage={PERMISSIONS.MANAGE_NIOKR}
                                           move_index_plus={move_index_plus}
                                           can_back={false}
                                           back_button={false}
                            />
                        </div>
                    </div>
                    <Card className={'bg-light mt-4 mb-4'}
                          style={messages && messages?.length !== 0 || formik.errors?.message ? { display: 'block' } : { display: 'none' }}
                          id={'card-message'}>
                        <Card.Body>
                            <div className='alert alert-danger'
                                 style={{ display: formik.errors?.message ? 'block' : 'none' }}>
                                <p>Исправьте следующие ошибки:</p>
                                <ul>
                                    <li>Необходимо заполнить: "Комментарий"</li>
                                </ul>
                            </div>
                            <label>История сообщений</label>
                            <MessageHistory/>
                            <div id='field-mention' className='content-group navbar-nav px-0'>
                                <div id='field-comment'>
                                    <label htmlFor='niokr_message'>Комментарий</label>
                                    <Area id='message'
                                          name={'message'}
                                          disabled={disabled}
                                          rows='5'
                                          value={formik?.values?.message}
                                          error={formik.errors['message']}
                                          invalid={formik.errors['message']}
                                          handleChangeValue={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <AdditionalInfoText/>
                    <Card className={'bg-light mb-3 mt-3'}>
                        <Card.Body>
                            <ToggleButtons/>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <Modal size={'md'} show={show_alert} centered={true} onHide={() => setShowAlert(false)}>
                                <div className={'modal-content'}>
                                    <Modal.Header>
                                        <Modal.Title>
                                            ВНИМАНИЕ
                                        </Modal.Title>
                                        <button className={'close'} onClick={() => {
                                            setShowModal(false)
                                        }}><FontAwesomeIcon icon={'times'}/>
                                        </button>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className={'text-center'}>
                                            <h5>Вы уверены, что ни одно поле не согласовано?</h5>
                                        </div>
                                        <div className={'text-center'}>
                                            Если вы хотите согласовать какие-то поля, то выберите, пожалуйста, эти поля,
                                            то есть поставьте галочку под полем
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <div className={'button-actions'}>
                                            <Button variant={'success'}
                                                    onClick={() => {
                                                        saveNiokr().then()
                                                        setShowAlert(false)
                                                    }}>
                                                Ни одно поле не согласовано
                                            </Button>
                                            <Button variant={'warning'} onClick={() => {
                                                setShowAlert(false)
                                            }}>
                                                Отменить
                                            </Button>
                                        </div>
                                    </Modal.Footer>
                                </div>
                            </Modal>
                            <MainBlock>
                                <fieldset>
                                    <Field component={FormikSelect}
                                           name={'science_dir'}
                                           label={'Научное направление'}
                                           id={'science_dir'}
                                           isSearchable={true}
                                           options={SCIENCE_DIRECTIONS}
                                           error={formik.errors['science_dir']}
                                           invalid={formik.errors['science_dir']}
                                           disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                           required={data ? REQUIRED_FIELDS[data.status].includes('science_dir') : false}
                                    />
                                    <Row>
                                        <Col md={6}>
                                            <Area label={'Тема НИОКР'}
                                                  id={'title'}
                                                  name={'title'}
                                                  required={REQUIRED_FIELDS?.[data?.status]?.includes('title')}
                                                  handleChangeValue={formik.handleChange}
                                                  isClearable={true}
                                                  value={formik.values.title}
                                                  step={'any'}
                                                  disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                                  error={formik.errors['title']}
                                                  invalid={formik.errors['title']}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Area label={'Индустриальный(ые) партнер(ы)'}
                                                  id={'partner'}
                                                  name={'partner'}
                                                  required={REQUIRED_FIELDS?.[data?.status]?.includes('partner')}
                                                  handleChangeValue={formik.handleChange}
                                                  isClearable={true}
                                                  value={formik.values.partner}
                                                  step={'any'}
                                                  disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                                  error={formik.errors['partner']}
                                                  invalid={formik.errors['partner']}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Field component={FormikSelect}
                                                   name={'project_id'}
                                                   label={'Проект'}
                                                   id={'project_id'}
                                                   isSearchable={true}
                                                   options={generateOptions('project', 'title_short', select_data)}
                                                   error={formik.errors['project']}
                                                   invalid={formik.errors['project']}
                                                   disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                                   required={data ? REQUIRED_FIELDS[data.status].includes('project') : false}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input label={'Руководитель / Отв. исп. НИОКР'} id={'supervisor'}
                                                   name={'supervisor'}
                                                   required={REQUIRED_FIELDS?.[data?.status]?.includes('supervisor')}
                                                   handleChangeValue={formik.handleChange}
                                                   isClearable={true}
                                                   value={formik.values.supervisor}
                                                   disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                                   error={formik.errors['supervisor']}
                                                   invalid={formik.errors['supervisor']}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Input label={'ГРНТИ (Программа)'} id={'grnti_program'}
                                                   name={'grnti_program'}
                                                   required={REQUIRED_FIELDS?.[data?.status]?.includes('grnti_program')}
                                                   handleChangeValue={formik.handleChange}
                                                   isClearable={true}
                                                   value={formik.values.grnti_program}
                                                   disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                                   error={formik.errors['grnti_program']}
                                                   invalid={formik.errors['grnti_program']}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input label={'Ссылка на презентацию'} id={'presentation_link'}
                                                   name={'presentation_link'}
                                                   required={REQUIRED_FIELDS?.[data?.status]?.includes('presentation_link')}
                                                   handleChangeValue={formik.handleChange}
                                                   isClearable={true}
                                                   value={formik.values.presentation_link}
                                                   disabled={disabled}
                                                   error={formik.errors['presentation_link']}
                                                   invalid={formik.errors['presentation_link']}
                                            />
                                        </Col>
                                    </Row>
                                </fieldset>
                                <hr/>
                                <fieldset>
                                    <legend>План выполнения</legend>
                                    <Area
                                        label={'Ожидаемый результат проекта'}
                                        id={'expected_result'}
                                        name={'expected_result'}
                                        handleChangeValue={formik.handleChange}
                                        value={formik.values.expected_result}
                                        step={'any'}
                                        disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                        required={REQUIRED_FIELDS?.[data?.status]?.includes('expected_result')}
                                        error={formik.errors['expected_result']}
                                        invalid={formik.errors['expected_result']}
                                    />
                                </fieldset>
                                <hr/>
                              
                                <fieldset>
                                    <YearResults disabled={disabled} 
                                                 formik={formik}
                                                 yearResults={formik.values.expected_result_year}
                                                 field={'expected_result_year'}
                                                 emptyYearResult={EMPTY_EXPECTED_RESULT_YEAR}
                                                 data={data}
                                                 user={user}
                                                 id={params.id}
                                                 showMonthHeader={true}
                                                 AddToMonth={AcceptMonths}
                                                 AddToYear={YearResultsReportFiles}
                                                 TotalYearResults={ExpectedResultYear}
                                                 setDisabledRes={setDisabledRes}
                                    />
                                    <hr/>
                                </fieldset>
                            </MainBlock>
                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    )
}
