import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Accordion, Button, Card, Col, Form, Row } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { getRequest, putRequest, updateRequest } from '../../../actions/common'
import {
    BLOCKS,
    EMPTY_RESULT_YEAR,
    EVENT_TYPES,
    INITIAL_FORMIK_HELPER,
    IS_NETWORK,
    MONTHS,
    REQUIRED_FIELDS,
    REQUIRED_FIELDS_TEMP,
    STATUS_ACCEPT,
    STATUS_NEW,
    YEAR,
} from './constant'
import { Area, Check, Input } from '../../../components/form/forms'
import { PERMISSIONS } from '../../../rbac/constant'
import { useAbac } from 'react-abac'
import { btnMessage } from '../../../utils/utils'
import { MyFileBrowser } from '../../../components/file_manager/file_manager'
import { Field, FieldArray, FormikProvider, useFormik } from 'formik'
import { FormikDate, FormikSelect } from '../../../components/form/formik'
import { YearResults } from '../../../components/databases/year_results'

export const EducationalProgramItem = ({
                                           data,
                                           user,
                                           disabled,
                                           messages,
                                           generateOptions,
                                           ErrorsBlock,
                                           MessageHistory,
                                           ActionButtons,
                                           DisableButton,
                                           HeaderStatus,
                                           handleSubmit,
                                           setData,
                                           setMessages,
                                           MainBlock,
                                           FileBlock,
                                           ToggleButtons,
                                           EditButton,
                                       }) => {
    const params = useParams()
    const navigate = useNavigate()

    const { userHasPermissions } = useAbac()
    const [select_data, setSelectData] = useState({})

    const project_ref = useRef()

    const select_fields = ['speciality_id', 'project', 'supervisor', 'deadlines_oop']
    const given_select_fields = ['event_type', 'is_network']
    const [other_speciality_id, setOtherSpecialityId] = useState(-1)

    const [input_fields, setInputFields] = useState({
        'status': 1,
        'current_status': 1,
        'is_closed': false,
        'save_form': false,
        'event_name': '',
        'speciality_id': null,
        'project_id': null,
        'responsible_edu': '',
        'event_type': '',
        'start': '',
        'is_network': null,
        'end': '',
        'dev_date': '',
        'impl_date_plan': '',
        'impl_date_real': '',
        'indicator_month': null,
        'indicator_year': null,
        'retrain_date': '',
        'start_order_date': '',
        'first_launch_date': '',
        'next_training_date': '',
        'document_link': '',
        'speciality_custom': '',
        'count_educated': 0,
        'count_educated_commercial': 0,
        'rpd_progress': 0,
        'partners': '',
        'months': ['', '', '', '', '', '', '', '', '', '', '', ''],
        'blocks': {
            'matrix_dev': {},
            'reference': {},
            'characteristic': {},
            'curriculum': {},
            'information_pedagogical': {},
            'information_specialist': {},
            'information_mto': {},
            'information_leader': {},
            'extract_protocol': {},
        },
        'year_result': [EMPTY_RESULT_YEAR],
        'oop_progres': 0,
    })

    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: (values) => {
            return getRequest('educational_program', () => null, {}, params.id, null, null, true)
                .then((r) => {
                    if (r.data?.results?.[0]?.updated_time !== data?.updated_time) {
                        formik.setFieldError('status', 'Данные устарели! Обновите страницу.')
                        return 'is_canceled'
                    } else {
                        let temp = { ...formik.errors }
                        delete temp?.status
                        formik.setErrors(temp)
                        return saveFunction()
                    }
                })
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })

    const formik_helper = useFormik({
        initialValues: {}, onSubmit: (values) => {
            for (let block of [...BLOCKS, 'rpd']) {
                if (values?.[block]?.message) {
                    putRequest('educational_program_messages', {
                        type: block,
                        content: values?.[block]?.message,
                        educational_program_status: data.status,
                        status: data.status,
                        created_by: user.id,
                        educational_program: data.id,
                    }, false).then((r) => {
                        formik_helper.setFieldValue(`${block}.message`, '')
                        getRequest('educational_program_messages', setMessages, {
                            type_data: 'all', educational_program: params.id,
                        }).then()
                    })
                }
            }
        }, enableReinitialize: true, validateOnChange: false, validateOnBlur: false,
    })

    useEffect(() => {
        handleSubmit(formik, saveFunction)
    }, [formik.values])

    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${data?.id} в БД Образовательные программы | ИС «ПИШ»`

            let set_selected = {}
            select_fields.forEach((field) => {
                set_selected[`${field}_id`] = data[field]?.id
            })
            given_select_fields.forEach((field) => {
                set_selected[field] = data[field]
            })
            let set_inputs = {}
            for (let field in input_fields) {
                set_inputs[field] = data[field]
            }

            if (!set_inputs?.year_result) {
                set_inputs['year_result'] = [EMPTY_RESULT_YEAR]
            }

            let init_helper = {}
            for (let block of BLOCKS) {
                let change_date = !!data.blocks?.[block]?.end_stage_date
                init_helper[block] = { ...INITIAL_FORMIK_HELPER, change_date: change_date, type: block }
            }
            formik_helper.setValues({ ...init_helper, rpd: { ...INITIAL_FORMIK_HELPER, type: 'rpd' } })

            if (!set_inputs?.months?.length) {
                set_inputs['months'] = ['', '', '', '', '', '', '', '', '', '', '', '']
            }
            formik.setValues({
                ...set_inputs, ...set_selected, current_status: data.status,
            })
        }
    }, [data])

    useEffect(() => {
        if (data) {
            if (Object.keys(select_data).length < select_fields.length) {
                for (let field of ['speciality', 'project', 'template_oop', 'deadlines_oop']) {
                    if (!Object.keys(select_data).includes(field)) {
                        getRequest(field, setSelectData, { type_data: 'all' }, '', select_data, field)
                        break
                    }
                }
            }
            if (select_data['speciality'] && other_speciality_id === -1) {
                let other_id = select_data['speciality'].find((v) => v?.title === 'Другое')?.id
                setOtherSpecialityId(other_id)
            }

        }
    }, [data, select_data, formik.values], other_speciality_id)

    useEffect(() => {
        if (formik.values?.project_id !== project_ref.current && Object.keys(select_data).includes('event')) {
            setSelectData({ ...select_data, event: [] })
        }
        if (formik.values?.project_id) project_ref.current = formik.values?.project_id
    }, [formik.values])

    const saveFunction = () => {
        formik.setFieldValue('save_form', false)

        let payload = null
        if (STATUS_ACCEPT === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values, direction_by: user.id }
        } else {
            payload = formik.values
        }

        updateRequest('educational_program', { 'common': payload }, params.id).then((response) => {
            if (response.status === 200) {
                if (payload?.status !== data?.status) navigate('/educational_program')
                setData(response.data)
            }
        })
        if (formik?.values?.message) {
            putRequest('educational_program_messages', {
                content: formik?.values?.message,
                educational_program_status: data.status,
                status: data.status,
                created_by: user.id,
                educational_program: data.id,
            }, false).then()
            document.getElementById('message').value = null
            formik.setFieldValue('message', '')
        }

        return Promise.resolve('is_saved')
    }

    const getTags = (document_type) => {
        return {
            document_type: document_type,
            year: formik.values.indicator_year,
            month: formik.values.indicator_month,
            project: formik.values.project_id,
            educational_program_item: data?.id,
        }
    }

    return (<React.Fragment>
        <FormikProvider value={formik}>
            <ReactBreadcrumb/>
            <Form>
                <div className='box'>
                    <div className={`d-flex align-items-center ${disabled ? 'justify-content-between' : ''}`}>
                        <h1 className='page-header d-flex'>
                            {`№${data?.id} в БД Образовательные программы`}
                        </h1>
                        <DisableButton/>
                    </div>
                    <div className={'d-flex justify-content-between align-items-center'}>
                        <Button variant={'outline-primary'} className={'flex-shrink-0 mt-1 mr-2'} size={'md'}
                                onClick={btnMessage}>
                            <IconText icon={'comment'}
                                      text={disabled ? 'Сообщения' : ''}/>
                        </Button>
                        <EditButton/>
                        <ActionButtons form={formik}
                                       permission_control={PERMISSIONS.CONTROL_OOP}
                                       permission_manage={PERMISSIONS.MANAGE_OOP}
                                       can_next={!(userHasPermissions(PERMISSIONS.IS_MANAGER))}
                                       can_back={userHasPermissions(PERMISSIONS.IS_MANAGER) ? false : data?.status > STATUS_NEW && data?.status !== STATUS_ACCEPT}
                        />
                    </div>
                </div>
                <Card className={'bg-white mt-4 mb-4'}
                      style={messages && messages?.length !== 0 || formik.errors?.message ? { display: 'block' } : { display: 'none' }}
                      id={'card-message'}>
                    <Card.Body>
                        <div className='alert alert-danger'
                             style={{ display: formik.errors?.message ? 'block' : 'none' }}>
                            <p>Исправьте следующие ошибки:</p>
                            <ul>
                                <li>Необходимо заполнить: "Комментарий"</li>
                            </ul>
                        </div>
                        <label>История сообщений</label>
                        <MessageHistory/>
                        <div id='field-mention' className='content-group navbar-nav px-0'>
                            <div id='field-comment'>
                                <label htmlFor='message'>Комментарий</label>
                                <Area id='message'
                                      name={'message'}
                                      value={formik.values?.message}
                                      disabled={disabled}
                                      rows='5'
                                      error={formik.errors['message']}
                                      invalid={formik.errors['message']}
                                      handleChangeValue={formik.handleChange}
                                />
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Accordion>
                    <Accordion.Item eventKey='0'>
                        <Accordion.Button className={'accordion-head'}>Дополнительная информация</Accordion.Button>
                        <Accordion.Body className={'accordion-body'}>
                            <p>
                                <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте
                                следующие
                                условные обозначения,
                                используемые в интерфейсе:
                            </p>
                            <ul>
                                <li>поля помеченные <strong className='text-danger'>красной
                                    «*»</strong> являются <strong>обязательными
                                    на текущем
                                    этапе для перехода на следующий</strong>;
                                </li>
                                <li>поля помеченные <strong className='text-success'>зелёной
                                    «*»</strong> являются <strong>обязательными
                                    для
                                    определенных условий</strong>;
                                </li>
                                <li>кнопка <span className='badge badge-success'>Сохранить</span> выполняет
                                    сохранение
                                    текущего состояния карточки (требуется
                                    заполнения ключевых полей);
                                </li>
                                <li>кнопка <span
                                    className='badge badge-success'>Сохранить + отправить далее</span> сохраняет
                                    карточку и
                                    проверяет выполнение
                                    условий текущего этапа (при выполнении условий карточка переходит на следующий
                                    этап).
                                </li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Card className={'bg-white mb-3 mt-3'}>
                    <Card.Body>
                        <ToggleButtons/>
                        <hr/>
                        <ErrorsBlock form={formik}/>
                        <MainBlock>
                            <fieldset>
                                <legend>
                                    <Row>
                                        <Col md={2}>
                                            Основные данные
                                        </Col>
                                        <Col md={4}>
                                            <Check
                                                label={'Образовательная программа закрыта'}
                                                handleChangeValue={formik.handleChange}
                                                disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) ? true : disabled}
                                                name={'is_closed'}
                                                id={'is_closed'}
                                                error={formik.errors.is_closed}
                                                invalid={formik.errors.is_closed}
                                                value={formik.values.is_closed}
                                            />
                                        </Col>
                                    </Row>
                                </legend>
                                <Row>
                                    <Col md={12}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Наименование программы'}
                                               id={'event_name'}
                                               name={'event_name'}
                                               error={formik.errors['event_name']}
                                               invalid={formik.errors['event_name']}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                               value={formik.values.event_name}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('event_name')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Специальность и направления подготовки'}
                                               id={'speciality_custom'}
                                               name={'speciality_custom'}
                                               error={formik.errors['speciality_custom']}
                                               invalid={formik.errors['speciality_custom']}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                               value={formik.values.speciality_custom}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('speciality_custom')}
                                        />
                                    </Col>

                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'event_type'}
                                               label={'Тип программы'}
                                               isClearable={true}
                                               options={EVENT_TYPES}
                                               id={'event_type'}
                                               error={formik.errors['event_type_id']}
                                               invalid={formik.errors['event_typed_id']}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('event_type')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikDate}
                                               name={'start'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата начала реализации'}
                                               id={'start'}
                                               placeholderText={'дд.мм.гггг'}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('start')}
                                               value={formik.values.start}
                                               error={formik.errors['start']}
                                               invalid={formik.errors['start']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate}
                                               name={'end'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата окончания реализации'}
                                               id={'end'}
                                               placeholderText={'дд.мм.гггг'}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('end')}
                                               value={formik.values.end}
                                               error={formik.errors['end']}
                                               invalid={formik.errors['end']}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Area handleChangeValue={formik.handleChange}
                                              label={'Задействованные в реализации, высокотехнологичные компании партнеры'}
                                              id={'partners'}
                                              name={'partners'}
                                              error={formik.errors['partners']}
                                              invalid={formik.errors['partners']}
                                              disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                              value={formik.values.partners}
                                              required={REQUIRED_FIELDS[STATUS_NEW].includes('partners')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'is_network'}
                                               label={'Программа является сетевой?'}
                                               isClearable={true}
                                               options={IS_NETWORK}
                                               id={'is_network'}
                                               error={formik.errors['is_network_id']}
                                               invalid={formik.errors['is_network_id']}
                                               disabled={disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('is_network')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect} name={'supervisor_id'}
                                               label={'Руководитель паспорта (Проект)'}
                                               id={'supervisor_id'}
                                               isClearable={true}
                                               isSearchable={true}
                                               error={formik.errors['supervisor']}
                                               invalid={formik.errors['supervisor']}
                                               options={generateOptions('project', 'title_short', select_data)}
                                               required={data ? REQUIRED_FIELDS[data.status].includes('supervisor') : false}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Ответственный за образование'}
                                               id={'responsible_edu'}
                                               name={'responsible_edu'}
                                               error={formik.errors['responsible_edu']}
                                               invalid={formik.errors['responsible_edu']}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                               value={formik.values.responsible_edu}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('responsible_edu')}
                                        />
                                    </Col>
                                </Row>

                                <hr/>
                            </fieldset>
                            <fieldset>
                                <legend>План</legend>
                                <Row>
                                    <Col md={4}>
                                        <Field component={FormikDate}
                                               name={'dev_date'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Программа разработана'}
                                               id={'dev_date'}
                                               showMonthYear={true}
                                               placeholderText={'мм.гггг'}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('dev_date')}
                                               value={formik.values.dev_date}
                                               error={formik.errors['dev_date']}
                                               invalid={formik.errors['dev_date']}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Field component={FormikDate}
                                               name={'impl_date_plan'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Программа внедрена'}
                                               id={'impl_date_plan'}
                                               showMonthYear={true}
                                               placeholderText={'мм.гггг'}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('impl_date_plan')}
                                               value={formik.values.impl_date_plan}
                                               error={formik.errors['impl_date_plan']}
                                               invalid={formik.errors['impl_date_paln']}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Field component={FormikDate}
                                               name={'retrain_date'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Повторное обучение по программе'}
                                               id={'retrain_date'}
                                               showMonthYear={true}
                                               placeholderText={'мм.гггг'}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('retrain_date')}
                                               value={formik.values.retrain_date}
                                               error={formik.errors['retrain_date']}
                                               invalid={formik.errors['retrain_date']}
                                        />
                                    </Col>
                                </Row>
                            </fieldset>
                            <hr/>
                            <fieldset>
                                <legend>Действительность</legend>
                                <Row>
                                    <Col md={4}>
                                        <Field component={FormikDate}
                                               name={'start_order_date'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата приказа об открытии'}
                                               id={'start_order_date'}
                                               placeholderText={'дд.мм.гггг'}
                                               disabled={!userHasPermissions(PERMISSIONS.MANAGER_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('start_order_date')}
                                               value={formik.values.start_order_date}
                                               error={formik.errors['start_order_date']}
                                               invalid={formik.errors['start_order_date']}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Field component={FormikDate}
                                               name={'first_launch_date'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата первого запуска'}
                                               id={'first_launch_date'}
                                               placeholderText={'дд.мм.гггг'}
                                               disabled={!userHasPermissions(PERMISSIONS.MANAGER_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('first_launch_date')}
                                               value={formik.values.first_launch_date}
                                               error={formik.errors['first_launch_date']}
                                               invalid={formik.errors['first_launch_date']}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Field component={FormikDate}
                                               name={'next_training_date'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата последующего обучения'}
                                               id={'next_training_date'}
                                               placeholderText={'дд.мм.гггг'}
                                               disabled={!userHasPermissions(PERMISSIONS.MANAGER_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('next_training_date')}
                                               value={formik.values.retrain_date}
                                               error={formik.errors['next_training_date']}
                                               invalid={formik.errors['next_training_date']}
                                        />
                                    </Col>

                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'indicator_month'}
                                               label={'Месяц внедрения (месяц учета в показателе)'}
                                               id={'indicator_month'}
                                               isSearchable={true}
                                               isClearable={true}
                                               options={MONTHS.map((v, i) => {
                                                   return { value: i + 1, label: v }
                                               })}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('indicator_month')}
                                               error={formik.errors['indicator_month_id']}
                                               invalid={formik.errors['indicator_month_id']}
                                               disabled={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'indicator_year'}
                                               label={'Год внедрения (год учета в показателе)'}
                                               id={'indicator_year'}
                                               isSearchable={true}
                                               isClearable={true}
                                               options={YEAR}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('indicator_year')}
                                               error={formik.errors['indicator_year_id']}
                                               invalid={formik.errors['indicator_year_id']}
                                               disabled={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Количество обученных'}
                                               type={'int'}
                                               id={'count_educated'}
                                               name={'count_educated'}
                                               error={formik.errors['count_educated']}
                                               invalid={formik.errors['count_educated']}
                                               disabled={!userHasPermissions(PERMISSIONS.MANAGER_PANEL) || disabled}
                                               value={formik.values.count_educated}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('count_educated')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Из количества обученных: на коммерческой основе'}
                                               type={'int'}
                                               id={'count_educated_commercial'}
                                               name={'count_educated_commercial'}
                                               error={formik.errors['count_educated_commercial']}
                                               invalid={formik.errors['count_educated_commercial']}
                                               disabled={!userHasPermissions(PERMISSIONS.MANAGER_PANEL) || disabled}
                                               value={formik.values.count_educated_commercial}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('count_educated_commercial')}
                                        />
                                    </Col>
                                </Row>
                            </fieldset>
                            <hr/>

                            <fieldset>
                                <YearResults disabled={disabled} formik={formik}
                                             yearResults={formik.values.year_result}/>
                                <hr/>
                            </fieldset>
                            <fieldset>
                                <legend>Отчет о реализации образовательной деятельности по месяцам</legend>
                                <Row>
                                    <FieldArray name={'months'} render={(arrayHelpers) => {
                                        return arrayHelpers.form.values?.months?.map((fact, month) => (<Col md={6}>
                                            <Area label={MONTHS[month]}
                                                  id={`months.${month}`}
                                                  name={`months.${month}`}
                                                  handleChangeValue={formik.handleChange}
                                                  value={fact}
                                                  disabled={disabled}
                                            />
                                        </Col>))
                                    }}/>
                                </Row>
                                <hr/>
                            </fieldset>
                        </MainBlock>
                        <FileBlock>
                            <fieldset>
                                <legend>Документы</legend>
                                <Row>
                                    <Col md={12}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Ссылка на документы'}
                                               id={'document_link'}
                                               name={'document_link'}
                                               error={formik.errors['document_link']}
                                               invalid={formik.errors['document_link']}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                               value={formik.values.document_link}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('document_link')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <label>Приказ об открытии</label>
                                        <MyFileBrowser
                                            path={`root/storage/education_program/education_program_${params.id}/open_order`}
                                            instanceId={'open_order'}
                                            height={250}
                                            tags={getTags('open_order')}
                                            read_only={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label>Документ содержащий описание программы</label>
                                        <MyFileBrowser
                                            path={`root/storage/education_program/education_program_${params.id}/description_program`}
                                            instanceId={'description_program'}
                                            height={250}
                                            tags={getTags('description_program')}
                                            read_only={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label>Приказ о зачислении слушателей</label>
                                        <MyFileBrowser
                                            path={`root/storage/education_program/education_program_${params.id}/listener_order`}
                                            instanceId={'listener_order'}
                                            height={250}
                                            tags={getTags('listener_order')}
                                            read_only={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label>Приказ об отчислении слушателей</label>
                                        <MyFileBrowser
                                            path={`root/storage/education_program/education_program_${params.id}/listener_neg_order`}
                                            instanceId={'listener_neg_order'}
                                            height={250}
                                            tags={getTags('listener_neg_order')}
                                            read_only={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label>Дипломы</label>
                                        <MyFileBrowser
                                            path={`root/storage/education_program/education_program_${params.id}/diploms`}
                                            instanceId={'diploms'}
                                            height={250}
                                            tags={getTags('diploms')}
                                            read_only={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label>Удостоверения</label>
                                        <MyFileBrowser
                                            path={`root/storage/education_program/education_program_${params.id}/certificates`}
                                            instanceId={'certificates'}
                                            height={250}
                                            tags={getTags('certificates')}
                                            read_only={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label>Дополнительные документы</label>
                                        <MyFileBrowser
                                            path={`root/storage/educational_program/educational_program_${params.id}`}
                                            height={250}
                                            tags={getTags('document')}
                                            read_only={data?.status > STATUS_NEW && !userHasPermissions(PERMISSIONS.DIRECTION_PANEL) ? true : disabled}
                                            instanceId={`educational_program_${params.id}`}/>
                                    </Col>
                                </Row>
                            </fieldset>
                        </FileBlock>
                    </Card.Body>
                </Card>
            </Form>
        </FormikProvider>
    </React.Fragment>)

}
