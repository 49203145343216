import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconText } from '../../icon_txt'
import { downloadPage } from '../../../utils/utils'
import { downloadRequest, putRequest } from '../../../actions/common'
import { Field, FormikProvider, useFormik } from 'formik'
import { FormikSelect } from '../../../components/form/formik'
import * as Yup from 'yup'

export const Export = ({ getTitle }) => (
    <Dropdown className={'mr-1 d-inline-block'}>
        <Dropdown.Toggle variant={'light'}>
            <FontAwesomeIcon icon={'external-link-alt'}/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Header>
                Экспорт данных с текущей страницы
            </Dropdown.Header>
            <Dropdown.Item onClick={() =>
                downloadPage('xlsx', 'tblToExcl', getTitle, true)
            }>
                <IconText text={'Excel'} icon={'file-excel'} icon_color={'green'}/>
            </Dropdown.Item>
            <Dropdown.Item onClick={() =>
                downloadPage('csv', 'tblToExcl', getTitle, true)
            }>
                <IconText text={'CSV'} icon={'file-csv'} icon_color={'#007bff'}/>
            </Dropdown.Item>
            <Dropdown.Item onClick={() =>
                downloadPage('json', 'tblToExcl', getTitle, true)}
                           disabled={true}
            >
                <IconText text={'JSON'} icon={'file-code'} icon_color={'#ffc107'}/>
            </Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
)

export const BackendExport = ({ getTitle, urlExport, chooseYear = true }) => {

    const today = new Date()
    const formik = useFormik({
        initialValues: {
            year: today.getFullYear(),
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            year: Yup.string().required('Обязательное поле!'),
        }),
        onSubmit: () => {
        },
    })

    const YEAR = [
        { value: 2022, label: 2022 },
        { value: 2023, label: 2023 },
        { value: 2024, label: 2024 },
        { value: 2025, label: 2025 },
        { value: 2026, label: 2026 },
        { value: 2027, label: 2027 },
        { value: 2028, label: 2028 },
        { value: 2029, label: 2029 },
        { value: 2030, label: 2030 },
    ]

    const customDownload = () => {
        if (!formik.values.year) {
            return
        }
        putRequest(urlExport, formik.values.year).then((response) => {
            if (response.status === 200) {
                downloadRequest(getTitle,
                    { file_name: `${getTitle}_export/${response.data}` })
            }
        })
    }

    return (
        <Dropdown className={'mr-1 d-inline-block'} autoClose='outside'>
            <Dropdown.Toggle variant={'light'}>
                <FontAwesomeIcon icon={'external-link-alt'}/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>
                    Экспорт данных с текущей страницы
                </Dropdown.Header>
                {chooseYear
                    ? <Dropdown.Item href={''}>
                        1. Введите год, за который необходимо выгрузить данные:
                        <div>
                            <FormikProvider value={formik}>
                                <Field component={FormikSelect}
                                       name={'year'}
                                       label={'Год зачета'}
                                       id={'year'}
                                       isSearchable={true}
                                       isClearable={true}
                                       options={YEAR}
                                       error={formik.errors['year_id']}
                                       invalid={formik.errors['year_id']}
                                />
                            </FormikProvider>
                        </div>
                    </Dropdown.Item>
                    : null}
                <Dropdown.Item>
                    {chooseYear ? '2.' : '1.'} Нажмите на нужный формат:
                </Dropdown.Item>
                <Dropdown.Item onClick={() => customDownload()}>
                    <IconText text={'Excel'} icon={'file-excel'} icon_color={'green'}/>
                </Dropdown.Item>
                <Dropdown.Item onClick={() =>
                    downloadPage('csv', 'tblToExcl', getTitle, true)
                }>
                    <IconText text={'CSV'} icon={'file-csv'} icon_color={'#007bff'}/>
                </Dropdown.Item>
                <Dropdown.Item onClick={() =>
                    downloadPage('json', 'tblToExcl', getTitle, true)}
                               disabled={true}
                >
                    <IconText text={'JSON'} icon={'file-code'} icon_color={'#ffc107'}/>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}
