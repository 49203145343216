import axiosInstance, { protectedAxios } from '../utils/axiosAPI'
import { getRandomString } from '../utils/utils'
import { toast } from 'react-toastify'

export const checkIsEmptyDirectory = (setFunc, path, instanceId, params = null, getTags = null) => {
    let paramsRequest = { ...params, path: path }
    if (getTags) {
        for (let key in getTags) {
            paramsRequest[`get_tag_${key}`] = getTags[key]
        }
    }

    return protectedAxios(axiosInstance.get, '/api/directory/', { params: paramsRequest })
        .then((response) => {
            if (response.status === 200) {
                const filesNumber = response.data[instanceId]?.childrenCount
                const isEmpty = !filesNumber || filesNumber === 0
                setFunc(isEmpty)
            }
        })
        .catch((err) => console.log(err))
}

export const getDirectory = (setFunc, path, params, getTags = null, showPreloader = false) => {
    let paramsRequest = { ...params, path: path }
    if (getTags) {
        for (let key in getTags) {
            paramsRequest[`get_tag_${key}`] = getTags[key]
        }
    }

    let toastId = null
    if (showPreloader) {
        toastId = toast.loading('Файловый менеджер загружается')
    }

    return protectedAxios(axiosInstance.get, '/api/directory/', { params: paramsRequest })
        .then((response) => {
            if (response.status === 200) {
                setFunc(response.data)
            }
            if (toastId) {
                if (response.status === 200) {
                    toast.dismiss(toastId)
                } else {
                    toast.error('Произошла ошибка', { id: toastId })
                    toast.dismiss(toastId)
                }
            }
        })
        .catch((err) => {
            console.log(err)
            toast.dismiss(toastId)
        })
}

export const getFile = (path, file, tags=null) => {
    let body = {
        params: {
            path: path,
            file: file,
            tags: tags,
        },
        headers:
            {
                'Content-Disposition': 'attachment;',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        responseType: 'blob',
    }

    protectedAxios(axiosInstance.get, '/api/file/', body)
        .then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = downloadUrl
            link.setAttribute('download', file ? file : `${getRandomString(8)}.zip`)
            document.body.appendChild(link)
            link.click()
            link.remove()
        })
        .catch((err) => toast.warning('Не удалось найти файл!'))
}

export const uploadFile = (url = '/api/file/', formData, setFunc) => {
    let body = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }

    return protectedAxios(axiosInstance.post, url, formData, body)
        .then((response) => {
            if (response.status === 200) {
                setFunc(response.data)
            }
            return response
        })
        .catch((err) => {
            return err.response
        })
}


export const deleteFile = (path, file, setFunc) => {
    let body = {
        data: {
            path: path,
            file: file,
        },
    }

    protectedAxios(axiosInstance.delete, '/api/file/', body)
        .then((response) => {
            if (response.status === 200) {
                setFunc(response.data)
            }
        })
        .catch((err) => console.log(err))
}
