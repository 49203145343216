import { ChonkyActions, FileHelper, FullFileBrowser, setChonkyDefaults } from 'chonky'
import { ChonkyIconFA } from 'chonky-icon-fontawesome'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { deleteFile, getDirectory, getFile, uploadFile } from '../../actions/file_manager'
import { russianI18n } from './locale'
import { toast } from 'react-toastify'

setChonkyDefaults({ iconComponent: ChonkyIconFA })

const MAX_FILE_SIZE = 200 * 1024 * 1024 // 200 MB
const ALLOWED_EXTENSIONS = ['doc', 'xlsx', 'xls', 'pdf', 'pptx', 'ptx']

export const MyFileBrowser = ({
                                  path,
                                  instanceId,
                                  borderInvalid = false,
                                  height = 700,
                                  read_only = false,
                                  can_upload = true,
                                  can_download = true,
                                  can_delete = true,
                                  setIsEmpty = null,
                                  tags = null,
                                  getTags = null,
                                  year = null,
                                  documentsReport = false,
                                  showPreloader = false,
                                  handleUploadFile = (file) => null,
                                  disableDispatch,
                              }) => {
    const maxDepth = 3
    const [fileMap, setFileMap] = useState(null)
    const [currentFolderId, setCurrentFolderId] = useState(instanceId)
    const params = { 'documents_report': documentsReport, 'year': year }

    const handleSetFileMap = (newFiles) => setFileMap({ ...fileMap, ...newFiles })

    useEffect(() => {
        setCurrentFolderId(instanceId)
    }, [instanceId])

    useEffect(() => {
        if (currentFolderId?.split('/')?.length >= maxDepth && currentFolderId !== instanceId) {
            getDirectory(handleSetFileMap, currentFolderId, params, getTags, showPreloader).then()
        }
    }, [currentFolderId])

    useEffect(() => {
        if (!fileMap) {
            getDirectory(setFileMap, path, params, getTags, showPreloader).then()
        }
        if (setIsEmpty) {
            const filesNumber = fileMap?.[instanceId]?.childrenCount
            const isEmpty = !filesNumber || filesNumber === 0
            setIsEmpty(isEmpty)
        }
    }, [fileMap])

    const useFiles = (currentFolderId) => {
        return useMemo(() => {
            if (fileMap && Object.keys(fileMap).includes(currentFolderId)) {
                const currentFolder = fileMap[currentFolderId]
                let folderFiles = currentFolder?.childrenIds ? currentFolder.childrenIds.map((fileId) => fileMap[fileId] ?? null) : []

                if (!year) return folderFiles

                let visibleFiles = []
                for (let fileId in folderFiles) {
                    let file = folderFiles[fileId]
                    if (!file?.tags?.year) {
                        visibleFiles.push(file)
                    } else if (file.tags.year.includes(year.toString())) {
                        visibleFiles.push(file)
                    }
                }

                return visibleFiles
            }
            return []
        }, [fileMap, currentFolderId, year])
    }

    const useFolderChain = (currentFolderId) => {
        return useMemo(() => {
            if (fileMap && Object.keys(fileMap).includes(currentFolderId)) {
                const currentFolder = fileMap[currentFolderId]
                const folderChain = [currentFolder]

                let parentId = currentFolder?.parentId
                while (parentId) {
                    const parentFile = fileMap[parentId]
                    if (parentFile) {
                        folderChain.unshift(parentFile)
                        parentId = parentFile.parentId
                    } else {
                        parentId = null
                    }
                }

                return folderChain
            }
            return ''
        }, [fileMap, currentFolderId])
    }

    const useFileActionHandler = (setCurrentFolderId) => {
        if (!can_upload) can_delete = false
        return useCallback(
            (data) => {
                let currentPath
                if (instanceId === currentFolderId) {
                    currentPath = path
                } else {
                    currentPath = currentFolderId.substring(0, currentFolderId.length - 1)
                }

                if (data.id === ChonkyActions.OpenFiles.id) {
                    const { targetFile, files } = data.payload
                    const fileToOpen = targetFile ?? files[0]
                    if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
                        setCurrentFolderId(fileToOpen.id)
                        return
                    }
                }
                if (data.id === ChonkyActions.DownloadFiles.id) {
                    for (let file of data.state.selectedFiles) {
                        if (file.isDir) {
                            getFile(`${file.parentId}${file.name}`, '')
                        } else {
                            getFile(file.parentId, file.name)
                        }
                    }
                }
                if (data.id === ChonkyActions.UploadFiles.id) {
                    const handleUpload = (event) => {
                        event.preventDefault()
                        for (let i in event.target.files) {

                            const newFile = event.target.files[i]
                            const fileExtension = newFile.name.split('.').pop().toLowerCase()
                            if (!(newFile.size <= MAX_FILE_SIZE)) {
                                toast.warning(`Размер файла превышает ${MAX_FILE_SIZE} Мб. 
                        Для загрузки файла необходимо провести его сжатие.`)
                                return
                            }
                            if (!(ALLOWED_EXTENSIONS.includes(fileExtension))) {
                                toast.warning('Расширение файла не соответствует допустимым расширениям. '
                                    + 'Чтобы загрузить документ используйте другое расширение файла.'
                                    + ` Список допустимых расширений: ${ALLOWED_EXTENSIONS.join(', ')}`)
                                return
                            }

                            let formData = new FormData()
                            formData.append('file', newFile)
                            formData.append('path', path)
                            formData.append('year', year)
                            if (getTags) {
                                for (let key in getTags) {
                                    formData.append(`tag_${key}`, getTags[key])
                                }
                            }
                            uploadFile('/api/file/', formData, setFileMap).then((r) => {
                                handleUploadFile(newFile)
                                // getDirectory(setFileMap, path, params, getTags, showPreloader).then((r) => {
                                // })
                            })
                        }
                    }
                    const form = document.createElement('form')
                    form.enctype = 'multipart/form-data'

                    const input = document.createElement('input')
                    input.type = 'file'
                    input.multiple = true
                    form.onchange = handleUpload
                    form.onsubmit = (e) => {
                        e.preventDefault()
                    }

                    form.appendChild(input)
                    input.click()
                }
                if (data.id === ChonkyActions.DeleteFiles.id) {
                    for (let file of data.state.selectedFiles) {
                        deleteFile(currentPath, file.name, setFileMap)
                    }
                }
            },
            [fileMap, currentFolderId, setCurrentFolderId],
        )
    }

    const files = useFiles(currentFolderId)
    const folderChain = useFolderChain(currentFolderId)
    const handleFileAction = useFileActionHandler(setCurrentFolderId)

    const getFileActions = (read_only, can_delete, can_upload, can_download) => {
        if (read_only) return null
        if (can_delete) {
            return [ChonkyActions.UploadFiles, ChonkyActions.DownloadFiles, ChonkyActions.DeleteFiles]
        } else if (can_upload) {
            return [ChonkyActions.UploadFiles, ChonkyActions.DownloadFiles]
        } else if (can_download) {
            return [ChonkyActions.DownloadFiles]
        } else {
            return null
        }
    }

    return (
        <div style={{ height: height, border: borderInvalid ? '2px solid #dc3545' : '', borderRadius: '5px' }}>
            <FullFileBrowser
                instanceId={currentFolderId}
                files={files}
                folderChain={folderChain}
                onFileAction={handleFileAction}
                fileActions={getFileActions(read_only, can_delete, can_upload, can_download)}
                i18n={russianI18n}
                defaultFileViewActionId={ChonkyActions.EnableListView.id}
            />
        </div>
    )

}
