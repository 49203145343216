import { ROLES } from '../../../rbac/constant'
import { Input, InputDate, Select } from '../../../components/form/forms'
import * as Yup from 'yup'

export const STATUS_DRAFT = -1             // Черновик
export const STATUS_NEW = 1                // Новая сущность в БД
export const STATUS_TEMP = 2

export const STATUS_LABELS = {
    [STATUS_NEW]: 'Принято',
    // [STATUS_ACCOUNTING]: 'Ведётся учёт по расходам',
}

export const STATUS_OPTIONS = [
    { value: STATUS_NEW, label: 'Принято' },
]

export const STATUS_BADGE_CLASS = {
    [STATUS_NEW]: 'badge-success',
}

export const LABELS = {
    'id': 'ID',
    'status': 'Статус',
    'project': 'Проект',
    'event': 'Мероприятие',
    'quarters': 'Кварталы',
    'full_sum': 'Сумма',
    'created_by': 'Создано',
    'updated_by': 'Обновлено',
    'created_at': 'Время создания',
    'updated_at': 'Время обновления',
    'date': 'Дата справки',
    'number': 'Номер справки',
    'sum': 'Сумма',
}

export const FIELDS = {
    id: {
        type: Input,
        content: 'number',
        props: {
            show_zero: false,
        },
    },
    status: {
        type: Select,
        options: STATUS_OPTIONS,
    },
    project: {
        type: Select,
        source: 'project',
        key: (v) => v?.title_short,
    },
    event: {
        type: Select,
        source: 'event',
        key: (v) => `(${v?.title_short}) ${v?.title}`,
    },
    full_sum: {
        type: Input,
        content: 'number',
    },
    created_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
    updated_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
    created_at: {
        type: InputDate,
        content: 'date',
    },
    updated_at: {
        type: InputDate,
        content: 'date',
    },
}

export const REQUIRED_FIELDS = {
    [STATUS_NEW]: [],
}

const getSchema = (status_) => {
    let schema = {
        status: Yup.number(),
        message: Yup.string().when(['status', 'current_status'], (status, schema) => {
            return status[0] < status[1] ? schema.required('Обязательное поле!') : schema
        }),
        quarters: Yup.array(Yup.object({
            number: Yup.number().nullable(),
            date: Yup.date().nullable().default(undefined),
            sum: Yup.number().nullable(),
        })),
    }
    if (status_ === STATUS_DRAFT) {
        schema = {
            ...schema,
            project: Yup.number()
                .typeError('Обязательное поле!')
                .required('Обязательное поле!'),
            event: Yup.number()
                .typeError('Обязательное поле!')
                .required('Обязательное поле!'),
        }
    } else {
        schema = {
            ...schema,
            project_id: Yup.number()
                .typeError('Обязательное поле!')
                .required('Обязательное поле!'),
            event_id: Yup.number()
                .typeError('Обязательное поле!')
                .required('Обязательное поле!'),
        }
    }
    return schema
}

export const REQUIRED_FIELDS_TEMP = {
    [STATUS_DRAFT]: Yup.object().shape(getSchema(STATUS_DRAFT)),
    [STATUS_NEW]: Yup.object().shape(getSchema(STATUS_NEW)),
}

export const CAN_MANAGE_ROLES = {
    [STATUS_NEW]: [ROLES.admin, ROLES.direction],
}
