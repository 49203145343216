import { ROLES } from '../../../../rbac/constant'
import { Input, InputDate, Select } from '../../../../components/form/forms'
import * as Yup from 'yup'
import { setRequiredField } from '../../../../utils/utils'

export const STATUS_DRAFT = -1             // Черновик
export const STATUS_ACCEPT = 3             // Принято
export const STATUS_NEW = 1                // Новая сущность в БД
export const STATUS_APPROVE = 2            // Рассматривается Дирекцией
export const STATUS_TEMP = 4            // Рассматривается Дирекцией


export const STATUS_LABELS = {
    [STATUS_NEW]: 'Заполнение менеджером',
    [STATUS_APPROVE]: 'Согласование дирекцией',
    [STATUS_ACCEPT]: 'Получен охранный документ / Отказ',
}

export const STATUS_DOUBLE_SIDED = [
    STATUS_APPROVE,
]

export const STATUS_OPTIONS = [
    { value: STATUS_NEW, label: 'Заполнение менеджером' },
    { value: STATUS_APPROVE, label: 'Согласование дирекцией' },
    { value: STATUS_ACCEPT, label: 'Получен охранный документ / Отказ' },
]

export const STATUS_BADGE_CLASS = {
    [STATUS_NEW]: 'badge-warning',
    [STATUS_APPROVE]: 'badge-info',
    [STATUS_ACCEPT]: 'badge-success',
}

export const RID_TYPES = [
    { value: -1, label: 'Программа для ЭВМ' },
    { value: 1, label: 'База данных' },
    { value: 2, label: 'Промышленный образец' },
    { value: 3, label: 'Топология интегральной микросхемы' },
    { value: 4, label: 'Изобретение' },
    { value: 5, label: 'Полезная модель' },
    { value: 6, label: 'Секрет производства (ноу-хау)' },
]

export const RID_DOCUMENT_TYPES = [
    { value: -1, label: 'Письмо о подаче заявки' },
    { value: 1, label: 'Уведомление ФИПС' },
    { value: 2, label: 'Патент' },
    { value: 3, label: 'Свидетельство о государственной регистрации' },
]

export const RID_STATUS_REJECTION = -1
export const RID_STATUS_RECEPTION = 1
export const RID_STATUS_APPLICATION = 2

export const RID_STATUS = [
    { value: RID_STATUS_REJECTION, label: 'Отказ' },
    { value: RID_STATUS_RECEPTION, label: 'Получение охранного документа' },
    { value: RID_STATUS_APPLICATION, label: 'Заявка' },
]

export const RID_FINANCING_METHOD = [
    { value: -1, label: 'РИД за грант' },
    { value: 1, label: 'инициативный РИД' },
]

export const LABELS = {
    'id': 'ID',
    'status': 'Статус',
    'application_status': 'Статус подачи',
    'application_date': 'Дата получения заявки',
    'date': 'Дата получения охранного документа',
    'document_type': 'Вид документа',
    'application_number': 'Номер заявки',
    'number': 'Номер охранного документа',
    'patent_office': 'Страна действия патента',
    'organization_name': 'Заявленный правообладатель',
    'title': 'Наименование созданного РИД',
    'title_ru': 'Наименование созданного РИД',
    'type': 'Вид РИД',
    'related_docs_url': 'Ссылка на хранилище',
    'project': 'Проект',
    'authors_input': 'ФИО авторов',
    'fin_method': 'Способ финансирования',
    'rid_message': 'Комментарий',
    'messages': 'Сообщения',
    'updated_by': 'Обновлено пользователем',
    'created_at': 'Дата создания',
    'updated_at': 'Дата обновления',
    'created_by': 'Создано пользователем',
    'direction_rid_by': 'Дирекция РИД',
    'direction_by': 'Дирекция',
}

export const ERRORS_MESSAGES = {
    '1': [
        { field: 'Номер заявки' },
        { field: 'Дата получения заявки' },
        { field: 'Уведомление ФИПС', isFile: true },
    ],
    '2': [
        { field: 'Номер охранного документа' },
        { field: 'Дата получения охранного документа' },
        { field: 'Патент', isFile: true },
    ],
    '3': [
        { field: 'Номер охранного документа' },
        { field: 'Дата получения охранного документа' },
        { field: 'Свидетельство о государственной регистрации', isFile: true },
    ],
}

export const FIELDS = {
    id: {
        type: Input,
        content: 'number',
        props: {
            show_zero: false,
        },
    },
    project: {
        type: Select,
        source: 'project',
        key: (v) => v?.title_short,
    },
    status: {
        type: Select,
        options: STATUS_OPTIONS,
    },
    application_status: {
        type: Select,
        options: RID_STATUS,
    },
    application_date: {
        type: InputDate,
        content: 'date',
    },
    date: {
        type: InputDate,
        content: 'date',
    },
    document_type: {
        type: Select,
        options: RID_DOCUMENT_TYPES,
    },
    application_number: {
        type: Input,
        content: 'number',
    },
    number: {
        type: Input,
        content: 'number',
    },
    patent_office: {
        type: Select,
        source: 'patent_office',
    },
    organization_name: {
        type: Input,
        content: 'text',
    },
    title: {
        type: Input,
        content: 'text',
    },
    title_ru: {
        type: Input,
        content: 'text',
    },
    type: {
        type: Select,
        options: RID_TYPES,
    },
    fin_method: {
        type: Select,
        options: RID_FINANCING_METHOD,
    },
    related_docs_url: {
        type: Input,
        content: 'text',
    },
    authors_input: {
        type: Input,
        content: 'text',
    },
    updated_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
    created_at: {
        type: InputDate,
        content: 'date',
    },
    updated_at: {
        type: InputDate,
        content: 'date',
    },
    created_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
    direction_rid_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
    direction_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
}

export const REQUIRED_FIELDS = {
    [STATUS_NEW]: [
        'title_ru', 'type',
    ],
    [STATUS_APPROVE]: [
        'title_ru', 'type', 'project', 'patent_office',
        'application_status', 'organization', 'authors', 'document_type',
    ],
    [STATUS_ACCEPT]: [
        'title_ru', 'type', 'project', 'patent_office',
        'application_status', 'organization', 'authors', 'document_type',
        'application_date', 'application_number', 'date', 'number',
    ],
    [STATUS_TEMP]: [
        'title_ru', 'type', 'project', 'patent_office',
        'application_status', 'organization', 'authors', 'document_type',
        'application_date', 'application_number', 'date', 'number',
    ],
}

const getSchema = () => {
    return {
        status: Yup.number(),
        message: Yup.string().when(['status', 'current_status'], (status, schema) => {
            return (status[0] < status[1]) && !status[2] ? schema.required('Обязательное поле!') : schema
        }),
        project_id: Yup.number()
            .typeError('Обязательное поле!')
            .required('Обязательное поле!'),
        type: Yup.number()
            .typeError('Обязательное поле!')
            .required('Обязательное поле!'),
        patent_office_id: setRequiredField(Yup.number()
            .typeError('Обязательное поле!'), STATUS_APPROVE),
        title_ru: Yup.string().required('Обязательное поле!'),
        application_status: setRequiredField(Yup.number()
            .typeError('Обязательное поле!').nullable(), STATUS_APPROVE),
        document_type: setRequiredField(Yup.number().nullable()
            .typeError('Обязательное поле!'), STATUS_APPROVE),
        application_date: Yup.date()
            .typeError('Обязательное поле!')
            .nullable().test('application_date_test', 'Обязательное поле!',
                (value, context) => {
                    return context.parent.document_type === 1 ? !!value : true
                },
            ),
        fips_empty: Yup.boolean().nullable().test('fips_test', 'Обязательное поле!',
            (value, context) => {
                return context.parent.document_type === 1 ? !!value : true
            },
        ),
        application_number: Yup.string()
            .nullable()
            .typeError('Обязательное поле!').test('application_number_test', 'Обязательное поле!',
                (value, context) => {
                    return context.parent.document_type === 1 ? !!value : true
                },
            ),
        date: Yup.date()
            .typeError('Обязательное поле!')
            .nullable().typeError('Обязательное поле!').test('date_test', 'Обязательное поле!',
                (value, context) => {
                    return context.parent.document_type === 3 || context.parent.document_type === 2 ? !!value : true
                },
            ),
        number: Yup.string()
            .nullable()
            .typeError('Обязательное поле!').test('number_test', 'Обязательное поле!',
                (value, context) => {
                    return context.parent.document_type === 3 || context.parent.document_type === 2 ? !!value : true
                },
            ),
        registration_empty: Yup.boolean().nullable().test('registration_test', 'Обязательное поле!',
            (value, context) => {
                return context.parent.document_type === 3 ? !!value : true
            },
        ),
        patent_empty: Yup.boolean().nullable().test('patent_test', 'Обязательное поле!',
            (value, context) => {
                return context.parent.document_type === 2 ? !!value : true
            },
        ),
    }
}

export const REQUIRED_FIELDS_TEMP = {
    [STATUS_NEW]: Yup.object().shape(getSchema()),
    [STATUS_APPROVE]: Yup.object().shape(getSchema()),
    [STATUS_ACCEPT]: Yup.object().shape(getSchema()),
}


export const CAN_MANAGE_ROLES = {
    [STATUS_NEW]: [ROLES.admin, ROLES.direction_rid, ROLES.project_manager],
    [STATUS_APPROVE]: [ROLES.admin, ROLES.direction_rid],
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction],
}
