import { ROLES } from '../../../rbac/constant'
import { Input, InputDate, Select } from '../../../components/form/forms'
import * as Yup from 'yup'

export const STATUS_ACCEPT = 1             // Принято

export const STATUS_LABELS = {
    [STATUS_ACCEPT]: 'Принято',
}

export const STATUS_OPTIONS = [
    { value: STATUS_ACCEPT, label: 'Принято' },
]

export const STATUS_BADGE_CLASS = {
    [STATUS_ACCEPT]: 'badge-success',
}

export const SPACE_TYPES = [
    { value: 1, label: 'Фабрика' },
    { value: 2, label: 'Интерактивный комплекс' },
    { value: 3, label: 'Лаборатория' },
]


export const LABELS = {
    'id': 'ID',
    'status': 'Статус',
    'space_name': 'Название пространства',
    'space_type': 'Тип',

    'description': 'Описание',
    'location': 'Местоположение',
    'area': 'Площадь (кв.м.)',
    'comment': 'Комментарий',
    'opening_plan': 'План открытия',
    'order_number': 'Номер приказа',
    'order_date': 'Дата приказа',
    'order_url': 'Приказ об открытии',
    'files': 'Приказ об открытии на платформе',
    'current_information': 'Текущая информация',

    'project': 'Проект',
    'created_by': 'Создано',
    'updated_by': 'Обновлено',
    'created_at': 'Дата создания',
    'updated_at': 'Дата обновления',
    'direction_by': 'Дирекция',
    'message': 'Комментарий',
    'messages': 'Сообщения',
}


export const FIELDS = {
    id: {
        type: Input,
        content: 'number',
        props: {
            show_zero: false,
        },
    },
    status: {
        type: Select,
        options: STATUS_OPTIONS,
    },
    space_type: {
        type: Select,
        options: SPACE_TYPES,
    },
    space_name: {
        type: Input,
        content: 'text',
    },
    description: {
        type: Input,
        content: 'text',
    },
    location: {
        type: Input,
        content: 'text',
    },
    area: {
        type: Input,
        content: 'text',
    },
    comment: {
        type: Input,
        content: 'text',
    },
    opening_plan: {
        type: Input,
        content: 'text',
    },
    order_number: {
        type: Input,
        content: 'number',
    },
    order_date: {
        type: InputDate,
        content: 'date',
    },
    order_url: {
        type: Input,
        content: 'text',
    },
    project: {
        type: Select,
        source: 'project',
        key: (v) => `${v?.title_short}`,
    },


    direction_by: {
        type: Select,
        source: 'users',
        key: (v) => `${v?.fio}`,
    },
    created_by: {
        type: Select,
        source: 'users',
        key: (v) => `${v?.fio}`,
    },
    updated_by: {
        type: Select,
        source: 'users',
        key: (v) => `${v?.fio}`,
    },
    created_at: {
        type: InputDate,
        content: 'date',
    },
    updated_at: {
        type: InputDate,
        content: 'date',
    },
}

export const REQUIRED_FIELDS = {
    [STATUS_ACCEPT]: [
        'is_network',
        'event_name',
        'speciality',
        'project',
        'event_type',
        'start',
        'end',
        'partners',
        'supervisor',
        'responsible',
    ],
}

export const CAN_MANAGE_ROLES = {
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction],
}

const Schema = Yup.object().shape({
    status: Yup.number(),
    message: Yup.string().when(['status', 'current_status'], (status, schema) => {
        return (status[0] < status[1]) ? schema.required('Обязательное поле!') : schema
    }),
})

export const REQUIRED_FIELDS_TEMP = {
    [STATUS_ACCEPT]: Schema,
}
