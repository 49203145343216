import * as React from 'react'
import { useContext, useState } from 'react'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import ReactFilterTable from '../../../components/tables/react_table_filters/react_table_filters'
import { useNavigate } from 'react-router-dom'
import { EVENT_TYPE, FIELDS, LABELS, STATUS_ACCEPT, STATUS_BADGE_CLASS, STATUS_LABELS, STATUS_NEW } from './constant'
import { PERMISSIONS, ROLES } from '../../../rbac/constant'
import { AuthContext } from '../../../auth'
import { Button } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { putRequest } from '../../../actions/common'
import { Messages } from '../../../utils/utils'
import { useAbac } from 'react-abac'
import { getSelectedColor, getStatusColor, handleMoveClick, MoveButton, onSelectRow } from '../common'
import { SchoolPreview } from '../preview'

const School = () => {
    const navigate = useNavigate()
    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()

    const [selected, setSelected] = useState([])
    const [status, setStatus] = useState(null)
    const [school, setSchool] = useState(null)
    const [filtering, setFiltering] = useState(null)
    const [viewItem, setViewItem] = useState(null)
    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['id', 'status', 'event_name', 'event_type',
            'project', 'event_info', 'deadline', 'organization', 'region', 'count_pupils'],
        'Скрытые / Фиксированные колонки': [
            'month',
            'year',
            'messages',
            'updated_by',
            'created_at',
            'created_by',
            'updated_at',
            'docs_title',
        ],
    })
    const templateFileTags = JSON.stringify({ 'document_type': 'template_import', 'DB': 'school' })

    const checkField = (field, key) => {
        if (key === 'status') {
            return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
        }
        if (key === 'event_type') {
            return EVENT_TYPE.find((v) => v?.value === field)?.['label']
        }
        if (key === 'is_network') {
            return IS_NETWORK.find((v) => v?.value === field)?.['label']
        }
        if (['speciality', 'project'].includes(key) && field) {
            return <span>{field.title_short}</span>
        }
        if (key === 'messages') {
            return <Messages messages_={field} status_labels={STATUS_LABELS} source={'publication'}/>
        }

        return null
    }

    const addNewSchool = (state) => {
        putRequest('school', {
            status: state?.monitoring ? STATUS_ACCEPT : STATUS_NEW,
            project: user?.project?.id,
            is_draft: true,
        }).then((response) => {
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/school/item/${response.data.id}/?update=true`)
            }
        })
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <div className={'box'}>
                <h1 className={'page-header'}>
                    Школьники
                </h1>
                {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.project_manager)
                || user?.roles?.includes(ROLES.direction)
                    ? <React.Fragment>
                        <div className={'button-actions'}>
                            {!user?.roles?.includes(ROLES.direction)
                                ? <Button variant={'primary'} onClick={() => {
                                    addNewSchool()
                                }}>
                                    <IconText icon={'plus'} text={'Создать'}/>
                                </Button> : null}
                            {selected.length
                                ? <MoveButton
                                    status={status}
                                    variantBtn={getStatusColor(status, STATUS_ACCEPT, STATUS_NEW)}
                                    labels={STATUS_LABELS}
                                    canNext={status !== STATUS_ACCEPT}
                                    canBack={status > STATUS_NEW}
                                    isAccept={(status + 1) === STATUS_ACCEPT}
                                    handleMoveClick={(moveIndex) => handleMoveClick(moveIndex, 'school', selected, setSelected, status, setFiltering)}
                                    permissionManage={PERMISSIONS.MANAGE_SCHOOL}
                                />
                                : null}
                        </div>
                    </React.Fragment> : null}
            </div>
            {viewItem
                ? <SchoolPreview
                    itemId={viewItem}
                    onClose={() => setViewItem(null)}
                    urlUpdate={'/school/item'}
                />
                : null}
            <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                              filtering={filtering}
                              data={school} setFunc={setSchool} page={1} url={'/school'}
                              url_update={'/school/item'}
                              get_title={'school'}
                              canSelect
                              onRowClick={(el) => setViewItem(el?.id)}
                              onSelect={(ev, el) => onSelectRow(ev, el, school, setSelected, selected)}
                              colorExpression={(el) => getSelectedColor(el, selected)}
                              selectedAll={selected?.length && (school['results']?.length === selected?.length)}
                              selected={selected}
                              setStatus={setStatus}
                              checkField={checkField}
                              permission={PERMISSIONS.MANAGE_SCHOOL}
                              urlImport={'/api/niokr_import_request/'}
                              templateFile={'import_school_template.xlsx'}
                              templateFileTags={templateFileTags}
                              fields={FIELDS}
            />
        </React.Fragment>
    )
}

export default School
