import React, { useState } from 'react'
import ReactBreadcrumb from '../components/breadcrumbs'
import { Field, FormikProvider, useFormik } from 'formik'
import { FormikSelect } from '../components/form/formik'
import { REPORT_YEAR } from './database/rid/constant'
import { Button, Col, Row } from 'react-bootstrap'
import { IconText } from '../components/icon_txt'
import { MyFileBrowser } from '../components/file_manager/file_manager'

const FileManager = () => {
    const today = new Date()
    const [path, setPath] = useState('root/storage')
    const [instanceId, setInstanceId] = useState('storage')
    const [documentsReport, setDocumentsReport] = useState(false)

    const formik = useFormik({
        initialValues: {
            year: today.getFullYear(),
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            return true
        },
    })

    return (<>
            <ReactBreadcrumb/>
            <h1 className={'page-header'}>
                Файловый менеджер
            </h1>
            <FormikProvider value={formik}>
                <Row>
                    <Col md={6}>
                        <div className='button-actions mb-3'>
                            <Button variant={'info'}
                                    className={'mb-3'}
                                    onClick={() => {
                                        setPath('root/storage')
                                        setInstanceId('storage')
                                        setDocumentsReport(false)
                                    }}
                            >
                                <IconText icon={'book'} text={'Хранилище документов'}/>
                            </Button>

                            <Button variant={'info'}
                                    className={'mb-3'}
                                    onClick={() => {
                                        setPath('root/virtual-storage')
                                        setInstanceId('virtual_storage')
                                        setDocumentsReport(true)
                                    }}
                            >
                                <IconText icon={'book'} text={'Документы для отчета'}/>
                            </Button>
                        </div>
                    </Col>
                    <Col md={6}>
                        <Field component={FormikSelect}
                               name={'year'} label={'Год зачета'}
                               id={'year'}
                               isSearchable={true}
                               isClearable={true}
                               options={REPORT_YEAR}
                        />
                    </Col>
                </Row>
            </FormikProvider>

        <MyFileBrowser
            height={500}
            path={path}
            read_only={false}
            year={formik.values.year}
            can_upload={true}
            can_download={true}
            instanceId={instanceId}
            documentsReport={documentsReport}
            showPreloader={true}
        />

        </>
    )
}

export default FileManager
