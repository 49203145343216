import { ROLES } from '../../../rbac/constant'
import { Input, InputDate, Select } from '../../../components/form/forms'
import * as Yup from 'yup'
import { setRequiredField } from '../../../utils/utils'
import { specialistNotFound } from './item'

export const STATUS_DRAFT = -1             // Черновик
export const STATUS_NEW = 1                // Новая сущность в БД
export const STATUS_APPROVE = 2            // Рассматривается Дирекцией
export const STATUS_ACCEPT = 3             // Принято
export const STATUS_TEMP = 4

export const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
]

export const STATUS_LABELS = {
    [STATUS_NEW]: 'Новый',
    [STATUS_APPROVE]: 'Рассматривается',
    [STATUS_ACCEPT]: 'Принято',
}

export const STATUS_OPTIONS = [
    { value: STATUS_NEW, label: 'Новый' },
    { value: STATUS_APPROVE, label: 'Рассматривается' },
    { value: STATUS_ACCEPT, label: 'Принято' },
]

export const STATUS_BADGE_CLASS = {
    [STATUS_NEW]: 'badge-warning',
    [STATUS_APPROVE]: 'badge-info',
    [STATUS_ACCEPT]: 'badge-success',
}

export const EVENT_TYPE = [
    { value: 1, label: 'ДПО повышение квалификации' },
    { value: 2, label: 'ДПО профессиональная переподготовка' },
    { value: 3, label: 'Бакалавриат' },
    { value: 4, label: 'Магистратура' },
    { value: 5, label: 'Специалитет' },
    { value: 6, label: 'Аспирантура' },
]

export const ORGANIZATION_TYPE = [
    { value: 1, label: 'Компания' },
    { value: 2, label: 'Компания со специальным налоговым режимом' },
    { value: 3, label: 'ИП' },
]

export const ERROR_LABELS = {
    'specialist_common': 'Специалист',
    'specialist': 'Наименование образовательной программы',
}

export const LABELS = {
    'id': 'ID',
    'status': 'Статус',

    'organization': 'Наименование организации, в которой трудоустроен специалист',
    'employment_date': 'Дата трудоустройства',
    'specialist_fio': 'ФИО подготовленного специалиста',
    'event': 'Наименование образовательной программы',
    'project': 'Проект',
    'month': 'Месяц включения в отчет',
    'year': 'Год включения в отчет',
    'enrollment_order': 'Приказ о зачислении',
    'docs_link': 'Ссылка на документы',
    'organization_type': 'Характеристика организации, в которой трудоустроен специалист',
    'edu_doc_number': 'Номер документа об образовании',
    'edu_doc_date': 'Дата документа об образовании',

    'created_by': 'Создано пользователем',
    'updated_by': 'Обновлено пользователем',
    'created_at': 'Дата создания',
    'updated_at': 'Дата обновления',
    'direction_by': 'Дирекция',
    'message': 'Комментарий',
    'messages': 'Сообщения',
}

export const FIELDS = {
    id: {
        type: Input,
        content: 'number',
        props: {
            show_zero: false,
        },
    },
    status: {
        type: Select,
        options: STATUS_OPTIONS,
    },

    organization: {
        type: Input,
        content: 'text',
    },
    employment_date: {
        type: InputDate,
        content: 'date',
    },
    specialist_fio: {
        type: Input,
        content: 'text',
    },
    event: {
        type: Select,
        source: 'event',
    },
    project: {
        type: Select,
        source: 'project',
        key: (v) => v?.title_short,
    },
    month: {
        type: Select,
        options: MONTHS.map((v, i) => {
            return { value: i + 1, label: v }
        }),
    },
    year: {
        type: Input,
        content: 'number',
    },
    enrollment_order: {
        type: Input,
        content: 'text',
    },
    docs_link: {
        type: Input,
        content: 'text',
    },
    organization_type: {
        type: Select,
        options: ORGANIZATION_TYPE,
    },

    direction_by: {
        type: Select,
        source: 'users',
        key: (v) => `${v?.fio}`,
    },
    created_by: {
        type: Select,
        source: 'users',
        key: (v) => `${v?.fio}`,
    },
    updated_by: {
        type: Select,
        source: 'users',
        key: (v) => `${v?.fio}`,
    },
    created_at: {
        type: InputDate,
        content: 'date',
    },
    updated_at: {
        type: InputDate,
        content: 'date',
    },
}

export const REQUIRED_FIELDS = {
    [STATUS_NEW]: [
        'organization',
        'employment_date',
        'month',
        'year',
        'specialist_fio',
        'event',
    ],
    [STATUS_APPROVE]: [
        'organization',
        'employment_date',
        'month',
        'year',
        'specialist_fio',
        'event',
        'project',
    ],
    [STATUS_ACCEPT]: [
        'organization',
        'employment_date',
        'month',
        'year',
        'specialist_fio',
        'event',
        'project',
    ],
}

export const CAN_MANAGE_ROLES = {
    [STATUS_NEW]: [ROLES.admin, ROLES.project_manager],
    [STATUS_APPROVE]: [ROLES.admin, ROLES.direction],
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction],
}


const Schema = Yup.object().shape({
    status: Yup.number(),
    message: Yup.string().when(['status', 'current_status'], (status, schema) => {
        return status[0] < status[1] ? schema.required('Обязательное поле!') : schema
    }),
    organization: Yup.string().required('Обязательное поле!').typeError('Обязательное поле!'),
    employment_date: Yup.date()
        .typeError('Обязательное поле!').nullable(),
    enrollment_order: Yup.string()
        .test('specialist-not-found', ' ',
            () => {
                return !specialistNotFound.value
            }),
    month: Yup.number().required('Обязательное поле!').typeError('Обязательное поле!'),
    year: Yup.number().required('Обязательное поле!').typeError('Обязательное поле!'),
    specialist_fio: Yup.string().required('Обязательное поле!').typeError('Обязательное поле!'),
    project_id: setRequiredField(Yup.number().typeError('Обязательное поле!'), STATUS_ACCEPT),
})


export const REQUIRED_FIELDS_TEMP = {
    [STATUS_NEW]: Schema,
    [STATUS_APPROVE]: Schema,
    [STATUS_ACCEPT]: Schema,
}

