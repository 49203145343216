import * as React from 'react'
import DictUpdate from './common/update'
import {Check, Area, Input, Select} from '../../components/form/forms'
import {DownloadFile, UploadFile} from '../../components/file_manager/file_handlers'
import {getRequest} from '../../actions/common'
import {useEffect, useState} from 'react'

export const AcademicDegreeUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'academic_degree'}
            />
        </>
    )
}


export const AcademicRankUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'academic_rank'}
            />
        </>
    )
}


export const CountryUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'country'}
            />
        </>
    )
}

// export const DepartamentUpdate = () => {
//     const fields = [
//         'id', 'title', 'title_short', 'parent_id', 'is_active'
//     ];
//     const labels = {
//         'id': 'ID',
//         'title': 'Заголовок',
//         'title_short': 'Аббревиатура',
//         'sort_index': 'Порядковый №',
//         'ref_id': 'Внешняя ссылка',
//         'parent_id': 'ID родителя',
//         'is_active': 'Активно?',
//         'data_create': 'Дата открытия',
//         'data_close': 'Дата закрытия'
//     };
//
//     return (
//         <>
//             <DictUpdate labels={labels}  fields={fields}
//                   dict_title={'departament'}
//             />
//         </>
//     );
// }

export const EventCategoryUpdate = () => {
    const fields = [
        'title', 'ref_id', 'sort_index',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'event_category'}
            />
        </>
    )
}

export const EventsUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
        'category_id': 'Категория',
        'project_id': 'Проект',
        'is_educational': 'Образовательное',
    }

    const [event_category, setEventCategory] = useState(null)
    const [projects, setProjects] = useState(null)

    useEffect(() => {
        if (!event_category) {
            getRequest('event_category', setEventCategory, { type_data: 'all' })
        }
    }, [event_category])

    useEffect(() => {
        if (!projects) {
            getRequest('project', setProjects, { type_data: 'all' })
        }
    }, [projects])

    let custom_fields = {
        category_id: Select,
        project_id: Select,
        is_educational: Check,
    }


    let select_options = {
        category_id: event_category ? event_category.map((v) => {
            return { value: v.id, label: v.title }
        }) : null,
        project_id: projects ? projects.map((v) => {
            return { value: v.id, label: v.title }
        }) : null,
    }

    return (
        <>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'event'}
                        custom_fields={custom_fields}
                        select_options={select_options}
            />
        </>
    )
}

export const JobContractUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'job_contract'}
            />
        </>
    )
}

export const OrganizationUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Наименование заказчика (контрагента)',
        'title_short': 'ИНН заказчика(контрагента)',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'organization'}
            />
        </>
    )
}

export const PatentOfficeUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'patent_office'}
            />
        </>
    )
}

export const ProjectUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'project'}
            />
        </>
    )
}

export const SelfAcademicDegreeUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'self_academic_degree'}
            />
        </>
    )
}

export const SelfAcademicRankUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'self_academic_rank'}
            />
        </>
    )
}

export const StaffCategoryUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'staff_category'}
            />
        </>
    )
}

export const StaffPositionUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'staff_position'}
            />
        </>
    )
}

export const StaffUnitUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'staff_unit'}
            />
        </>
    )
}

export const UnitUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'unit'}
            />
        </>
    )
}

export const DeadlinesOopUpdate = () => {
    const labels = {
        'id': 'ID',
        'title': 'Наименование этапа',
        'date': 'Срок загрузки документа',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    let custom_fields = {
        title: Input,
        date: Input,
        sort_index: Input,
        ref_id: Input,
    }

    let custom_props = {
        date: { type: 'date' },
    }
    return (
        <>
            <DictUpdate labels={labels}
                        dict_title={'deadlines_oop'}
                        custom_fields={custom_fields}
                        custom_props={custom_props}
            />
        </>
    )
}

export const SpecialityUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Направление подготовки',
        'title_short': 'Специальность и направление подготовки',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'speciality'}
            />
        </>
    )
}

export const OrderUpdate = () => {
    const labels = {
        'number': 'Номер приказа',
        'date': 'Дата приказа',
        'description': 'Описание',
        'category': 'Категория',
    }

    let custom_fields = {
        number: Input,
        date: Input,
        category: Select,
        description: Area,
        filename: DownloadFile,
        new_file: UploadFile,
    }

    let select_fields = ['category']

    let custom_props = {
        date: { type: 'date' },
    }

    let select_options = {
        category: [
            { value: 3, label: 'Не задано' },
            { value: 1, label: 'Зачисление на обучение' },
            { value: 2, label: 'Рабочая группа' },
        ],

    }

    return (
        <>
            <DictUpdate labels={labels}
                        custom_fields={custom_fields}
                        custom_props={custom_props}
                        dict_title={'order'}
                        select_fields={select_fields}
                        select_options={select_options}
                        header={'title'}
            />
        </>
    )
}

export const TemplateUpdate = () => {
    const labels = {
        'title': 'Заголовок',
        'description': 'Описание',
    }

    let custom_fields = {
        title: Input,
        description: Area,
        filename: DownloadFile,
        new_file: UploadFile,
    }

    return (
        <>
            <DictUpdate custom_fields={custom_fields}
                        labels={labels}
                        dict_title={'template'}
                        header={'title'}
                        additional_props={{ 'replaceFile': true }}
            />
        </>
    )
}


export const TemplateOopUpdate = () => {
    const [custom_props, setCustomProps] = useState({
        title: {disabled: true},
    })
    const labels = {
        'title': 'Заголовок',
    }

    let custom_fields = {
        title: Input,
        filename: DownloadFile,
        new_file: UploadFile,
    }

    const checkData = (data) => {
        if (data?.results?.[0]?.files?.length >= 5) {
            setCustomProps({
                ...custom_props,
                new_file: {
                    disabled: true,
                    small_text: 'Вы добавили максимальное количество файлов'
                }
            })
        }
        return null
    }

    return (
        <>
            <DictUpdate custom_fields={custom_fields}
                        labels={labels}
                        custom_props={custom_props}
                        checkData={checkData}
                        dict_title={'template_oop'}
                        header={'title'}
            />
        </>
    )
}

export const SchoolEventUpdate = () => {
    const fields = [
        'title', 'variants', 'sort_index',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Тип мероприятия',
        'variants': 'Варианты мероприятий',
        'sort_index': 'Порядковый №',
    }

    const handleSave = (payload) => {
        if (payload?.common?.variants) {
            payload.common.variants = payload.common.variants.split(',')
        }
    }

    return (
        <>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'school_event'}
                        handleSave={handleSave}
            />
        </>
    )
}
