import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Badge, Button, Card, Col, Row } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getRequest, postRequest } from '../../../actions/common'
import ReactTable from '../../../components/tables/react_table'
import { useAbac } from 'react-abac'
import { PERMISSIONS, ROLES } from '../../../rbac/constant'
import { Input } from '../../../components/form/forms'
import { Link } from 'react-router-dom'
import { getValueByKey } from '../../../utils/utils'
import { Field, FormikProvider, useFormik } from 'formik'
import { FormikSelect } from '../../../components/form/formik'
import { AuthContext } from '../../../auth'
import { MONTHS, REPORT_YEAR } from '../../database/rid/constant'


const Indicator = () => {
    const { userHasPermissions } = useAbac()
    const today = new Date()

    const user = useContext(AuthContext)
    const formik = useFormik({
        initialValues: {
            ref_id_int: null,
            title: null,
            description: null,
            unit: null,
        },
        enableReinitialize: true,
        onSubmit: (values) => onSubmitForm(values),
    })
    const formikPeriod = useFormik({
        initialValues: {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
        },
        enableReinitialize: true,
        onSubmit: (values) => handleRecalculateIndicators(values),
    })
    const [indicators, setIndicators] = useState(null)
    const [indicators_current, setIndicatorsCurrent] = useState(null)
    const [settings, setSettings] = useState(null)
    const [units, setUnits] = useState(null)

    const labels = {
        'ref_id': 'ID',
        'abbreviation': 'Показатель',
        'title_short': 'Сокращенное наименование',
        'title': 'Характеристика (показатель, необходимый для достижения результатов предоставления гранта)',
        'unit': 'Единица измерения',
        'value': `Факт ${formikPeriod.values.year}`,
        'value_plan': `Договор ${formikPeriod.values.year}`,
        'value_passport': `Паспорт ${formikPeriod.values.year}`,
        'value_accumulated': 'Факт нарастающим',
        'value_plan_accumulated': 'План нарастающим',
    }

    useEffect(() => {
        document.title = 'Показатели | ИС «ПИШ»'
    })

    useEffect(() => {
        if (!indicators) {
            getRequest('indicator', setIndicators)
        }
    }, [indicators])

    useEffect(() => {
        if (!indicators_current) {
            getRequest('indicator_current', setIndicatorsCurrent)
        }
    }, [indicators_current])

    useEffect(() => {
        if (!settings) {
            getRequest('settings', setSettings, { type_data: 'all' })
        }
    }, [settings])

    // useEffect(() => {
    //     let last_recalculate = Number(localStorage.getItem('last_recalculate_indicators'));
    //
    //     if ((!indicators_current || ((Date.now() - last_recalculate) / (1000 * 3600 * 24)) > 1)
    //         && getValueByKey(settings, 'auto_calculate', 'value') === 'True') {
    //         let current_date = new Date();
    //         let current_date_string = `${current_date.getFullYear()}-${current_date.getMonth() + 1}-${current_date.getUTCDate()}`;
    //         let prev_date_string = `${current_date.getFullYear()}-${current_date.getMonth() + 1}-${current_date.getUTCDate() - 1}`;
    //         localStorage.setItem('last_recalculate_indicators', String(Date.now()))
    //         postRequest('recalculate_indicators', setIndicatorsCurrent, {
    //             start_date: prev_date_string,
    //             end_date: current_date_string
    //         })
    //     } else if (!localStorage.getItem('indicators_current') && indicators_current) {
    //         localStorage.setItem('indicators_current', JSON.stringify(indicators_current));
    //     }
    // }, [indicators_current, settings]);

    useEffect(() => {
        if (!units) {
            getRequest('unit', setUnits)
        }
    }, [units])

    const btnFilter = () => {
        let card = document.getElementById('card-filter')
        if (card && card.style.display === 'none') {
            card.style.display = 'block'
        } else if (card && card.style.display === 'block') {
            card.style.display = 'none'
        }
    }

    const refreshFilter = () => {
        formik.setValues({
            id: '',
            title: '',
            description: '',
            unit: '',
        })
        formik.submitForm().then()
    }

    const onSubmitForm = (values) => {
        getRequest('indicator', setIndicators, values)
        getRequest('indicator_current', setIndicatorsCurrent, values)
    }

    const handleRecalculateIndicators = (v) => {
        localStorage.removeItem('indicators')
        postRequest('recalculate_indicators', setIndicatorsCurrent, { ...v }, '', true, true).then()
    }


    const getUnitsOptions = () => {
        let options = []

        if (units !== null && units !== undefined) {
            for (let i = 0; i < units.results.length; i++) {
                if (units.results[i]) {
                    options.push({ value: units.results[i].id, label: units.results[i].title })
                }
            }
        }
        return options
    }

    const BadgeList = () => {
        let report_year = null
        let auto_calc = null
        if (settings) {
            report_year = getValueByKey(settings, 'report_year', 'value')
            auto_calc = getValueByKey(settings, 'auto_calculate', 'value')
        }
        return <ul className={'list-unstyled'}>
            <li><b>Отчетный год</b>: <Badge bg='secondary'
                                            style={{ color: 'white' }}>
                {report_year}
            </Badge>
            </li>
            <li><b>Автоматический расчет</b>:
                <Badge bg={`${auto_calc === 'True' ? 'success' : 'danger'}`} style={{ color: 'white' }}>
                    {auto_calc === 'True' ? 'Включено' : 'Отключено'}
                </Badge>
            </li>
        </ul>
    }


    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={'page-header'}>
                Показатели
                {userHasPermissions(PERMISSIONS.ADMIN_PANEL)
                    ? <Link to={'/indicator/settings'}>
                        <Badge bg='secondary' style={{ fontSize: 16 + 'px', color: 'white' }}
                               className={'ml-2'}>
                            <FontAwesomeIcon icon={'cog'}/>
                        </Badge>
                    </Link> : null
                }
            </h1>
            {userHasPermissions(PERMISSIONS.ADMIN_PANEL)
                ? <BadgeList/>
                : null}
            <FormikProvider value={formikPeriod}>
                <Row style={{ zIndex: 2000, position: 'relative' }}>
                    <Col md={6}>
                        <Field component={FormikSelect}
                               name={'month'} label={'Месяц зачета'}
                               id={'month'}
                               isSearchable={true}
                               isClearable={true}
                               options={MONTHS.map((v, i) => {
                                   return { value: i + 1, label: v }
                               })}
                               error={formikPeriod.errors['month_id']}
                               invalid={formikPeriod.errors['month_id']}
                        />
                    </Col>
                    <Col md={6}>
                        <Field component={FormikSelect}
                               name={'year'} label={'Год зачета'}
                               id={'year'}
                               isSearchable={true}
                               isClearable={true}
                               options={REPORT_YEAR}
                               error={formikPeriod.errors['year_id']}
                               invalid={formikPeriod.errors['year_id']}
                        />
                    </Col>
                </Row>
            </FormikProvider>
            {userHasPermissions(PERMISSIONS.MANAGE_COMMON)
                ? <div className='button-actions mb-3'>
                    <Button variant='secondary' onClick={btnFilter}>
                        <IconText text={'Форма поиска'} icon={'search'}/>
                    </Button>
                    {userHasPermissions(PERMISSIONS.ADMIN_PANEL)
                        ? <Link to={'/indicator/create'}>
                            <Button variant='success'>
                                <IconText text={'Создать'} icon={'plus'}/>
                            </Button>
                        </Link>
                        : null}
                    <Button variant='warning' onClick={() => formikPeriod.submitForm()}>
                        <IconText text={'Пересчитать'} icon={'calculator'}/>
                    </Button>
                </div> : null
            }
            <div className='button-actions mb-3'>
                <Link to={'/indicator-project'}>
                    <Button variant='info'>
                        <IconText text={'Показатели по проектам'} icon={'project-diagram'}/>
                    </Button>
                </Link>
            </div>
            <Card style={{ display: 'none' }} id='card-filter'>
                <form onSubmit={onSubmitForm}>
                    <Card.Body>
                        <FormikProvider value={formik}>
                            <Row className='row-cols-1 row-cols-md-2 row-cols-lg-3'>
                                <Col>
                                    <Input label={'ID'}
                                           name={'id'}
                                           value={formik.values.id}
                                           handleChangeValue={formik.handleChange}
                                    />
                                </Col>
                                <Col>
                                    <Input label={'Название'}
                                           name={'title'}
                                           value={formik.values.title}
                                           handleChangeValue={formik.handleChange}
                                    />
                                </Col>
                                <Col>
                                    <Input label={'Описание'}
                                           name={'description'}
                                           value={formik.values.description}
                                           handleChangeValue={formik.handleChange}
                                    />
                                </Col>
                                <Col>
                                    <Field component={FormikSelect}
                                           name={'unit'}
                                           label={'Единицы измерения'}
                                           id={'unit'}
                                           isClearable={true}
                                           options={getUnitsOptions()}
                                    />
                                </Col>

                            </Row>
                        </FormikProvider>

                    </Card.Body>
                    <Card.Footer>
                        <div className={'button-actions'}>
                            <Button onClick={() => formik.submitForm()}>Найти</Button>
                            <Button variant='secondary' onClick={() => refreshFilter()}>Сбросить</Button>
                        </div>
                    </Card.Footer>
                </form>
            </Card>
            <legend>Текущие значения показателей</legend>

            <ReactTable
                stickyHeader
                data={indicators_current} labels={labels} bordered={true}
                striped={true} hover={true} setFunc={setIndicators} sort={true}
                show_result={true}
                colorExpression={(element) => element?.parent_id ? 'rgba(61,7,255,0.24)' : ''}
                pageSize={60}
                is_update={false}
                is_see_field={false}
                get_title={'indicator'}
                url_update={'/indicator/update'}
                url_field={'/indicator'}
            />

            <legend>Значения показателей за отчетный год</legend>
            <ReactTable
                stickyHeader
                data={indicators} labels={labels} bordered={true}
                striped={true} hover={true} setFunc={setIndicators} sort={true}
                pageSize={60}
                get_title={'indicator'}
                url_update={'/indicator/update'}
                colorExpression={(element) => element?.parent_id ? 'rgba(61,7,255,0.24)' : ''}
                is_update={!user?.roles?.includes(ROLES.management)}
                url_field={'/indicator'}
            />
        </React.Fragment>
    )
}

export default Indicator
