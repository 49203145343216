export const ShowResults = ({ data, page, pageLimit }) => {
    if (data) {
        let page_ = page ? page : 1
        let mult = data.results.length < pageLimit
            ? data.count - data.results.length
            : pageLimit
        let to = data.results.length < pageLimit ? data.count : (page_ * mult)
        let from = data.results.length < pageLimit
            ? data.count - data.results.length + 1 : to - mult + 1
        return (
            <div className='summary'>
                Показаны {from} - {to} из {data ? data.count : null} записи.
            </div>
        )
    }
    return null
}
