import * as React from 'react'
import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Accordion, Button, Card, Col, Form, Row } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { getRequest, putRequest, updateRequest } from '../../../actions/common'
import { REQUIRED_FIELDS_TEMP, SPACE_TYPES } from './constant'
import { Area, Input } from '../../../components/form/forms'
import { PERMISSIONS } from '../../../rbac/constant'
import { btnMessage } from '../../../utils/utils'
import { MyFileBrowser } from '../../../components/file_manager/file_manager'
import { Field, FormikProvider, useFormik } from 'formik'
import { FormikDate, FormikSelect } from '../../../components/form/formik'
import { CurrentInformation } from '../../../components/databases/educational_spaces/current_information'

export const EducationalSpacesItem = ({
                                          data,
                                          user,
                                          disabled,
                                          messages,
                                          generateOptions,
                                          ErrorsBlock,
                                          MessageHistory,
                                          ActionButtons,
                                          DisableButton,
                                          HeaderStatus,
                                          handleSubmit,
                                          setData,
                                          MainBlock,
                                          FileBlock,
                                          ToggleButtons,
                                          EditButton,
                                      }) => {
    const params = useParams()
    const navigate = useNavigate()
    const [showMainInfo, setShowMainInfo] = useState(true)

    const [select_data, setSelectData] = useState({})

    const select_fields = ['project']
    const given_select_fields = ['space_type']

    const [input_fields, setInputFields] = useState({
        'status': 1,
        'current_status': 1,
        'save_form': false,
        'space_name': '',
        'project_id': null,
        'space_type': null,
        'description': undefined,
        'location': undefined,
        'area': undefined,
        'comment': undefined,
        'opening_plan': undefined,
        'order_number': undefined,
        'order_date': undefined,
        'order_url': undefined,
        'current_information': [],
        'current_results': [],
    })

    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: (values) => {
            return getRequest('educational_spaces', () => null,
                {}, params.id, null, null, true)
                .then((r) => {
                    if (r.data?.results?.[0]?.updated_time !== data?.updated_time) {
                        formik.setFieldError('status', 'Данные устарели! Обновите страницу.')
                        return 'is_canceled'
                    } else {
                        let temp = { ...formik.errors }
                        delete temp?.status
                        formik.setErrors(temp)
                        return saveFunction()
                    }
                })
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })

    useEffect(() => {
        handleSubmit(formik, saveFunction)
    }, [formik.values])


    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${data?.id} в БД Образовательные пространства | ИС «ПИШ»`

            let set_selected = {}
            select_fields.forEach((field) => {
                set_selected[`${field}_id`] = data[field]?.id
            })
            given_select_fields.forEach((field) => {
                set_selected[field] = data[field]
            })
            let set_inputs = {}
            for (let field in input_fields) {
                set_inputs[field] = data[field]
            }

            formik.setValues({
                ...set_inputs,
                ...set_selected,
                current_status: data.status,
            })
        }
    }, [data])

    useEffect(() => {
        if (data) {
            if (Object.keys(select_data).length < select_fields.length) {
                for (let field of ['project']) {
                    if (!Object.keys(select_data).includes(field)) {
                        getRequest(field, setSelectData, { type_data: 'all' }, '', select_data, field)
                        break
                    }
                }
            }
        }
    }, [data, select_data, formik.values])

    const saveFunction = () => {
        formik.setFieldValue('save_form', false)

        let payload = { ...formik.values, direction_by: user.id }

        updateRequest('educational_spaces', { 'common': payload }, params.id).then((response) => {
            if (response.status === 200) {
                if (payload?.status !== data?.status)
                    navigate('/educational_spaces')
                setData(response.data)
            }
        })
        if (formik?.values?.message) {
            putRequest('educational_spaces_messages', {
                content: formik?.values?.message,
                educational_spaces_status: data.status,
                status: data.status,
                created_by: user.id,
                educational_spaces: data.id,
            }, false).then()
            document.getElementById('message').value = null
            formik.setFieldValue('message', '')
        }

        return Promise.resolve('is_saved')
    }

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <ReactBreadcrumb/>
                <Form>
                    <div className='box'>
                        <div className={`d-flex align-items-center ${disabled ? 'justify-content-between' : ''}`}>
                            <h1 className='page-header d-flex'>
                                {`№${data?.id} в БД Образовательные пространства`}
                            </h1>
                            <DisableButton/>
                        </div>
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <Button variant={'outline-primary'} className={'flex-shrink-0 mt-1 mr-2'} size={'md'}
                                    onClick={btnMessage}>
                                <IconText icon={'comment'}
                                          text={disabled ? 'Сообщения' : ''}/>
                            </Button>
                            <EditButton/>
                            <ActionButtons form={formik}
                                           permission_control={PERMISSIONS.DIRECTION_PANEL}
                                           permission_manage={PERMISSIONS.DIRECTION_PANEL}
                                           can_next={false}
                                           can_back={false}
                            />
                        </div>
                    </div>
                    <Card className={'bg-white mt-4 mb-4'}
                          style={messages && messages?.length !== 0 || formik.errors?.message ? { display: 'block' } : { display: 'none' }}
                          id={'card-message'}>
                        <Card.Body>
                            <div className='alert alert-danger'
                                 style={{ display: formik.errors?.message ? 'block' : 'none' }}>
                                <p>Исправьте следующие ошибки:</p>
                                <ul>
                                    <li>Необходимо заполнить: "Комментарий"</li>
                                </ul>
                            </div>
                            <label>История сообщений</label>
                            <MessageHistory/>
                            <div id='field-mention' className='content-group navbar-nav px-0'>
                                <div id='field-comment'>
                                    <label htmlFor='message'>Комментарий</label>
                                    <Area id='message'
                                          name={'message'}
                                          value={formik.values?.message}
                                          disabled={disabled}
                                          rows='5'
                                          error={formik.errors['message']}
                                          invalid={formik.errors['message']}
                                          handleChangeValue={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Accordion>
                        <Accordion.Item eventKey='0'>
                            <Accordion.Button className={'accordion-head'}>Дополнительная информация</Accordion.Button>
                            <Accordion.Body className={'accordion-body'}>
                                <p>
                                    <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте
                                    следующие
                                    условные обозначения,
                                    используемые в интерфейсе:
                                </p>
                                <ul>
                                    <li>поля помеченные <strong className='text-danger'>красной
                                        «*»</strong> являются <strong>обязательными
                                        на текущем
                                        этапе для перехода на следующий</strong>;
                                    </li>
                                    <li>поля помеченные <strong className='text-success'>зелёной
                                        «*»</strong> являются <strong>обязательными
                                        для
                                        определенных условий</strong>;
                                    </li>
                                    <li>кнопка <span className='badge badge-success'>Сохранить</span> выполняет
                                        сохранение
                                        текущего состояния карточки (требуется
                                        заполнения ключевых полей);
                                    </li>
                                    <li>кнопка <span
                                        className='badge badge-success'>Сохранить + отправить далее</span> сохраняет
                                        карточку и
                                        проверяет выполнение
                                        условий текущего этапа (при выполнении условий карточка переходит на следующий
                                        этап).
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Card className={'bg-white mb-3 mt-3'}>
                        <Card.Body>
                            <ToggleButtons/>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <MainBlock>
                                <fieldset>
                                    <Row>
                                        <Col md={2}>
                                            <legend>Основные данные</legend>
                                        </Col>
                                        <Col>
                                            <Button variant={'primary'}
                                                    onClick={() => setShowMainInfo(!showMainInfo)}>
                                                {showMainInfo ? 'Скрыть' : 'Показать'}
                                            </Button>
                                        </Col>
                                    </Row>
                                    {showMainInfo ? (<>

                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Название пространства'}
                                               id={'space_name'}
                                               name={'space_name'}
                                               error={formik.errors['space_name']}
                                               invalid={formik.errors['space_name']}
                                               disabled={disabled}
                                               value={formik.values.space_name}
                                        />
                                        <Area id={'description'}
                                              name={'description'}
                                              label={'Описание'}
                                              value={formik.values?.description}
                                              disabled={disabled}
                                              rows='5'
                                              error={formik.errors['description']}
                                              invalid={formik.errors['description']}
                                              handleChangeValue={formik.handleChange}
                                        />
                                        <Row>
                                            <Col>
                                                <Field component={FormikSelect}
                                                       name={'space_type'}
                                                       label={'Тип'}
                                                       isClearable={true}
                                                       options={SPACE_TYPES}
                                                       id={'space_type'}
                                                       error={formik.errors['space_type_id']}
                                                       invalid={formik.errors['space_type_id']}
                                                       disabled={disabled}
                                                />
                                            </Col>
                                            <Col>
                                                <Input handleChangeValue={formik.handleChange}
                                                       label={'Местоположение'}
                                                       id={'location'}
                                                       name={'location'}
                                                       error={formik.errors['location']}
                                                       invalid={formik.errors['location']}
                                                       disabled={disabled}
                                                       value={formik.values.location}
                                                />
                                            </Col>
                                            <Col>
                                                <Input handleChangeValue={formik.handleChange}
                                                       label={'Площадь (кв.м.)'}
                                                       id={'area'}
                                                       name={'area'}
                                                       type={'number'}
                                                       error={formik.errors['area']}
                                                       invalid={formik.errors['area']}
                                                       disabled={disabled}
                                                       value={formik.values.area}
                                                />
                                            </Col>
                                            <Col>
                                                <Field component={FormikSelect}
                                                       name={'project_id'}
                                                       label={'Проект'}
                                                       id={'project_id'}
                                                       isSearchable={true}
                                                       isClearable={true}
                                                       options={generateOptions('project', 'title_short', select_data)}
                                                       error={formik.errors['project']}
                                                       invalid={formik.errors['project']}
                                                       disabled={disabled}
                                                />
                                            </Col>
                                        </Row>
                                        <fieldset>
                                            <legend>Открытие</legend>
                                            <Input handleChangeValue={formik.handleChange}
                                                   label={'План открытия'}
                                                   id={'opening_plan'}
                                                   name={'opening_plan'}
                                                   small={'`гггг` или `гггг-гггг`'}
                                                   error={formik.errors['opening_plan']}
                                                   invalid={formik.errors['opening_plan']}
                                                   disabled={disabled}
                                                   value={formik.values.opening_plan}
                                            />
                                        </fieldset>
                                        <fieldset>
                                            <legend>Реквизиты приказа об открытии</legend>
                                            <Row>
                                                <Col>
                                                    <Input handleChangeValue={formik.handleChange}
                                                           label={'Номер приказа'}
                                                           id={'order_number'}
                                                           name={'order_number'}
                                                           error={formik.errors['order_number']}
                                                           invalid={formik.errors['order_number']}
                                                           disabled={disabled}
                                                           value={formik.values.order_number}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Field component={FormikDate}
                                                           name={'order_date'}
                                                           handleChangeValue={formik.handleChange}
                                                           label={'Дата приказа'}
                                                           id={'order_date'}
                                                           disabled={disabled}
                                                           value={formik.values.order_date}
                                                           error={formik.errors['order_date']}
                                                           invalid={formik.errors['order_date']}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Input handleChangeValue={formik.handleChange}
                                                           label={'Приказ об открытии (ссылка)'}
                                                           id={'order_url'}
                                                           name={'order_url'}
                                                           error={formik.errors['order_url']}
                                                           invalid={formik.errors['order_url']}
                                                           disabled={disabled}
                                                           value={formik.values.order_url}
                                                    />
                                                </Col>
                                            </Row>
                                            <MyFileBrowser
                                                path={`root/storage/educational_spaces/educational_spaces_${params.id}/order`}
                                                instanceId={'order'}
                                                read_only={disabled}
                                                height={250}
                                            />
                                            <hr/>
                                        </fieldset>
                                        <Area id={'comment'}
                                              name={'comment'}
                                              label={'Комментарий'}
                                              value={formik.values?.comment}
                                              disabled={disabled}
                                              rows='5'
                                              error={formik.errors['comment']}
                                              invalid={formik.errors['comment']}
                                              handleChangeValue={formik.handleChange}
                                        />
                                    </>) : null}
                                    <hr/>
                                </fieldset>
                                <CurrentInformation formik={formik} id={params.id}/>
                            </MainBlock>
                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    )
}
