import { ROLES } from '../../../rbac/constant'
import { Input, InputDate, Select } from '../../../components/form/forms'
import * as Yup from 'yup'

export const STATUS_DRAFT = -1             // Черновик
export const STATUS_NEW = 1                // Новая сущность в БД
export const STATUS_APPROVE = 2            // Рассматривается Дирекцией
export const STATUS_UPLOAD = 3            // Обрабатывается Дирекцией
export const STATUS_PROCESS = 4            // Обрабатывается Дирекцией
export const STATUS_ACCEPT = 5             // Принято
export const STATUS_TEMP = 6               // Черновик


export const STATUS_LABELS = {
    [STATUS_NEW]: 'Новый',
    [STATUS_APPROVE]: 'Рассматривается',
    [STATUS_UPLOAD]: 'Загрузка отчетов по практике',
    [STATUS_PROCESS]: 'Проверка отчетов',
    [STATUS_ACCEPT]: 'Принято',
}

export const STATUS_OPTIONS = [
    { value: STATUS_NEW, label: 'Новый' },
    { value: STATUS_APPROVE, label: 'Рассматривается' },
    { value: STATUS_UPLOAD, label: 'Загрузка отчетов по практике' },
    { value: STATUS_PROCESS, label: 'Проверка отчетов' },
    { value: STATUS_ACCEPT, label: 'Принято' },
]

export const INTERNSHIP_TYPE_OPTIONS = [
    { value: 1, label: 'С грантом' },
    { value: 2, label: 'Без гранта' },
]


export const STATUS_BADGE_CLASS = {
    [STATUS_NEW]: 'badge-warning',
    [STATUS_APPROVE]: 'badge-info',
    [STATUS_UPLOAD]: 'badge-warning',
    [STATUS_PROCESS]: 'badge-primary',
    [STATUS_ACCEPT]: 'badge-success',
}

export const LABELS = {
    'id': 'ID',
    'status': 'Статус',
    'order_number': 'Номер',
    'order_date': 'Дата',
    'order_type': 'Тип стажировки',
    'project': 'Проект',

    'partner_name': 'Наименование договора с партнером',
    'partner_number': 'Номер договора с партнером',
    'partner_date': 'Дата договора с партнером',

    'protocol_number': 'Номер протокола',
    'protocol_date': 'Дата протокола',

    'students': 'Список студентов',

    'internship_number': 'Номер приказа о направлении на стажировку',
    'internship_date': 'Дата приказа о направлении на стажировку',

    'scholarships_number': 'Номер приказа о выплате стипендии ',
    'scholarships_date': 'Дата приказа о выплате стипендии ',

    'direction': 'Дирекция',
    'updated_by': 'Обновлено пользователем',
    'created_at': 'Дата создания',
    'updated_at': 'Дата обновления',
    'created_by': 'Создано пользователем',
    'messages': 'Сообщения',
    'message': 'Комментарий',
}

export const FIELDS = {
    id: {
        type: Input,
        content: 'number',
        props: {
            show_zero: false,
        },
    },
    status: {
        type: Select,
        options: STATUS_OPTIONS,
    },
    order_number: {
        type: Input,
        content: 'text',
    },
    project: {
        type: Select,
        source: 'project',
        key: (v) => v?.title_short,
    },
    order_date: {
        type: InputDate,
        content: 'date',
    },
    order_type: {
        type: Select,
        options: INTERNSHIP_TYPE_OPTIONS,
    },

    partner_name: {
        type: Input,
        content: 'text',
    },
    partner_number: {
        type: Input,
        content: 'text',
    },
    partner_date: {
        type: InputDate,
        content: 'date',
    },

    protocol_number: {
        type: Input,
        content: 'text',
    },
    protocol_date: {
        type: InputDate,
        content: 'date',
    },

    internship_number: {
        type: Input,
        content: 'text',
    },
    internship_date: {
        type: InputDate,
        content: 'date',
    },

    scholarships_number: {
        type: Input,
        content: 'text',
    },
    scholarships_date: {
        type: InputDate,
        content: 'date',
    },

    direction: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
    created_at: {
        type: InputDate,
        content: 'date',
    },
    updated_at: {
        type: InputDate,
        content: 'date',
    },
    updated_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
    created_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
}

export const CAN_MANAGE_ROLES = {
    [STATUS_NEW]: [ROLES.admin, ROLES.project_manager],
    [STATUS_APPROVE]: [ROLES.admin, ROLES.direction, ROLES.direction_oop],
    [STATUS_UPLOAD]: [ROLES.admin, ROLES.project_manager],
    [STATUS_PROCESS]: [ROLES.admin, ROLES.direction, ROLES.direction_oop],
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction],
}
export const ONLY_UPLOAD = { [STATUS_UPLOAD]: [ROLES.project_manager] }

export const REQUIRED_FIELDS = {
    [STATUS_NEW]: [
        'project_id', 'order_number', 'order_date', 'order_type', 'partner_name', 'partner_number', 'partner_date', 'protocol_number', 'protocol_date', 'students', 'internship_number', 'internship_date', 'scholarships_number', 'scholarships_date',
    ],
    [STATUS_APPROVE]: [
        'project_id', 'order_number', 'order_date', 'order_type', 'partner_name', 'partner_number', 'partner_date', 'protocol_number', 'protocol_date', 'students', 'internship_number', 'internship_date', 'scholarships_number', 'scholarships_date',
    ],
    [STATUS_UPLOAD]: [
        'project_id', 'order_number', 'order_date', 'order_type', 'partner_name', 'partner_number', 'partner_date', 'protocol_number', 'protocol_date', 'students', 'internship_number', 'internship_date', 'scholarships_number', 'scholarships_date',
    ],
    [STATUS_PROCESS]: [
        'project_id', 'order_number', 'order_date', 'order_type', 'partner_name', 'partner_number', 'partner_date', 'protocol_number', 'protocol_date', 'students', 'internship_number', 'internship_date', 'scholarships_number', 'scholarships_date',
    ],
    [STATUS_ACCEPT]: [
        'project_id', 'order_number', 'order_date', 'order_type', 'partner_name', 'partner_number', 'partner_date', 'protocol_number', 'protocol_date', 'students', 'internship_number', 'internship_date', 'scholarships_number', 'scholarships_date',
    ],
    [STATUS_TEMP]: [
        'project_id', 'order_number', 'order_date', 'order_type', 'partner_name', 'partner_number', 'partner_date', 'protocol_number', 'protocol_date', 'students', 'internship_number', 'internship_date', 'scholarships_number', 'scholarships_date',
    ],
}

const getSchema = () => {
    return {
        status: Yup.number(),
        current_status: Yup.number(),
        message: Yup.string().when(['status', 'current_status'], (status, schema) => {
            return status[0] < status[1] ? schema.required('Обязательное поле!') : schema
        }),
        // project_id: Yup.number()
        //     .typeError('Обязательное поле!')
        //     .required('Обязательное поле!'),
        order_number: Yup.string()
            .typeError('Обязательное поле!')
            .nullable()
            .required('Обязательное поле!'),
        order_date: Yup.string()
            .nullable()
            .default(undefined).required('Обязательное поле!'),
        project_id: Yup.number()
            .typeError('Обязательное поле!')
            .required('Обязательное поле!'),
        order_type: Yup.string()
            .typeError('Обязательное поле!')
            .nullable()
            .required('Обязательное поле!'),
        partner_name: Yup.string()
            .typeError('Обязательное поле!')
            .nullable()
            .required('Обязательное поле!'),
        partner_number: Yup.string()
            .typeError('Обязательное поле!')
            .nullable()
            .required('Обязательное поле!'),
        partner_date: Yup.string()
            .nullable()
            .default(undefined).required('Обязательное поле!'),
        protocol_number: Yup.string()
            .typeError('Обязательное поле!')
            .nullable()
            .required('Обязательное поле!'),
        protocol_date: Yup.string()
            .nullable()
            .default(undefined).required('Обязательное поле!'),
        students: Yup.array(Yup.number().required('Обязательное поле!')).min(1, 'Обязательное поле!'),
        internship_number: Yup.string()
            .typeError('Обязательное поле!')
            .nullable()
            .required('Обязательное поле!'),
        internship_date: Yup.string()
            .nullable()
            .default(undefined).required('Обязательное поле!'),
        scholarships_number: Yup.string()
            .typeError('Обязательное поле!')
            .nullable()
            .when(['order_type'], (order_type, schema) => {
                return (order_type[0] == 1) ? schema?.required('Обязательное поле!') : schema
            }),
        scholarships_date: Yup.string()
            .nullable()
            .default(undefined)
            .when(['order_type'], (order_type, schema) => {
                return (order_type[0] == 1) ? schema?.required('Обязательное поле!') : schema
            }),
    }
}

export const REQUIRED_FIELDS_TEMP = {
    [STATUS_NEW]: Yup.object().shape(getSchema()),
    [STATUS_APPROVE]: Yup.object().shape(getSchema()),
    [STATUS_UPLOAD]: Yup.object().shape(getSchema()),
    [STATUS_PROCESS]: Yup.object().shape(getSchema()),
    [STATUS_ACCEPT]: Yup.object().shape(getSchema()),
    [STATUS_TEMP]: Yup.object().shape(getSchema()),
}
