import { ROLES } from '../../../rbac/constant'
import { Input, InputDate, Select } from '../../../components/form/forms'
import * as Yup from 'yup'
import { setRequiredField } from '../../../utils/utils'


export const STATUS_ACCEPT = 3                 // Принято Д
export const STATUS_NEW = 1                    // Новый МП
export const STATUS_APPROVE = 2               // Обрабатывается Д
export const STATUS_TEMP = 4

export const STATUS_LABELS = {
    [STATUS_NEW]: 'Заполнение менеджером',
    [STATUS_APPROVE]: 'Проверка дирекцией',
    [STATUS_ACCEPT]: 'Трудоустроен',
}

export const STATUS_OPTIONS = [
    { value: STATUS_NEW, label: 'Заполнение менеджером' },
    { value: STATUS_APPROVE, label: 'Проверка дирекцией' },
    { value: STATUS_ACCEPT, label: 'Трудоустроен' },
]

export const SPECIALIST_TYPES = [
    { value: 1, label: 'ДПО, повышение квалификации' },
    { value: 2, label: 'ДПО, профессиональная переподготовка' },
    { value: 7, label: 'Стажировка с грантом' },
    { value: 8, label: 'Стажировка без гранта' },
]

export const STUDENT_TYPES = [
    { value: 3, label: 'Бакалавриат' },
    { value: 4, label: 'Магистратура' },
    { value: 5, label: 'Специалитет' },
    { value: 6, label: 'Аспирантура' },
]


export const SPECIALIST_PARTICIPATION = [
    { value: 1, label: 'Разработчик' },
    { value: 2, label: 'Преподаватель' },
    { value: 3, label: 'Руководитель' },
]

export const YEAR = [
    { value: 2020, label: 2020 },
    { value: 2021, label: 2021 },
    { value: 2022, label: 2022 },
    { value: 2023, label: 2023 },
    { value: 2024, label: 2024 },
    { value: 2025, label: 2025 },
    { value: 2026, label: 2026 },
    { value: 2027, label: 2027 },
    { value: 2028, label: 2028 },
    { value: 2029, label: 2029 },
    { value: 2030, label: 2030 },
]

export const MONTH = [
    { value: 1, label: 'Январь' },
    { value: 2, label: 'Февраль' },
    { value: 3, label: 'Март' },
    { value: 4, label: 'Апрель' },
    { value: 5, label: 'Май' },
    { value: 6, label: 'Июнь' },
    { value: 7, label: 'Июль' },
    { value: 8, label: 'Август' },
    { value: 9, label: 'Сентябрь' },
    { value: 10, label: 'Октябрь' },
    { value: 11, label: 'Ноябрь' },
    { value: 12, label: 'Декабрь' },
]


export const STATUS_BADGE_CLASS = {
    [STATUS_ACCEPT]: 'badge-success',
    [STATUS_NEW]: 'badge-warning',
    [STATUS_APPROVE]: 'badge-info',
    [STATUS_TEMP]: '',
}

export const NO_YES = [
    { value: 1, label: 'Нет' },
    { value: 2, label: 'Да' },
]

export const LABELS = {
    'id': 'ID',
    'status': 'Статус',
    'type': 'Тип (форма подготовки)',
    'fio': 'ФИО',
    'first_name': 'Имя',
    'last_name': 'Фамилия',
    'middle_name': 'Отчество',
    // 'birth_date': 'Дата рождения',
    // 'age': 'Возраст',
    'edu_doc_number': 'Номер документа об образовании',
    'edu_doc_date': 'Дата документа об образовании',
    'expel_order_number': 'Дата документа об образовании',
    'enrollment_order': 'Приказ о зачислении/ о направлении на обучение',
    'organization_employed': 'Наименование организации, в которой трудоустроен специалист',
    'organization_program': 'Наименование организации',
    'organization_inn': 'ИНН организации',
    'employed_inn': 'ИНН организации, в которой трудоустроен специалист',
    'indicator': 'Показатель',
    'is_network': 'Сетевая',
    'is_commercial': 'На коммерческой основе',
    'diploma_month': 'Месяц получения диплома',
    'diploma_year': 'Год получения диплома',
    'comment': 'Комментарий',
    'event': 'Наименование программы',
    'project': 'Проект',
    'direction_edu': 'Дирекция Образование',
    'direction': 'Дирекция',
    'order': 'Приказ',
    'related_docs_url': 'Ссылка на сопутствующие документы',
    'staff': 'Кадры',
    'messages': 'Сообщения',
    'message': 'Комментарий',
    'updated_by': 'Обновлено пользователем',
    'created_at': 'Дата создания',
    'updated_at': 'Дата обновления',
    'created_by': 'Создано пользователем',
}

export const FIELDS = {
    id: {
        type: Input,
        content: 'number',
        props: {
            show_zero: false,
        },
    },
    fio: {
        type: Input,
        content: 'text',
    },
    status: {
        type: Select,
        options: STATUS_OPTIONS,
    },
    type: {
        type: Select,
        options: SPECIALIST_TYPES,
    },
    first_name: {
        type: Input,
        content: 'text',
    },
    last_name: {
        type: Input,
        content: 'text',
    },
    middle_name: {
        type: Input,
        content: 'text',
    },
    // birth_date: {
    //     type: InputDate,
    //     content: 'date'
    // },
    // age: {
    //     type: Input,
    //     content: 'number'
    // },
    edu_doc_number: {
        type: Input,
        content: 'text',
    },
    expel_order_number: {
        type: Input,
        content: 'text',
    },
    edu_doc_date: {
        type: InputDate,
        content: 'date',
    },
    enrollment_order: {
        type: Input,
        content: 'number',
    },
    organization_employed: {
        type: Input,
        content: 'text',
    },
    organization_program: {
        type: Input,
        content: 'text',
    },
    organization_inn: {
        type: Input,
        content: 'text',
        props: {
            mask: '9999-999999-99',
        },
    },
    employed_inn: {
        type: Input,
        content: 'text',
        props: {
            mask: '9999-999999-99',
        },
    },
    indicator: {
        type: Select,
        source: 'indicator',
        key: (v) => v?.title_short,
    },
    is_network: {
        type: Select,
        options: NO_YES,
    },
    is_commercial: {
        type: Select,
        options: NO_YES,
    },
    diploma_month: {
        type: Select,
        options: MONTH.map((v, i) => {
            return { value: i + 1, label: v }
        }),
    },
    diploma_year: {
        type: Input,
        content: 'number',
    },
    event: {
        type: Select,
        source: 'event',
        key: (v) => `(${v?.title_short}) ${v?.title}`,
    },
    project: {
        type: Select,
        source: 'project',
        key: (v) => v?.title_short,
    },
    direction_edu: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
    direction: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
    order: {
        type: Select,
        source: 'order',
    },
    staff: {
        type: Select,
        source: 'staff_select',
        key: (v) => v?.fio,
    },
    updated_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
    created_at: {
        type: InputDate,
        content: 'date',
    },
    updated_at: {
        type: InputDate,
        content: 'date',
    },
    created_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
}

export const REQUIRED_FIELDS = {
    [STATUS_NEW]: [
        'fio', 'type', 'event_id', 'event_name',
    ],
    [STATUS_APPROVE]: [
        'fio', 'project_id', 'event_id', 'event_name', 'type', 'order_id',
    ],
    [STATUS_ACCEPT]: [
        'fio', 'project_id', 'event_id', 'event_name', 'type', 'order_id',
    ],
    [STATUS_TEMP]: [
        'fio', 'project_id', 'event_id', 'event_name', 'type', 'order_id',
    ],
}

const isINNLegalEntity = (value) => {
    if (!value) return true

    const valueToString = value ? value.toString().replaceAll('-', '') : ''
    const getN = (index) => (parseInt(valueToString[index]))

    if (valueToString.length === 10) {
        const dgt10 = ((
            2 * getN(0) + 4 * getN(1) + 10 * getN(2)
            + 3 * getN(3) + 5 * getN(4) + 9 * getN(5)
            + 4 * getN(6) + 6 * getN(7) + 8 * getN(8)
        ) % 11) % 10
        return (getN(9) === dgt10)
    }

    if (valueToString.length === 12) {
        const checkSumOne = ((
            7 * getN(0) + 2 * getN(1) + 4 * getN(2)
            + 10 * getN(3) + 3 * getN(4) + 5 * getN(5)
            + 9 * getN(6) + 4 * getN(7) + 6 * getN(8)
            + 8 * getN(9)) % 11) % 10
        const checkSumTwo = ((
            3 * getN(0) + 7 * getN(1) + 2 * getN(2)
            + 4 * getN(3) + 10 * getN(4) + 3 * getN(5)
            + 5 * getN(6) + 9 * getN(7) + 4 * getN(8)
            + 6 * getN(9) + 8 * getN(10)) % 11) % 10
        if (checkSumOne === Number(getN(10)) && checkSumTwo === Number(getN(11)))
            return true
    }

    return false
}

const getSchema = () => {
    return {
        status: Yup.number(),
        message: Yup.string().when(['status', 'current_status'], (status, schema) => {
            return status[0] < status[1] ? schema.required('Обязательное поле!') : schema
        }),
        program: Yup.number().typeError('Обязательное поле!').nullable(),
        fio: Yup.string().nullable().required('Обязательное поле!'),
        // birth_date: Yup.string().nullable().required('Обязательное поле!').typeError('Обязательное поле!'),
        project_id: setRequiredField(Yup.number()
            .typeError('Обязательное поле!'), STATUS_APPROVE),
        type: setRequiredField(Yup.number()
            .typeError('Обязательное поле!'), STATUS_NEW),
        event_id: Yup.object()
            .nullable()
            .typeError('Обязательное поле!')
            .test('test-on-req', 'Обязательное поле!', (value, context) => {
                return context.parent?.program == 1 ? !!value : true
            }),
        event_name: Yup.string()
            .typeError('Обязательное поле!').nullable()
            .test('test-on-req-name', 'Обязательное поле!', (value, context) => {
                return context.parent?.program == 2 ? !!value : true
            }),
        edu_doc_date: Yup.string().nullable()
            .typeError('Обязательное поле!'),
        edu_doc_number: Yup.string().nullable()
            .typeError('Обязательное поле!'),
        order_id: Yup.number()
            .typeError('Обязательное поле!'),
        organization_inn: Yup.string().nullable()
            .test('inn', 'Неверный ИНН', isINNLegalEntity),
        employed_inn: Yup.string().nullable()
            .test('inn', 'Неверный ИНН', isINNLegalEntity),
    }
}

export const REQUIRED_FIELDS_TEMP = {
    [STATUS_NEW]: Yup.object().shape(getSchema()),
    [STATUS_APPROVE]: Yup.object().shape(getSchema()),
    [STATUS_ACCEPT]: Yup.object().shape(getSchema()),
}

export const CAN_MANAGE_ROLES = {
    [STATUS_NEW]: [ROLES.admin, ROLES.project_manager],
    [STATUS_APPROVE]: [ROLES.admin, ROLES.direction, ROLES.direction_edu],
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction],
}
