import { useAbac } from 'react-abac'
import { MyFileBrowser } from '../../../components/file_manager/file_manager'
import { DownloadFile } from '../../../components/file_manager/file_handlers'
import { Col, Row } from 'react-bootstrap'
import { Check, Input } from '../../../components/form/forms'
import {
    CAN_APPROVE_ROLES,
    CAN_MANAGE_FILES_PDF,
    CAN_MANAGE_FILES_WORD,
    CAN_MANAGE_ROLES_COMMON,
    REQUIRED_FIELDS,
    STATUS_APPROVE,
    STATUS_PROCESS,
} from '../../../screens/database/niokr/constant'
import { PERMISSIONS } from '../../../rbac/constant'

export const YearResultsReportFiles = ({ 
    id, 
    data, 
    formik, 
    item, 
    index, 
    user, 
    disabled,
}) => {
    const { userHasPermissions } = useAbac()

    const FILEPATH_REPORT_WORD = `root/storage/niokr/niokr_${id}/word/${item?.year}`
    const FILEPATH_REPORT_PDF = `root/storage/niokr/niokr_${id}/pdf/${item?.year}`
    const FILEPATH_REPORT_PRESENTATION = `root/storage/niokr/niokr_${id}/presentation/${item?.year}`
    const status = data?.status ? data?.status : 1

    const getUserRights = (action = 'download', filetype = 'word' ) => {
        const CAN_MANAGE_FILES = filetype === 'word'? CAN_MANAGE_FILES_WORD : CAN_MANAGE_FILES_PDF
        
        return user.roles
            .some((role) => CAN_MANAGE_FILES[action].includes(role))
    }

    const readOnly = disabled && !(getUserRights('upload', 'word') || getUserRights('download', 'word'))

    const getTags = (documentType) => {
        return {
            'document_type': documentType,
            'year': item?.year,
            'project': formik.values.project_id,
            'niokr_item': id,
        }
    }
    
    const presTemplateFileTags = JSON.stringify({ 
        'document_type': 'template_presentation', 
        'DB': 'niokr',
    })

    return (
        <fieldset>
            <legend>
                Отчет о научно исследовательской работе
            </legend>
            <p>
                Оформление отчета по ГОСТ 7.32-2017 <br/>
                Система стандартов по информации, библиотечному и издательскому делу.
                Отчет о научно-исследовательской работе. Структура и правила оформления
            </p>

            <Input
                label={'Номер государственной регистрации'}
                id={`expected_result_year.${index}.gos_number`}
                name={`expected_result_year.${index}.gos_number`}
                handleChangeValue={formik.handleChange}
                value={item.gos_number}
                disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                required={REQUIRED_FIELDS?.[data?.status]?.includes('gos_number')}
                error={formik.errors[`expected_result_year.${index}.result`]}
                invalid={formik.errors[`expected_result_year.${index}.gos_number`]}
            />

            <Row>
                <Col md={6}>
                    <h5>
                        Отчет о научно исследовательской работе, word
                    </h5>
                    <small>
                        Пожалуйста, загружайте файл с названием
                        "[Фамилия руководителя]_НТО_[сокращенное
                        название НИОКР]_[год]_word"
                    </small>

                    <MyFileBrowser
                        height={280}
                        path={FILEPATH_REPORT_WORD}
                        read_only={readOnly}
                        tags={getTags('NTO_WORD')}
                        can_upload={getUserRights('upload', 'word')}
                        can_download={getUserRights('download', 'word')}
                        instanceId={`${item?.year}`}
                    />
                </Col>
                <Col md={6}>
                    <h5>
                        Отчет о научно исследовательской работе, pdf
                    </h5>
                    <small>
                        Пожалуйста, загружайте файл с названием
                        "[Фамилия руководителя]_НТО_[сокращенное
                        название НИОКР]_[год]_pdf"
                    </small>

                    <MyFileBrowser
                        height={280}
                        path={FILEPATH_REPORT_PDF}
                        read_only={readOnly}
                        tags={getTags('NTO_PDF')}
                        can_upload={getUserRights('upload', 'pdf')}
                        can_download={getUserRights('download', 'pdf')}
                        instanceId={`${item?.year}`}
                    />
                </Col>
            </Row>

            <br/>

            <Row>
                <Col md={12}>
                    <h5>Презентация</h5>
                </Col>
                
                <Col md={10}>
                    <small>
                        Пожалуйста, загружайте файл с названием
                        "[Фамилия руководителя]_Презентация_[сокращенное
                        название НИОКР]_[год]"
                    </small>
                </Col>

                <Col md={2}>
                    <DownloadFile
                        folder={'root/templates/'}
                        file={'pish_NIOKR_presentation_template.pptx'}
                        title={'niokr'}
                        label={'Шаблон презентации:  '}
                        withIcon={false}
                        tags={presTemplateFileTags}
                    />
                </Col>

                <Col md={12}>
                    <MyFileBrowser
                        path={FILEPATH_REPORT_PRESENTATION}
                        instanceId={`${item?.year}`}
                        read_only={readOnly}
                        tags={getTags('presentation')}
                        borderInvalid={data?.status === STATUS_PROCESS && item.needPresentationAccept && !item.presentationAccepted}
                        can_upload={getUserRights('upload', 'word')}
                        can_download={getUserRights('download', 'word')}
                    />

                    {data && user.roles.some((role) => CAN_APPROVE_ROLES[data?.status].includes(role)) && data?.status === STATUS_PROCESS && (
                        <Check
                            label={'Принять результат за год'}
                            id={`expected_result_year.${index}.presentationAccepted`}
                            value={item.presentationAccepted}
                            handleChangeValue={formik.handleChange}
                        />
                    )}

                    {data && userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status === STATUS_APPROVE && !item?.presentationAccepted && (
                        <Check
                            label={'Отправить на согласование информацию в поле выше'}
                            id={`expected_result_year.${index}.needPresentationAccept`}
                            value={item.needPresentationAccept}
                            handleChangeValue={formik.handleChange}
                        />
                    )}
                </Col>
            </Row>
        </fieldset>
    )
}