import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import ReactFilterTable from '../../../components/tables/react_table_filters/react_table_filters'
import { useNavigate } from 'react-router-dom'
import {
    FIELDS,
    INTERNSHIP_TYPE_OPTIONS,
    LABELS,
    STATUS_ACCEPT,
    STATUS_BADGE_CLASS,
    STATUS_LABELS,
    STATUS_NEW,
} from './constant'
import { PERMISSIONS, ROLES } from '../../../rbac/constant'
import { Button } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { putRequest } from '../../../actions/common'
import { AuthContext } from '../../../auth'
import { useAbac } from 'react-abac'
import { toast } from 'react-toastify'
import { getSelectedColor, getStatusColor, handleMoveClick, MoveButton, onSelectRow } from '../common'
import { InternshipsPreview } from '../preview'

const Internships = () => {
    const navigate = useNavigate()
    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()

    const [selected, setSelected] = useState([])
    const [status, setStatus] = useState(null)
    const [internships, setInternships] = useState(null)
    const [filtering, setFiltering] = useState(null)
    const [viewItem, setViewItem] = useState(null)

    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['id', 'status', 'project', 'order_number', 'order_date', 'order_type', 'partner_name'],
        'Скрытые / Фиксированные колонки': [
            'partner_number', 'partner_date', 'protocol_number', 'protocol_date', 'students', 'internship_number',
            'internship_date', 'scholarships_number', 'scholarships_date',
            'direction', 'updated_by', 'created_at', 'updated_at', 'created_by',
        ],
    })

    useEffect(() => {
        if (userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && !userHasPermissions(PERMISSIONS.ADMIN_PANEL)) {
            setFiltering({ status_not: STATUS_NEW })
        }
    }, [user])


    const checkField = (field, key) => {
        if (key === 'status') {
            return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
        } else if (key === 'order_type') {
            let label = INTERNSHIP_TYPE_OPTIONS?.find((v) => v?.value === field)?.label
            return label ? label : <span className={'not-set'}>(не задано)</span>
        } else if (['project'].includes(key) && field) {
            return <span>{field.title_short}</span>
        } else if (['students'].includes(key) && field) {
            let students = ''
            field.forEach((v) => {
                students += `${v.fio}, `
            })
            return <span>{students}</span>
        }
        return null
    }

    const addNewInternships = (props) => {
        putRequest('internships', {
            status: props?.monitoring ? STATUS_ACCEPT : STATUS_NEW,
            project: user?.project?.id,
            is_draft: true,
        }).then((response) => {
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/internships/item/${response.data.id}/?update=true`, { state: props })
            } else if (response?.status === 400 && response?.data?.message) {
                toast.error(response?.data?.message)
            }
        })
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <div className={'box'}>
                <h1 className={'page-header'}>
                    Стажировка
                </h1>
                {user?.roles?.includes(ROLES.admin)
                || user?.roles?.includes(ROLES.direction)
                || user?.roles?.includes(ROLES.direction_oop)
                || user?.roles?.includes(ROLES.project_manager)
                    ? <React.Fragment>
                        <div className={'button-actions'}>
                            {userHasPermissions(PERMISSIONS.CONTROL_INTERN)
                                ? <React.Fragment>
                                    <Button variant={'outline-primary'} onClick={() => {
                                        addNewInternships({ monitoring: true })
                                    }}>
                                        <IconText icon={'plus'} text={'Добавить в мониторинг'}/>
                                    </Button>
                                    {userHasPermissions(PERMISSIONS.ADMIN_PANEL)
                                        ? <Button variant={'primary'} onClick={() => {
                                            addNewInternships()
                                        }}>
                                            <IconText icon={'plus'} text={'Создать'}/>
                                        </Button> : null}
                                </React.Fragment>
                                : <React.Fragment>
                                    <Button variant={'primary'} onClick={() => {
                                        addNewInternships()
                                    }}>
                                        <IconText icon={'plus'} text={'Создать'}/>
                                    </Button>
                                </React.Fragment>
                            }
                            {selected.length
                                ? <MoveButton
                                    status={status}
                                    variantBtn={getStatusColor(status, STATUS_ACCEPT, STATUS_NEW)}
                                    labels={STATUS_LABELS}
                                    canNext={status !== STATUS_ACCEPT}
                                    canBack={status > STATUS_NEW}
                                    isAccept={(status + 1) === STATUS_ACCEPT}
                                    handleMoveClick={(moveIndex) => handleMoveClick(moveIndex, 'internships', selected, setSelected, status, setFiltering)}
                                    permissionManage={PERMISSIONS.MANAGE_FINANCING}
                                />
                                : null}
                        </div>
                    </React.Fragment> : null}
            </div>
            {viewItem
                ? <InternshipsPreview
                    itemId={viewItem}
                    onClose={() => setViewItem(null)}
                    urlUpdate={'/internships/item'}
                />
                : null}
            <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                              filtering={filtering}
                              data={internships}
                              setFunc={setInternships} page={1}
                              url={'/internships'}
                              url_update={'/internships/item'}
                              get_title={'internships'}
                              checkField={checkField}
                              permission={PERMISSIONS.MANAGE_INTERN}
                              fields={FIELDS}
                              canSelect
                              onRowClick={(el) => setViewItem(el?.id)}
                              onSelect={(ev, el) => onSelectRow(ev, el, internships, setSelected, selected)}
                              colorExpression={(el) => getSelectedColor(el, selected)}
                              selectedAll={selected?.length && (internships['results']?.length === selected?.length)}
                              selected={selected}
                              setStatus={setStatus}
            />
        </React.Fragment>
    )
}

export default Internships
