import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import ReactFilterTable from '../../../components/tables/react_table_filters/react_table_filters'
import { useNavigate } from 'react-router-dom'
import { PERMISSIONS, ROLES } from '../../../rbac/constant'
import { Button } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { AuthContext } from '../../../auth'
import { FIELDS, LABELS, MONTHS, STATUS_ACCEPT, STATUS_BADGE_CLASS, STATUS_LABELS, STATUS_NEW } from './constant'
import { putRequest } from '../../../actions/common'
import { useAbac } from 'react-abac'
import { getSelectedColor, getStatusColor, handleMoveClick, MoveButton, onSelectRow } from '../common'
import { EmployedPreview } from '../preview'


const Employed = () => {
    const navigate = useNavigate()
    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()

    const [selected, setSelected] = useState([])
    const [status, setStatus] = useState(null)
    const [viewItem, setViewItem] = useState(null)
    const [employed, setEmployed] = useState(null)
    const [filtering, setFiltering] = useState(null)
    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['id', 'status', 'organization',
            'specialist_fio',
            'employment_date',
            'project',
            'month',
            'year'],
        'Скрытые / Фиксированные колонки': [
            'event',
            'direction_by',
            'updated_by',
            'created_by',
            'created_at',
            'updated_at',
            'messages',
        ],
    })

    const checkField = (field, key) => {
        if (key === 'status') {
            return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
        } else if (key === 'specialist') {
            return field?.fio
        } else if (key === 'project' && field) {
            return <span>{field.title_short}</span>
        } else if (key === 'event') {
            return field?.title
        } else if (key === 'month') {
            return MONTHS?.[field - 1]
        }

        return null
    }

    useEffect(() => {
        if (userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && !userHasPermissions(PERMISSIONS.ADMIN_PANEL)) {
            setFiltering({ status_not: STATUS_NEW })
        }
    }, [user])

    const addNewEmployed = (state) => {
        putRequest('employed', {
            status: state?.monitoring ? STATUS_ACCEPT : STATUS_NEW,
            project: user?.project?.id,
            is_draft: true,
        }).then((response) => {
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/employed/item/${response.data.id}/?update=true`)
            }
        })
    }

    const templateFileTags = JSON.stringify({ 'document_type': 'template_export', 'DB': 'employed' })

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <div className={'box'}>
                <h1 className={'page-header'}>
                    Трудоустроенные
                </h1>
                {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.direction)
                || user?.roles?.includes(ROLES.project_manager)
                    ? <React.Fragment>
                        <div className={'button-actions'}>
                            {userHasPermissions(PERMISSIONS.CONTROL_EMPLOYED)
                                ? <React.Fragment>
                                    {userHasPermissions(PERMISSIONS.ADMIN_PANEL)
                                        ? <Button variant={'primary'} onClick={() => {
                                            addNewEmployed()
                                        }}>
                                            <IconText icon={'plus'} text={'Создать'}/>
                                        </Button> : null}
                                </React.Fragment>
                                : <React.Fragment>
                                    <Button variant={'primary'} onClick={() => {
                                        addNewEmployed()
                                    }}>
                                        <IconText icon={'plus'} text={'Создать'}/>
                                    </Button>
                                </React.Fragment>
                            }
                            {selected.length
                                ? <MoveButton
                                    status={status}
                                    variantBtn={getStatusColor(status, STATUS_ACCEPT, STATUS_NEW)}
                                    labels={STATUS_LABELS}
                                    canNext={status !== STATUS_ACCEPT}
                                    canBack={status > STATUS_NEW}
                                    isAccept={(status + 1) === STATUS_ACCEPT}
                                    handleMoveClick={(moveIndex) => handleMoveClick(moveIndex, 'employed', selected, setSelected, status, setFiltering)}
                                    permissionManage={PERMISSIONS.MANAGE_EMPLOYED}
                                />
                                : null}
                        </div>
                    </React.Fragment> : null}
            </div>
            {viewItem
                ? <EmployedPreview
                    itemId={viewItem}
                    onClose={() => setViewItem(null)}
                    urlUpdate={'/employed/item'}
                />
                : null}
            <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                              filtering={filtering}
                              data={employed} setFunc={setEmployed} page={1} url={'/employed'}
                              url_update={'/employed/item'}
                              get_title={'employed'}
                              checkField={checkField}
                              permission={PERMISSIONS.MANAGE_EMPLOYED}
                              fields={FIELDS}
                              onRowClick={(el) => setViewItem(el?.id)}
                              urlImport={'/api/employed_import/'}
                              urlExport={'employed_table_export'}
                              templateFile={'/root/templates/Шаблон импорта бд Трудоустроенные.xlsx'}
                              templateFileTags={templateFileTags}
                              canSelect
                              onSelect={(ev, el) => onSelectRow(ev, el, employed, setSelected, selected)}
                              colorExpression={(el) => getSelectedColor(el, selected)}
                              selectedAll={selected?.length && (employed['results']?.length === selected?.length)}
                              selected={selected}
                              setStatus={setStatus}
            />
        </React.Fragment>
    )
}

export default Employed
